import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { Event } from '@bugsnag/js'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import fetch from 'cross-fetch'
import { print } from 'graphql/language/printer'
import { sha256 } from 'crypto-hash' // use native crypto (https only)
import { sha256 as devSha256 } from 'hash.js' // for non https
import https, { Agent } from 'https'

import bugsnagClient from '../helpers/BugsnagHelpers'
import * as config from '../configuration'

const isHttps = config.app.APP_URL.indexOf('https') === 0
const endPoint = config.api.GRAPH_API_URL
const FILTERED_CATEGORIES = ['validation', 'authorization']

type FetchOptions = {
  agent?: Agent
}

const fetchOptions: FetchOptions = {}

if (config.app.IGNORE_API_SSL_ERRORS) {
  const fetchOptions: FetchOptions = {}
  fetchOptions.agent = new https.Agent({
    rejectUnauthorized: false,
  })
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (bugsnagClient) {
      if (graphQLErrors) {
        const filteredErrors = graphQLErrors.filter(
          ({ extensions }) =>
            !FILTERED_CATEGORIES.includes(extensions?.category)
        )

        filteredErrors.forEach(
          ({ message, locations, path, ...additional }) => {
            const messageToSend = `[GraphQL error]: \
            Message: ${JSON.stringify(message)}, \
            Location: ${JSON.stringify(locations)}, \
            Path: ${JSON.stringify(path)}, \
            Additional: ${JSON.stringify(additional)}`

            if (config.app.APP_ENV === 'local') {
              console.error(messageToSend)
            }

            if (message !== 'PersistedQueryNotFound') {
              bugsnagClient?.notify(
                new Error(messageToSend),
                (event: Event) => {
                  event.severity = 'error'
                  event.context = 'graphQLErrors'
                  event.addMetadata('metaData', {
                    Message: message,
                    Location: locations,
                    Path: path,
                    query: print(operation.query),
                    Operation: operation,
                    Response: response,
                    Additional: additional,
                  })
                }
              )
            }
          }
        )
      }

      if (networkError) {
        if (config.app.APP_ENV === 'local') {
          console.error(`[Network error]: ${networkError}`)
        }

        bugsnagClient?.notify(
          new Error(`[Network error]: ${networkError}`),
          (event: Event) => {
            event.severity = 'error'
            event.context = 'graphQLErrors'
            event.addMetadata('metaData', {
              Operation: operation,
              Response: response,
            })
          }
        )
      }
    }
  }
)

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const httpLink = new HttpLink({ uri: endPoint, fetch, fetchOptions })

const httpsOptions = {
  sha256,
  useGETForHashedQueries: true,
}

const notHttpsOptions = {
  sha256,
  generateHash: (query: any) => devSha256().update(print(query)).digest('hex'),
  useGETForHashedQueries: true,
}

export const persistedClient = new ApolloClient({
  link: ApolloLink.from([
    createPersistedQueryLink({
      ...(isHttps ? httpsOptions : notHttpsOptions),
    }),
    errorLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  name: 'Apollo Persisted Client',
})

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  name: 'Apollo Client',
  queryDeduplication: false,
  defaultOptions,
})

export const defaultConfig = {
  fetchPolicy: 'no-cache',
  errorPolicy: 'all',
}

export const cacheConfig = {
  fetchPolicy: 'cache-first',
  errorPolicy: 'all',
}

export default apolloClient
