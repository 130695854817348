import gql from 'graphql-tag'

import { CouponFragment } from './CouponFragment'

export const HighcoCartFragment = gql`
  fragment HighcoCartFragment on HighcoCart {
    cartCode
    cartFormat
    listCoupon {
      ...CouponFragment
    }
  }
  ${CouponFragment}
`
