import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'
import { HighcoCartFragment } from '../../../fragments/HighcoCartFragment'

const couponAddToCart: ServiceMutation = {
  mutation: gql`
    mutation couponAddToCart($couponCode: ID!) {
      couponAddToCart(couponCode: $couponCode) {
        ...HighcoCartFragment
      }
    }
    ${HighcoCartFragment}
  `,
  transformer: (data: any) => {
    return data?.couponAddToCart
  },
}

export default couponAddToCart
