export const fontWeights = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
}

export const fontStyles = {
  normal: 'normal',
  italic: 'italic',
  oblique: 'oblique',
}

export type Font = {
  basename: string
  fontFamily: string
  fontWeight?: number
  fontStyle?: string
  fallback: string
  isVariable?: boolean
  fontWeightRange?: [number, number]
  fontStretchRange?: [string, string]
  isAlreadyDeclared?: boolean
}

export const defineFont = (font: Font): string => `
  font-family: "${font.fontFamily}", ${font.fallback || 'sans-serif'};
  font-style: ${font?.fontStyle || fontStyles.normal};
  font-weight: ${font?.fontWeight || fontWeights.regular};
  ${
    font.isVariable === true
      ? `
    font-variation-settings: 'wght' ${font?.fontWeight || fontWeights.regular};
  `
      : ``
  }  
`

export const declareFont = ({
  basename,
  fontFamily,
  fontWeight,
  fontStyle,
  isVariable,
  fontWeightRange,
  fontStretchRange,
}: Font): string => {
  const path = `/fonts/${basename}/${basename}`

  return `
    @font-face {
      font-family: "${fontFamily}";
      ${
        isVariable === true
          ? `
        src: url("${path}.woff2") format('woff2 supports variations'),
        url("${path}.woff2") format('woff2-variations');
        font-weight: ${(fontWeightRange && fontWeightRange[0]) ?? 100} ${
              (fontWeightRange && fontWeightRange[1]) ?? 900
            };
        font-stretch: ${(fontStretchRange && fontStretchRange[0]) ?? '25%'} ${
              (fontStretchRange && fontStretchRange[1]) ?? '150%'
            };
        ;
      `
          : `
        src: url("${path}.woff2") format("woff2");
        font-weight: ${fontWeight};
      
      `
      }
      font-style: ${fontStyle};
      font-display: swap;
    }
  `
}
