import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type NutritionBlockProps = MinimalSeoBlock & {
  className?: string
  sections?: MinimalSeoBlock &
    {
      title?: string
      data?: {
        label?: string
        info?: string | React.ReactNode
      }[]
    }[]
}

const NutritionBlock: FC<NutritionBlockProps> = (props) => {
  const { className, htmlTag, sections } = props

  return sections && sections.length > 0 ? (
    <SC.NutritionBlock className={cx('NutritionBlock', className)} as={htmlTag}>
      {sections.map((section, index) => (
        <SC.Section key={`NutritionBlock-section-${index}`}>
          {section.title && <SC.Title>{section.title}</SC.Title>}
          {section.data && section.data.length > 0 && (
            <SC.List>
              {section.data.map((datum, i) => (
                <SC.Item key={`NutritionBlock-datum-${index}-${i}`}>
                  {datum.label && <SC.Label>{datum.label}</SC.Label>}
                  {datum.info && <SC.Info>{datum.info}</SC.Info>}
                </SC.Item>
              ))}
            </SC.List>
          )}
        </SC.Section>
      ))}
    </SC.NutritionBlock>
  ) : null
}

export default NutritionBlock
