import React, { FC, useEffect, useId, useRef, useState } from 'react'
import cx from 'classnames'
import { useIsomorphicLayoutEffect, useLockedBody } from 'usehooks-ts'
import { useRouter } from 'next/router'

import { Icons } from '../Icon'
import { usePrevious } from '../../../hooks/usePrevious'

import * as SC from './styled'

export type PopinProps = MinimalSeoBlock & {
  className?: string
  title?: string
  texts?: {
    close?: string
  }
  children?: React.ReactNode
  isOpen?: boolean
  closeHandler?: () => void
  showHeader?: boolean
  noWrapping?: boolean
}

const Popin: FC<PopinProps> = (props) => {
  const {
    className,
    title,
    texts,
    isOpen,
    children,
    closeHandler,
    htmlTitleTag,
    showHeader = true,
    noWrapping = false,
  } = props

  const initalFocusedElement = useRef<Element | HTMLElement | null>(null)
  const ref = useRef<HTMLDivElement | null>(null)
  const refContent = useRef<HTMLDivElement | null>(null)
  const title_id = useId()
  const router = useRouter()

  // open status in state to control it from inside
  const [stateOpen, setStateOpen] = useState(false)
  const previousOpenState = usePrevious(isOpen)

  useEffect(() => {
    setStateOpen(isOpen ?? false)
  }, [isOpen, router?.asPath])

  // lock body scroll when open
  useLockedBody(stateOpen ?? false)

  const focusContentOnOpen = () => {
    const focusableEls = ref?.current?.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    )
    const firstEl = Array.prototype.slice.call(focusableEls)[0]
    firstEl?.focus({ focusVisible: true })
  }

  // store previous focused element and return it on close
  useIsomorphicLayoutEffect(() => {
    if (stateOpen) {
      initalFocusedElement.current = document.activeElement
      if (ref.current) focusContentOnOpen()
    } else if (initalFocusedElement.current) {
      ;(initalFocusedElement.current as HTMLElement)?.focus()
    }
  }, [stateOpen])

  // close the popin on "escape"
  useEffect(() => {
    const handlePressEscape = (e: KeyboardEvent) => {
      if (stateOpen && e.key === 'Escape') {
        closeHandler?.()
      }
    }

    window.addEventListener('keydown', handlePressEscape)
    return () => {
      window.removeEventListener('keydown', handlePressEscape)
    }
  }, [stateOpen, closeHandler])

  // close the popin on route change
  useEffect(() => {
    if (!router) return
    const close = () => setStateOpen(false)
    router?.events.on('routeChangeStart', close)
    return () => {
      router?.events.off('routeChangeStart', close)
    }
  }, [router])

  // scroll on reopen a popin
  useEffect(() => {
    if (refContent.current && previousOpenState === false && isOpen === true) {
      refContent.current.scrollTo({ top: 0 })
    }
  }, [isOpen, previousOpenState])

  return (
    <SC.Popin
      className={cx('Popin', className)}
      aria-hidden={!stateOpen}
      ref={ref}
      role="dialog"
      aria-modal
      {...(title && { 'aria-labelledby': title_id })}
    >
      <SC.Wrapper
        $withHeader={(closeHandler || title) && showHeader ? true : false}
      >
        {(closeHandler || title) && showHeader && (
          <SC.Header>
            {title && (
              <SC.Title id={title_id} as={htmlTitleTag}>
                {title}
              </SC.Title>
            )}
            {closeHandler && (
              <SC.Close onClick={closeHandler}>
                <span>{texts?.close ?? 'Close'}</span>
                <SC.CloseIcon icon={Icons.close} />
              </SC.Close>
            )}
          </SC.Header>
        )}
        {children && (
          <SC.Content ref={refContent} $noWrapping={noWrapping}>
            {children}
          </SC.Content>
        )}
      </SC.Wrapper>
      <SC.Overlay aria-label={texts?.close ?? 'Close'} onClick={closeHandler} />
    </SC.Popin>
  )
}

export default Popin
