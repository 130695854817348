import gql from 'graphql-tag'

import { FormatNutritionalFoodFragment } from './FormatNutritionalFoodFragment'
import { WediaImageFragment } from './WediaImageFragment'

export const FormatFragment = gql`
  fragment FormatFragment on Format {
    id
    title
    slug
    ean
    picture {
      ...WediaImageFragment
    }
    packshot {
      ...WediaImageFragment
    }
    thumbnail {
      ...WediaImageFragment
    }
    body
    link
    weight
    propertyIngredients
    propertyAllergens
    propertyConservation
    physicalChemicalExcerptDryMin
    physicalChemicalExcerptDryMax
    physicalChemicalFatOnDryWeight
    physicalChemicalFatOnTotalWeight
    nutritionalInformation
    nutritionals {
      servingSize
      servingSizeUnit
      servingSizeDescription
      fat {
        ...FormatNutritionalFoodFragment
      }
      fatsat {
        ...FormatNutritionalFoodFragment
      }
      choavl {
        ...FormatNutritionalFoodFragment
      }
      sugar {
        ...FormatNutritionalFoodFragment
      }
      pro {
        ...FormatNutritionalFoodFragment
      }
      salteq {
        ...FormatNutritionalFoodFragment
      }
      energyKj {
        ...FormatNutritionalFoodFragment
      }
      energyKcal {
        ...FormatNutritionalFoodFragment
      }
    }
    publicationDate
    expirationDate
    status
    mainBrand {
      id
      slug
      name
    }
    formatTrimanCategory {
      id
      label
      createdAt
      updatedAt
    }
    cheeses {
      id
    }
    createdAt
    updatedAt
  }
  ${FormatNutritionalFoodFragment}
  ${WediaImageFragment}
`
