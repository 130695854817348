import styled, { css } from 'styled-components'

import Ad from '../../components/molecules/Ad'
import Header from '../../components/molecules/Header'
import { mq, sizes } from '../../theme'
import BreadcrumbComponent from '../../components/molecules/Breadcrumb'

export const Layout = styled.div<{ $adBackground?: string }>`
  background-color: ${({ theme, $adBackground }) =>
    $adBackground ?? theme.colors.linen};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  @supports (min-height: 100svh) {
    min-height: 100svh;
  }
`
export const StyledHeader = styled(Header)``
export const StyledAd = styled(Ad)``
export const Main = styled.main<{
  $withMobileModal?: boolean
  $withAd?: boolean
}>`
  position: relative;
  z-index: ${({ theme, $withMobileModal }) =>
    $withMobileModal ? theme.zIndexes.modal : theme.zIndexes.default};
  width: 100%;
  flex: 1;
  max-width: ${({ theme }) => (theme.sizes.scopingMedium + 40) / 10}rem;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.colors.linen};
  ${({ $withAd }) =>
    $withAd &&
    css`
      ${StyledAd} + & {
        margin-top: 0;
        padding-top: 1.5rem;
        max-width: ${({ theme }) => (theme.sizes.withAd + 20) / 10}rem;
      }
    `}
  @media ${mq(sizes.tablet)} {
    padding: 0 2rem;
    margin-top: 3rem;
    z-index: ${({ theme }) => theme.zIndexes.default};
    ${StyledAd} + & {
      margin-top: 0;
      padding-top: 2rem;
    }
  }
  @media ${mq(sizes.desktop)} {
    ${StyledAd} + & {
      margin-top: -2.5rem;
      padding-top: 2rem;
      border-radius: 3rem 3rem 0 0;
    }
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-bottom: 4rem;

  @media print {
    gap: 1rem;
  }
`
export const Breadcrumb = styled(BreadcrumbComponent)``
