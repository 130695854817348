import gql from 'graphql-tag'

import { RecipeListFragment } from './RecipeListFragment'
import { RecipeCardFragment } from './RecipeCardFragment'
import { FormatFragment } from './FormatFragment'
import { WediaImageFragment } from './WediaImageFragment'
import { WediaVideoFragment } from './WediaVideoFragment'
import { CulinaryArticleCardFragment } from './CulinaryArticleCardFragment'
import { SeoFragment } from './SeoFragment'
import { BrandWrapFragment } from './BrandWrapFragment'

export const RecipeDetailsFragment = gql`
  fragment RecipeDetailsFragment on Recipe {
    ...RecipeListFragment
    introduction
    budget
    preparationTime
    cookingTime
    seo {
      ...SeoFragment
    }
    thumbnail03 {
      ...WediaImageFragment
    }
    outroTitle
    outroBody
    tags {
      id
      name
      slug
    }
    video {
      ...WediaVideoFragment
    }
    servings
    # "tags" used to display details
    tagsCookingTime {
      name
    }
    tagsBudget {
      name
    }
    tagsPreparationTime {
      name
    }
    # actual tags
    tagsConsumptionTime {
      name
      slug
    }
    tagsDifficulty {
      name
      slug
    }
    tagsTheme {
      name
      slug
    }
    # ingredients && directions
    ingredients {
      type
      text
      quantity
      product {
        ...FormatFragment
      }
    }
    directions
    # brand & cheese
    brand {
      id
      name
      slug
      pictureLogo {
        ...WediaImageFragment
      }
      thumbnailRange {
        ...WediaImageFragment
      }
      ...BrandWrapFragment
    }
    formats {
      id
      thumbnail {
        ...WediaImageFragment
      }
      packshot {
        ...WediaImageFragment
      }
      picture {
        ...WediaImageFragment
      }
      title
      body
      slug
      ean
      mainBrand {
        slug
      }
      propertyIngredients
      propertyAllergens
      propertyConservation
      physicalChemicalExcerptDryMin
      physicalChemicalExcerptDryMax
      physicalChemicalFatOnDryWeight
      physicalChemicalFatOnTotalWeight
      formatTrimanCategory {
        label
      }
      nutritionals {
        servingSize
        servingSizeDescription
        servingSizeUnit
        choavl {
          lessThan
          precision
          unit
          value
        }
        energyKcal {
          lessThan
          precision
          unit
          value
        }
        energyKj {
          lessThan
          precision
          unit
          value
        }
        fat {
          lessThan
          precision
          unit
          value
        }
        fatsat {
          lessThan
          precision
          unit
          value
        }
        pro {
          lessThan
          precision
          unit
          value
        }
        salteq {
          lessThan
          precision
          unit
          value
        }
        sugar {
          lessThan
          precision
          unit
          value
        }
      }
    }
    # Nutrition side block
    tipsNutritionTitle
    nutritionPortionBlock {
      title
      order
      energy
      fat
      carbohydrates
      proteins
      fibers
      salt
    }
    nutrition100gBlock {
      title
      order
      energy
      fat
      carbohydrates
      proteins
      fibers
      salt
    }
    # related
    thematicRelatedRecipes {
      ...RecipeCardFragment
    }
    brandOtherRecipes {
      ...RecipeCardFragment
    }
    culinaryArticles {
      ...CulinaryArticleCardFragment
    }
  }
  ${BrandWrapFragment}
  ${RecipeListFragment}
  ${SeoFragment}
  ${RecipeCardFragment}
  ${FormatFragment}
  ${WediaImageFragment}
  ${WediaVideoFragment}
  ${CulinaryArticleCardFragment}
`
