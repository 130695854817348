import React, { FC, useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { useWindowSize } from 'usehooks-ts'

import { PictureProps } from '../../atoms/Picture'
import { LinkProps } from '../../atoms/Link'
import tracking, { TrackingBannerInteraction } from '../../../tracking'
import { sizes } from '../../../theme'

import * as SC from './styled'

export type AdProps = {
  className?: string
  title?: string
  link?: LinkProps
  background?: string
  pictureTop: PictureProps
  pictureTopTablet?: PictureProps
  pictureTopMobile?: PictureProps
  pictureLeft?: PictureProps
  pictureRight?: PictureProps
  isBoxed?: boolean
}

enum DEVICE {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

enum FORMAT {
  MOBILE = '750x320',
  TABLET = '1000x240',
  DESKTOP = '1480x240',
}

const getDevice = (width: number) => {
  if (width < sizes.mobile) {
    return DEVICE.MOBILE
  }
  if (width < sizes.tablet) {
    return DEVICE.TABLET
  }
  return DEVICE.DESKTOP
}

const getFormat = (width: number) => {
  if (width < sizes.mobile) {
    return FORMAT.MOBILE
  }
  if (width < sizes.tablet) {
    return FORMAT.TABLET
  }
  return FORMAT.DESKTOP
}

const Ad: FC<AdProps> = (props) => {
  const {
    className,
    title,
    link,
    pictureTop,
    pictureTopTablet,
    pictureTopMobile,
    pictureLeft,
    pictureRight,
  } = props
  const [isAlreadyTracked, setIsAlreadyTracked] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    if (width && getDevice(width) === DEVICE.MOBILE && !pictureTopMobile) {
      return
    }
    if (width && getDevice(width) === DEVICE.TABLET && !pictureTopTablet) {
      return
    }

    if (width && !isAlreadyTracked) {
      tracking.bannerSelfPromo(
        title || '',
        getFormat(width),
        link?.href || '',
        TrackingBannerInteraction.IMPRESSION
      )
      setIsAlreadyTracked(true)
    }
  }, [
    isAlreadyTracked,
    link?.href,
    pictureTopMobile,
    pictureTopTablet,
    title,
    width,
  ])

  const renderLink = useCallback(
    (children?: React.ReactNode) => {
      return link ? (
        <SC.StyledLink
          {...link}
          onClick={() => {
            tracking.bannerSelfPromo(
              title || '',
              getFormat(width),
              link?.href || '',
              TrackingBannerInteraction.CLICK
            )
          }}
        >
          {children}
        </SC.StyledLink>
      ) : (
        children
      )
    },
    [link, title, width]
  )

  return (
    <SC.Ad className={cx('Ad', className)} aria-hidden>
      {renderLink(
        <>
          <SC.TopDesktop {...pictureTop} />
          {pictureTopMobile && <SC.TopMobile {...pictureTopMobile} />}
          {pictureTopTablet && <SC.TopTablet {...pictureTopTablet} />}
        </>
      )}
      {pictureLeft && renderLink(<SC.Left {...pictureLeft} />)}
      {pictureRight && renderLink(<SC.Right {...pictureRight} />)}
    </SC.Ad>
  )
}

export default Ad
