import styled, { css } from 'styled-components'

export const commonsStyles = css<{ $disabled?: boolean }>`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.pureBlack};
  display: inline-flex;

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.8;
    `}
`

export const Link = styled.a<{ $disabled?: boolean }>`
  ${commonsStyles}
`

export const Button = styled.button<{ $disabled?: boolean }>`
  ${commonsStyles}
  background-color: transparent;
`

export const Span = styled.span<{ $disabled?: boolean }>``
