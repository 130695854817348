import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'

import { actions, selectors } from '..'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../api/types/state'
import soloPageCapriceValentineCreateSession from '../../graphql/Services/Solo/mutations/soloPageCapriceValentineCreateSession'
import SoloPageCapriceValentineFetchSessionResponse from '../../graphql/Services/Solo/mutations/soloPageCapriceValentineFetchSessionResponse'
import SoloPageCapriceValentineSaveSessionInput from '../../graphql/Services/Solo/mutations/soloPageCapriceValentineSaveSessionInput'
import SoloPageCapriceValentineSessionByUid from '../../graphql/Services/Solo/queries/soloPageCapriceValentineSessionByUid'
import {
  SoloCapriceValentineError,
  SoloCapriceValentineStep,
} from '../../components/molecules/SoloCapriceValentineGame/types'
import { getRecaptchaToken } from '../../helpers/RecaptchaHelpers'

import { CapriceWhiteList } from './types'

export default class SoloSagas {
  static *onCapriceCreateSession({
    payload,
  }: ReturnType<typeof actions.solo.capriceValentineCreateSessionRequest>) {
    const rs: ApiResponse<typeof soloPageCapriceValentineCreateSession> =
      yield call(
        ApiSagas.mutate,
        soloPageCapriceValentineCreateSession,
        payload
      )

    if (rs.errors) {
      const errorTypes: string[] = rs.errors?.map(
        (e: any) => e?.extensions?.category ?? ''
      )
      if (
        errorTypes.includes(
          SoloCapriceValentineError.caprice_valentine_max_input_tries_reached
        ) ||
        errorTypes.includes(
          SoloCapriceValentineError.caprice_valentine_max_sessions_reached
        ) ||
        errorTypes.includes(
          SoloCapriceValentineError.caprice_valentine_invalid_session
        ) ||
        errorTypes.includes(
          SoloCapriceValentineError.caprice_valentine_game_closed
        )
      ) {
        yield put(actions.solo.setCapriceValentineSession(null))
        yield put(
          actions.solo.setCapriceValentineError(
            errorTypes[0] as SoloCapriceValentineError
          )
        )
        yield put(
          actions.solo.setCapriceValentineStep(SoloCapriceValentineStep.ERROR)
        )
      }
      yield put(actions.solo.capriceValentineCreateSessionError(rs.errors))
    } else {
      yield put(actions.solo.setCapriceValentineSession(rs.data))
      yield put(actions.solo.capriceValentineCreateSessionSuccess(rs.data))
    }
  }

  static *onCapriceFetchSessionResponse({
    payload,
  }: ReturnType<
    typeof actions.solo.capriceValentineFetchSessionResponseRequest
  >) {
    const user: SagaReturnType<typeof selectors.auth.user> = yield select(
      selectors.auth.user
    )
    const rs: ApiResponse<typeof SoloPageCapriceValentineFetchSessionResponse> =
      yield call(
        ApiSagas.mutate,
        SoloPageCapriceValentineFetchSessionResponse,
        payload
      )

    if (rs.errors) {
      yield put(
        actions.solo.capriceValentineFetchSessionResponseError(rs.errors)
      )
    } else {
      yield put(actions.solo.setCapriceValentineSession(rs.data))
      const count: number = yield select(selectors.solo.capriceValentineCount)
      if (!CapriceWhiteList.includes(user?.email ?? '')) {
        yield put(actions.solo.setCapriceValentineCount(count + 1))
      }
      yield put(
        actions.solo.capriceValentineFetchSessionResponseSuccess(rs.data)
      )
    }
  }

  static *onCapriceSaveSessionInput({
    payload,
  }: ReturnType<typeof actions.solo.capriceValentineSaveSessionInputRequest>) {
    const recaptcha: string = yield call(getRecaptchaToken)
    const rs: ApiResponse<typeof SoloPageCapriceValentineSaveSessionInput> =
      yield call(ApiSagas.mutate, SoloPageCapriceValentineSaveSessionInput, {
        ...payload,
        recaptcha,
      })

    if (rs.errors) {
      console.log(rs.errors)
      const errorTypes: string[] = rs.errors?.map(
        (e: any) => e?.extensions?.category ?? ''
      )
      if (
        errorTypes.includes(
          SoloCapriceValentineError.caprice_valentine_max_input_tries_reached
        ) ||
        errorTypes.includes(
          SoloCapriceValentineError.caprice_valentine_max_sessions_reached
        ) ||
        errorTypes.includes(
          SoloCapriceValentineError.caprice_valentine_invalid_session
        ) ||
        errorTypes.includes(
          SoloCapriceValentineError.caprice_valentine_game_closed
        )
      ) {
        yield put(actions.solo.setCapriceValentineSession(null))
        yield put(
          actions.solo.setCapriceValentineError(
            errorTypes[0] as SoloCapriceValentineError
          )
        )
        yield put(
          actions.solo.setCapriceValentineStep(SoloCapriceValentineStep.ERROR)
        )
      }
      if (
        errorTypes.includes(
          SoloCapriceValentineError.caprice_valentine_input_moderation
        )
      ) {
        console.log('moderation error', rs.errors)
      }
      yield put(actions.solo.capriceValentineSaveSessionInputError(rs.errors))
    } else {
      yield put(actions.solo.setCapriceValentineSession(rs.data))
      yield put(actions.solo.capriceValentineSaveSessionInputSuccess(rs.data))
    }
  }

  static *onCapriceSessionByUid({
    payload,
  }: ReturnType<typeof actions.solo.capriceValentineSessionByUidRequest>) {
    const rs: ApiResponse<typeof SoloPageCapriceValentineSessionByUid> =
      yield call(ApiSagas.query, SoloPageCapriceValentineSessionByUid, payload)

    if (rs.errors) {
      yield put(actions.solo.capriceValentineSessionByUidError(rs.errors))
    } else {
      yield put(actions.solo.setCapriceValentineSession(rs.data))
      yield put(actions.solo.capriceValentineSessionByUidSuccess(rs.data))
    }
  }

  static *onLinkCapriceSessionId() {
    const capriceValentineSession: SagaReturnType<
      typeof selectors.solo.capriceValentineSession
    > = yield select(selectors.solo.capriceValentineSession)
    const capriceValentineSessionUid = capriceValentineSession?.sessionUid
    if (capriceValentineSessionUid) {
      yield put(
        actions.member.memberUpdateRequest({
          input: { capriceValentineSessionUid },
        })
      )
    }
  }

  static *loop() {
    yield all([
      takeLatest(
        actions.solo.capriceValentineCreateSessionRequest,
        this.onCapriceCreateSession
      ),
      takeLatest(
        actions.solo.capriceValentineFetchSessionResponseRequest,
        this.onCapriceFetchSessionResponse
      ),
      takeLatest(
        actions.solo.capriceValentineSaveSessionInputRequest,
        this.onCapriceSaveSessionInput
      ),
      takeLatest(
        actions.solo.capriceValentineSessionByUidRequest,
        this.onCapriceSessionByUid
      ),
      takeLeading(actions.solo.linkCapriceSession, this.onLinkCapriceSessionId),
    ])
  }
}
