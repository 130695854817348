import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import ActionButton from '../../atoms/ActionButton'
import Link from '../../atoms/Link'
import Picture from '../../atoms/Picture'
import NavCard from '../NavCard'

export const SubNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 4rem 2rem 2rem;
  background: ${({ theme }) => theme.colors.pureWhite};
`
export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.scopingMedium / 10}rem;
`

export const Section = styled.div<{ $isCards?: boolean }>`
  --spacing-h-bottom-link: 0;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  gap: 2rem;
  justify-content: stretch;
  &:first-child {
    flex: 1;
  }
  &:only-child {
    justify-content: ${({ $isCards }) =>
      $isCards === true ? 'center' : 'flex-start'};
  }
  @media ${mq(sizes.scoping)} {
    --spacing-h-bottom-link: ${({ $isCards }) =>
      $isCards === true ? '0' : '2.1rem'};
    gap: ${({ $isCards }) => ($isCards === true ? '2rem 3rem' : '2rem')};
  }
`
export const Menu = styled.div<{ $oneOf?: number }>`
  grid-column: auto / span 1;
  grid-row: 1 / 2;
  flex: 1 1 auto;
  align-self: stretch;
  display: flex;
  flex-flow: column;
  @media ${mq(sizes.scoping)} {
    padding-left: 2rem;
    border-left: 0.1rem solid ${({ theme }) => theme.colors.melon};
  }
`
export const MenuTitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH7}
  margin: 0;
  color: ${({ theme }) => theme.colors.ruby};
`
type Nav = {
  $length?: number
  $oneMenuOf?: number
  $oneColumnOf?: number
}
export const Nav = styled.ul<Nav>`
  ${({ theme }) => theme.textStyles.textXSmall}
  list-style: none;
  padding: 0;
  margin: 0;
  &:not(:first-child) {
    margin-top: 1.6rem;
  }
  &:not(:last-child) {
    margin-bottom: auto;
  }
  & > li {
    margin-bottom: 0.6rem;
  }
  ${({ $length, $oneColumnOf }) =>
    $length && $length > 12 && $oneColumnOf && $oneColumnOf > 4
      ? css`
          columns: 2;
          column-gap: 1.6rem;
        `
      : $length &&
        $length > 10 &&
        $oneColumnOf &&
        $oneColumnOf <= 4 &&
        css`
          columns: 3;
          column-gap: 1.6rem;
        `}
`
export const NavLink = styled(Link)`
  ${({ theme }) => theme.textStyles.textXSmall}
  position: relative;
  color: ${({ theme }) => theme.colors.bismark};
  transition: color 200ms ease-in;
  &[aria-current='true'],
  &:hover {
    color: ${({ theme }) => theme.colors.arapawa};
  }
  &[aria-current='true'] {
    font-weight: bold;
    font-variation-settings: 'wght' 600;
  }
`
type Card = {
  $oneCardOf?: number
  $oneColumnOf?: number
}
export const Card = styled(NavCard)<Card>`
  grid-column: auto / span 1;
  grid-row: 1 / 2;
  justify-self: center;
`
export const CardTitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH4}
  line-height: 1.2;
  @media ${mq(sizes.scoping)} {
    ${({ theme }) => theme.textStyles.titleH3}
    line-height: 1.2;
  }
`
export const CardSubtitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH6}
  line-height: 1.4;
  text-transform: unset;
  @media ${mq(sizes.scoping)} {
    ${({ theme }) => theme.textStyles.titleH5}
    line-height: 1.4;
  }
`
export const CardPicture = styled(Picture)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  & img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
export const CardAction = styled(ActionButton)`
  margin-top: auto;
  align-self: flex-start;
  max-width: 100%;
  & > span {
    display: block;
    line-height: 2.4rem;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
`
export const MenuLink = styled(Link)`
  ${({ theme }) => theme.textStyles.titleH7};
  align-self: flex-start;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.arapawa};
  transition: color 200ms ease-in;
  &:hover {
    color: ${({ theme }) => theme.colors.bismark};
  }
  &:not(:first-child) {
    margin-top: 1rem;
  }
  & svg {
    color: ${({ theme }) => theme.colors.greyChateau};
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 0.6rem;
    transition: transform 200ms ease-in;
  }
  &:hover svg {
    transform: translateX(0.3rem);
  }
`
export const BottomLink = styled(MenuLink)`
  justify-self: flex-start;
  align-self: end;
  grid-column: 1 / 2;
  margin-left: var(--spacing-h-bottom-link);
  &:not(:first-child) {
    margin-top: 0;
  }
  &:nth-child(1),
  &:nth-child(2) {
    grid-column: 1 / 2;
  }
  &:nth-child(3) {
    grid-column: 1 / 3;
  }
  &:nth-child(4) {
    grid-column: 1 / 4;
  }
  &:nth-child(5) {
    grid-column: 1 / 5;
  }
  &:nth-child(6) {
    grid-column: 1 / 6;
  }
`
