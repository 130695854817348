import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'
import { WediaImageFragment } from '../../../fragments/WediaImageFragment'

const plans: ServiceQuery = {
  query: gql`
    query plans {
      plans {
        id
        picture {
          ...WediaImageFragment
        }
        slug
        startAt
        endAt
        titleHeader
      }
    }
    ${WediaImageFragment}
  `,
  transformer: (data: any) => {
    return data?.plans
  },
}

export default plans
