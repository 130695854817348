/* eslint-disable @next/next/no-img-element */
import { AriaAttributes, FC, useMemo } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type PictureProps = AriaAttributes & {
  className?: string
  // maxWidth for the image to avoid fetching big images for small blocks (2x the rendered size is not a bad idea)
  maxWidth?: number
  // width and height to reserve space of the image
  width?: number
  height?: number
  images?: {
    src: string
    size: number
    type?: string
  }[]
  alt?: string
  // will provide the image in 2x for High DPR up to 2x
  withHD?: boolean
  withLazyLoading?: boolean
  placeholder?: boolean
  preload?: boolean
  // if one will log each of the value
  log?: boolean
}

const Picture: FC<PictureProps> = (props) => {
  const {
    className,
    images,
    alt,
    maxWidth = 0,
    width,
    height,
    withHD = true,
    withLazyLoading = true,
    placeholder = false,
    preload = false,
    log = false,
    ...rest
  } = props

  if (log)
    console.log('src/components/atoms/Picture/index.tsx,  images', images)

  // sort images by size
  const sortedImages = useMemo(() => {
    return images ? [...images].sort((a, b) => a.size - b.size) : null
  }, [images])

  if (log)
    console.log(
      'src/components/atoms/Picture/index.tsx,  sortedImages',
      sortedImages
    )

  // pop out images that are bigger than maxWidth
  // or bigger than 2x maxWidth if with HD
  const truncatedImages = useMemo(() => {
    if (sortedImages && maxWidth) {
      const filteredImages = sortedImages.filter((image) =>
        withHD ? image.size <= maxWidth * 2 : image.size <= maxWidth
      )

      // if no image is smaller than maxWidth, fallback to the smallest image
      if (filteredImages.length === 0 && sortedImages.length > 0) {
        return [sortedImages[0]]
      }

      return filteredImages
    }

    return sortedImages
  }, [maxWidth, sortedImages, withHD])

  if (log)
    console.log(
      'src/components/atoms/Picture/index.tsx,  truncatedImages',
      truncatedImages
    )

  // get the last image index (???)
  const lastImageIndex = useMemo(() => {
    if (!truncatedImages || truncatedImages.length === 0) return -1

    return truncatedImages.length - 1
  }, [truncatedImages])

  if (log)
    console.log(
      'src/components/atoms/Picture/index.tsx,  lastImageIndex',
      lastImageIndex
    )

  // get the srcSet
  const srcSet = useMemo(() => {
    if (!truncatedImages || truncatedImages.length === 0) return null

    return truncatedImages.map((image, index) => {
      const srcSetAttr =
        truncatedImages[index + 1] && withHD
          ? `${image.src} 1x, ${truncatedImages[index + 1].src} 2x`
          : image.src

      const mediaAttr = `(max-width: ${image.size - 1}px)`

      return (
        (!maxWidth || maxWidth >= image.size) && (
          <source
            key={`Picture-source-${index}`}
            srcSet={srcSetAttr}
            {...(mediaAttr && { media: mediaAttr })}
            {...(image?.type && { type: image.type })}
          />
        )
      )
    })
  }, [truncatedImages, withHD, maxWidth])

  const preloadSrcSet = useMemo(
    () => sortedImages?.map((image) => `${image.src} ${image.size}w`).join(','),
    [sortedImages]
  )

  // get the fallback
  const fallback = useMemo(() => {
    if (!truncatedImages || truncatedImages.length === 0) return

    return truncatedImages[lastImageIndex].src
  }, [truncatedImages, lastImageIndex])

  if (log)
    console.log('src/components/atoms/Picture/index.tsx,  fallback', fallback)

  return images && images.length > 0 ? (
    <>
      {preload && <link rel="preload" as="image" imageSrcSet={preloadSrcSet} />}
      <SC.Container className={cx('Picture', className)} {...rest}>
        {srcSet}
        <img
          {...(withLazyLoading ? { loading: 'lazy' } : { loading: 'eager' })}
          src={fallback}
          alt={alt ?? ''}
          {...(width && { width })}
          {...(height && { height })}
        />
      </SC.Container>
    </>
  ) : placeholder ? (
    <SC.Container
      className={cx('Picture', className)}
      as="div"
      aria-hidden
      {...rest}
    />
  ) : null
}

export default Picture
