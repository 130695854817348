import styled from 'styled-components'

import Link from '../../atoms/Link'
import Icon from '../../atoms/Icon'
import { mq, sizes } from '../../../theme'

export const Container = styled.nav`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 0 1.5rem 2rem;
  gap: 0.5rem 1.5rem;
  @media ${mq(sizes.tablet)} {
    padding: 0 2rem 1rem;
  }

  @media print {
    display: none;
  }
`
export const EntryContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`
export const Entry = styled(Link)`
  ${({ theme }) => theme.textStyles.textXSmall}
  color: ${({ theme }) => theme.colors.arapawa};
  a& {
    color: ${({ theme }) => theme.colors.grey};
    &:hover {
      color: ${({ theme }) => theme.colors.arapawa};
    }
  }
`
export const Separator = styled(Icon)`
  width: 0.5rem;
  height: 0.8rem;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.greyChateau};
`
