import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'

const footer: ServiceQuery = {
  query: gql`
    query footer {
      configuration {
        footer {
          socialLinks {
            type
            link {
              href
              label
              target
            }
          }
          legalLinks {
            href
            label
            target
          }
          sectionsLinks {
            link {
              href
              label
              target
            }
            links {
              href
              label
              target
            }
          }
        }
      }
    }
  `,
  transformer: (data: any) => {
    return data?.configuration.footer
  },
}

export default footer
