import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const RecipeListFragment = gql`
  fragment RecipeListFragment on Recipe {
    id
    slug
    title
    publicationDate
    updatedAt
    mainImage {
      ...WediaImageFragment
    }
    thumbnail01 {
      ...WediaImageFragment
    }
    brand {
      pictureLogo {
        ...WediaImageFragment
      }
    }
    positiveFood
    nutriscore
    nutriscoreLetter
    # "tags" used to display details
    tagsPreparationTime {
      name
    }
    # actual tags
    tagsConsumptionTime {
      name
    }
    tagsDifficulty {
      name
    }
    tagsTheme {
      name
    }
  }
  ${WediaImageFragment}
`
