import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'
import { MemberFragment } from '../../../fragments/MemberFragment'

const memberRegister: ServiceMutation = {
  mutation: gql`
    mutation memberRegister($input: MemberRegisterInput!) {
      memberRegister(input: $input) {
        member {
          ...MemberFragment
        }
        token {
          accessToken
          refreshToken
          expiresIn
        }
      }
    }
    ${MemberFragment}
  `,
  transformer: (data: any) => {
    return data?.memberRegister
  },
}

export default memberRegister
