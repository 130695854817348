import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import type { RootState } from '../store'
import { Favorite } from '../../graphql/generated/api-graphql'
import { transformMemberFromReach5 } from '../../relay/Common/transformReach5'
import { CustomMember } from '../../types/types'

import { actionTypes } from './types'
import {
  loginService,
  requestPasswordResetService,
  resetPasswordService,
  socialLoginService,
} from './services'
import { MemberToken } from './types/state'

export const name = 'auth'

export type AuthState = {
  user: CustomMember | null
  token: MemberToken | null
  login: typeof loginService.values
  socialLogin: typeof socialLoginService.values
  requestPasswordReset: typeof requestPasswordResetService.values
  resetPassword: typeof resetPasswordService.values
  favoriteBrands: Favorite[]
  favoriteRecipes: Favorite[]
  favoriteArticles: Favorite[]
  favoriteFolders: Favorite[]
  authPopinOpen: boolean
  fullAuthPopinOpen: boolean
  authStatusIsComputed: boolean
  hasResetPassword: boolean
}

//
// Initial state
//

const initialState: AuthState = {
  user: null,
  token: null,
  login: loginService.values,
  socialLogin: socialLoginService.values,
  requestPasswordReset: requestPasswordResetService.values,
  resetPassword: resetPasswordService.values,
  favoriteBrands: [],
  favoriteRecipes: [],
  favoriteArticles: [],
  favoriteFolders: [],
  authPopinOpen: false,
  fullAuthPopinOpen: false,
  authStatusIsComputed: false,
  hasResetPassword: false,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setUser: (state, action: actionTypes.setUser) => {
      state.user = {
        ...action.payload,
        ...transformMemberFromReach5(action.payload),
      }
    },
    setToken: (state, action: actionTypes.setToken) => {
      if (!action.payload) {
        state.token = null
      } else {
        state.token = {
          ...action.payload,
          refreshToken:
            action?.payload?.refreshToken ?? state.token?.refreshToken,
        }
      }
    },
    logout: () => undefined,
    resetAuth: () => initialState,
    setAuthPopinOpen: (state, action: actionTypes.setAuthPopinOpen) => {
      state.authPopinOpen = action.payload
    },
    setFullAuthPopinOpen: (state, action: actionTypes.setFullAuthPopinOpen) => {
      state.fullAuthPopinOpen = action.payload
    },
    setFavoriteBrands: (state, action: actionTypes.setFavoriteBrands) => {
      state.favoriteBrands = action.payload
    },
    setFavoriteRecipes: (state, action: actionTypes.setFavoriteRecipes) => {
      state.favoriteRecipes = action.payload
    },
    setFavoriteArticles: (state, action: actionTypes.setFavoriteArticles) => {
      state.favoriteArticles = action.payload
    },
    setFavoriteFolders: (state, action: actionTypes.setFavoriteFolders) => {
      state.favoriteFolders = action.payload
    },
    setAuthStatusIsComputed: (
      state,
      action: actionTypes.setAuthStatusIsComputed
    ) => {
      state.authStatusIsComputed = action.payload
    },
    setHasResetPassword: (state, action: actionTypes.setHasResetPassword) => {
      state.hasResetPassword = action.payload
    },
    ...loginService.reducers,
    ...socialLoginService.reducers,
    ...requestPasswordResetService.reducers,
    ...resetPasswordService.reducers,
  },
  extraReducers: (builder) =>
    builder.addCase<any, PayloadAction<any>>(
      REHYDRATE,
      (_state, action: PayloadAction<any>) => {
        return {
          ...(action.payload?.['auth'] ?? initialState),
          user: null,
          login: loginService.values,
          socialLogin: socialLoginService.values,
          authStatusIsComputed: false,
        } // remove user to avoid first render errors
      }
    ),
})

export const { reducer, actions } = slice

//
// Selectors
//
const appRoot = (state: RootState) => state['app']
const isInit = (state: RootState) => appRoot(state).isInit

const root = (state: RootState) => state[slice.name]
const user = createSelector([root, isInit], (state, isInit) => {
  return isInit ? state.user : null
})
const token = (state: RootState) => root(state).token
const accessToken = (state: RootState) => root(state).token?.accessToken
const jwt = createSelector([accessToken], (tokenString): JwtPayload | null => {
  return tokenString ? jwtDecode<JwtPayload>(tokenString) : null
})

const isConnected = createSelector(
  [accessToken, isInit],
  (token, isInit): boolean => {
    return !!token && token !== '' && isInit
  }
)
const login = (state: RootState) => root(state).login
const socialLogin = (state: RootState) => root(state).socialLogin
const requestPasswordReset = (state: RootState) =>
  root(state).requestPasswordReset
const resetPassword = (state: RootState) => root(state).resetPassword
const favoriteBrands = (state: RootState) => root(state).favoriteBrands
const favoriteRecipes = (state: RootState) => root(state).favoriteRecipes
const favoriteArticles = (state: RootState) => root(state).favoriteArticles
const favoriteFolders = (state: RootState) => root(state).favoriteFolders
const authPopinOpen = (state: RootState) => root(state).authPopinOpen
const fullAuthPopinOpen = (state: RootState) => root(state).fullAuthPopinOpen
const authStatusIsComputed = (state: RootState) =>
  root(state).authStatusIsComputed
const hasResetPassword = (state: RootState) => root(state).hasResetPassword

export const selectors = {
  user,
  token,
  accessToken,
  jwt,
  isConnected,
  // services
  login,
  socialLogin,
  requestPasswordReset,
  resetPassword,
  favoriteBrands,
  favoriteRecipes,
  favoriteArticles,
  favoriteFolders,
  authPopinOpen,
  fullAuthPopinOpen,
  authStatusIsComputed,
  hasResetPassword,
}
