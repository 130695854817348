import styled from 'styled-components'

import Icon from '../Icon'

export enum ToasterType {
  success = 'success',
  error = 'error',
  info = 'info',
}

export const Bar = styled.div`
  width: 0.4rem;
  min-height: 5.5rem;
  border-radius: 10rem;
`

export const StyledIcon = styled(Icon)`
  margin: 0 1.6rem 0 0;
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textLargeStrong}
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Container = styled.div<{
  $variant?: ToasterType
  $open: boolean
}>`
  position: fixed;
  top: 1.6rem;
  left: 50%;
  transform: ${({ $open }) =>
    $open ? 'translate(-50%, 0)' : 'translate(-50%, -200%)'};
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding: 2rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
  min-width: 25rem;
  max-width: 54rem;
  z-index: ${({ theme }) => theme.zIndexes.modal};

  border: ${({ theme, $variant }) =>
    $variant === ToasterType.error
      ? `1px solid ${theme.colors.red}`
      : `1px solid ${theme.colors.blue}`};

  background-color: ${({ theme }) => theme.colors.arapawa};

  ${StyledIcon} {
    color: ${({ theme, $variant }) => {
      switch ($variant) {
        case ToasterType.success:
        case ToasterType.info:
          return theme.colors.cornflowerBlue
        case ToasterType.error:
          return theme.colors.red
      }
    }};

    width: 3.2rem;
    height: 3.2rem;
  }

  @media print {
    display: none;
  }
`
