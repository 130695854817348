import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Icon from '../Icon'
import Link from '../Link'

export const SkipNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`

export const StyledLink = styled(Link)`
  ${({ theme }) => theme.textStyles.textXXSmallCaps}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.arapawa};
  @media ${mq(sizes.tablet)} {
  }
`
export const StyledIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.colors.greyChateau};
`
