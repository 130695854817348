import React, { FC } from 'react'
import cx from 'classnames'

import { FormikFormProps } from '../../../form/FormikForm'
import { FormikFieldProps } from '../../../form/FormikField'
import { FormMessagesProps } from '../../../form/FormMessages'
import { FormSubmitButtonProps } from '../../../form/FormSubmitButton'
import BaseModal, { BaseModalProps } from '../BaseModal'
import { ActionButtonProps } from '../../../atoms/ActionButton'

import * as SC from './styled'

export type LightAuthRegisterModalProps = BaseModalProps & {
  className?: string
  background?: string
  title: string
  formikForm: FormikFormProps
  submitButton: FormSubmitButtonProps
  fields: {
    gender: FormikFieldProps
    firstName: FormikFieldProps
    lastName: FormikFieldProps
    birthDate: FormikFieldProps
    phoneNumber: FormikFieldProps
    email: FormikFieldProps
    password: FormikFieldProps
    confirm: FormikFieldProps
    rules: FormikFieldProps
  }
  errors?: FormMessagesProps
  login?: {
    title: string
    loginButtonProps?: ActionButtonProps
  }
}

const LightAuthRegisterModal: FC<LightAuthRegisterModalProps> = (props) => {
  const {
    className,
    background,
    title,
    errors,
    fields,
    formikForm,
    submitButton,
    login,
    ...modalProps
  } = props

  return (
    <BaseModal
      {...modalProps}
      className={cx('LightAuthRegisterModal', className)}
    >
      <SC.Content $background={background}>
        <SC.Title>{title}</SC.Title>

        <SC.Form {...formikForm}>
          <SC.AuthFields>
            <SC.GenderField {...fields.gender} />
            <SC.Row>
              <SC.Field {...fields.firstName} />
              <SC.Field {...fields.lastName} />
            </SC.Row>
            <SC.Row>
              <SC.Field {...fields.birthDate} />
              <SC.Field {...fields.phoneNumber} />
            </SC.Row>
            <SC.Field {...fields.email} />
            <SC.Row>
              <SC.Field {...fields.password} />
              <SC.Field {...fields.confirm} />
            </SC.Row>
            <SC.RulesField {...fields.rules} />
          </SC.AuthFields>
          {errors && <SC.Errors {...errors} />}
          <SC.SubmitButton {...submitButton} />
          {login && (
            <>
              <SC.DividerHorizontal />
              <SC.Login>
                <SC.Subtitle>{login.title}</SC.Subtitle>
                {login.loginButtonProps && (
                  <SC.LoginButton {...login.loginButtonProps} />
                )}
              </SC.Login>
            </>
          )}
        </SC.Form>
      </SC.Content>
    </BaseModal>
  )
}

export default LightAuthRegisterModal
