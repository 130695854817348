import brandColors from '../brandColors/stAlbray'

import { BrandSchemeType } from './types'

const brandScheme: BrandSchemeType = {
  DarkToneColor: brandColors.DarkToneColor,
  MediumToneColor: brandColors.MediumToneColor,
  LightToneColor: brandColors.LightToneColor,
  UltraLightToneColor: brandColors.UltraLightToneColor,
  HighLightToneColor: brandColors.HighLightToneColor,
}

export default brandScheme
