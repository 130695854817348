import gql from 'graphql-tag'

export const CouponFragment = gql`
  fragment CouponFragment on HighcoCoupon {
    label
    offerId
    offerCode
    couponId
    couponImg
    couponCode
    amountFormatted
    couponStatus
    couponDescription
    couponQuotaPerUser
    couponRemainingStock
    couponRemainingConsumerStock
    brandId
    brandName
    brandLogo
    productPrintVisual
    productPackShotVisual
    discountAmount
    discountType
    flag
    flagId
    closeSoon
    stockOffer
    validationDate
    startDate
    endDate
    remainingDaysBeforeEnding
    listEan
    ucNumber
    listRayon {
      id
      code
      name
      type
    }
  }
`
