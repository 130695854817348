import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import CouponCard, { CouponCardProps } from '../CouponCard'
import { FormMessagesProps } from '../../form/FormMessages'
import FormFieldSelect, {
  FormFieldSelectProps,
} from '../../form/fields/FormFieldSelect'
import Loader from '../../atoms/Loader'
import { ActionButtonProps } from '../../atoms/ActionButton'

import * as SC from './styled'
import { Variant } from './types'

export type CouponCardsBlockProps = MinimalSeoBlock & {
  className?: string
  title?: string
  empty?: string
  noBrand?: string
  formikForm?: FormikFormProps
  messagesProps?: FormMessagesProps
  filters?: FormFieldSelectProps[]
  cards?: CouponCardProps[]
  variant?: Variant
  isLoadingCoupons?: boolean
  noResultsMessage?: string
  actions?: ActionButtonProps[]
}

const CouponCardsBlock: FC<CouponCardsBlockProps> = ({
  className,
  htmlTag,
  title,
  empty,
  noBrand,
  filters,
  htmlTitleTag,
  cards,
  variant,
  isLoadingCoupons,
  noResultsMessage,
  actions,
}) => {
  return (
    <SC.CouponCardsBlock className={className} as={htmlTag}>
      {title && (
        <SC.Title $variant={variant} as={htmlTitleTag}>
          {title}
        </SC.Title>
      )}
      {empty && <SC.Text>{empty}</SC.Text>}
      {noBrand && <SC.Text>{noBrand}</SC.Text>}
      {filters && (
        <SC.Fields>
          {filters?.map((field, index) => (
            <FormFieldSelect key={index} {...field} />
          ))}
        </SC.Fields>
      )}
      {cards && cards.length > 0 ? (
        <SC.Cards $variant={variant}>
          {cards?.map((card, i) => (
            <CouponCard key={i} {...card} />
          ))}
        </SC.Cards>
      ) : isLoadingCoupons ? (
        <SC.LoaderContainer>
          <Loader />
        </SC.LoaderContainer>
      ) : (
        <SC.NoResults
          dangerouslySetInnerHTML={{ __html: noResultsMessage || '' }}
        />
      )}
      {actions && (
        <SC.Actions>
          {actions.map((action, index) => (
            <SC.Action key={index} {...action} />
          ))}
        </SC.Actions>
      )}
    </SC.CouponCardsBlock>
  )
}

export default CouponCardsBlock
