import React, { FC } from 'react'

import { AdProps } from '../../components/molecules/Ad'
import Footer, { FooterProps } from '../../components/molecules/Footer'
import { HeaderProps } from '../../components/molecules/Header'
import ProductPopin, {
  ProductPopinProps,
} from '../../components/molecules/ProductPopin'
import { BreadcrumbProps } from '../../components/molecules/Breadcrumb'
import LightAuthLoginModal, {
  LightAuthLoginModalProps,
} from '../../components/molecules/LightAuthModal/LightAuthLoginModal'
import LightAuthRegisterModal, {
  LightAuthRegisterModalProps,
} from '../../components/molecules/LightAuthModal/LightAuthRegisterModal'
import LightAuthOptinModal, {
  LightAuthOptinModalProps,
} from '../../components/molecules/LightAuthModal/LightAuthOptinModal'
import AuthPopin, { AuthPopinProps } from '../../components/molecules/AuthPopin'

import * as SC from './styled'

export type MainLayoutProps = {
  className?: string
  idContent?: string
  headerProps?: HeaderProps
  footerProps?: FooterProps
  adProps?: AdProps
  children?: React.ReactNode
  productPopinProps?: ProductPopinProps
  withMobileModal?: boolean
  breadcrumbProps?: BreadcrumbProps
  classicAuthModalProps?: AuthPopinProps
  lightAuthLoginModalProps?: LightAuthLoginModalProps
  lightAuthRegisterModalProps?: LightAuthRegisterModalProps
  lightAuthOptinModalProps?: LightAuthOptinModalProps
}

const MainLayout: FC<MainLayoutProps> = ({
  className,
  idContent = 'Content',
  headerProps,
  footerProps,
  adProps,
  children,
  productPopinProps,
  withMobileModal,
  breadcrumbProps,
  classicAuthModalProps,
  lightAuthLoginModalProps,
  lightAuthRegisterModalProps,
  lightAuthOptinModalProps,
}) => {
  return (
    <>
      <SC.Layout className={className} $adBackground={adProps?.background}>
        {headerProps && <SC.StyledHeader {...headerProps} />}
        {adProps && <SC.StyledAd {...adProps} />}
        <SC.Main
          id={idContent}
          $withMobileModal={withMobileModal}
          $withAd={!!adProps && adProps.isBoxed}
        >
          {breadcrumbProps?.entries && breadcrumbProps?.entries.length > 0 && (
            <SC.Breadcrumb {...breadcrumbProps} />
          )}
          <SC.Content>{children}</SC.Content>
        </SC.Main>
        {footerProps && <Footer {...footerProps} />}
      </SC.Layout>
      {productPopinProps && <ProductPopin {...productPopinProps} />}
      {classicAuthModalProps && <AuthPopin {...classicAuthModalProps} />}
      {lightAuthLoginModalProps && (
        <LightAuthLoginModal {...lightAuthLoginModalProps} />
      )}
      {lightAuthRegisterModalProps && (
        <LightAuthRegisterModal {...lightAuthRegisterModalProps} />
      )}
      {lightAuthOptinModalProps && (
        <LightAuthOptinModal {...lightAuthOptinModalProps} />
      )}
    </>
  )
}

export default MainLayout
