import styled from 'styled-components'

import FormFieldInput from '../FormFieldInput'
import Icon from '../../../atoms/Icon'
import { mq, sizes } from '../../../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Input = styled(FormFieldInput)``
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.bismark};
`

export const Validations = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.8rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  padding: 0.6rem 1rem;
  border-radius: 0.4rem;
  margin-top: 0.4rem;

  @media ${mq(sizes.tablet)} {
    width: max-content;
    flex-wrap: unset;
  }
`

export const Validation = styled.div<{ $valid: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  ${({ theme }) => theme.textStyles.textXSmall}
  color: ${({ $valid, theme }) =>
    $valid ? theme.colors.arapawa : theme.colors.standardRed};
`

export const ValidationIcon = styled(Icon)`
  width: 1.6rem;
  height: 1.6rem;
`

export const Divider = styled.div`
  width: 1px;
  height: 1.2rem;
  background-color: ${({ theme }) => theme.colors.bismark};
  opacity: 0.2;
`
