import React, { FC, useEffect, useState } from 'react'
import cx from 'classnames'
import { useWindowSize } from 'usehooks-ts'

import { PictureProps } from '../../atoms/Picture'
import { FormikFormProps } from '../../form/FormikForm'
import { ActionButtonProps } from '../../atoms/ActionButton'
import FormikField, { FormikFieldProps } from '../../form/FormikField'
import { LinkProps } from '../../atoms/Link'
import { FormMessagesProps } from '../../form/FormMessages'
import { sizes } from '../../../theme'
import { Icons } from '../../atoms/Icon'
import { useNoOverflow } from '../../../hooks/useNoOverflow'

import * as SC from './styled'

export type AuthPopinProps = {
  className?: string
  isOpen?: boolean
  closeHandler?: () => void
  logoProps?: PictureProps
  title: string
  login: {
    title: string
    formikForm?: FormikFormProps
    submitButtonText?: string
    formikFieldEmailProps?: FormikFieldProps
    formikFieldPasswordProps?: FormikFieldProps
    linkResetPasswordProps?: LinkProps
    errors?: FormMessagesProps
  }
  social: {
    title: string
    actionFacebookButtonProps?: ActionButtonProps
    actionGoogleButtonProps?: ActionButtonProps
  }
  signUp: {
    title: string
    signUpButtonProps?: ActionButtonProps
  }
}

const AuthPopin: FC<AuthPopinProps> = (props) => {
  const {
    className,
    logoProps,
    isOpen,
    closeHandler,
    title,
    login,
    social,
    signUp,
  } = props
  const [isMobile, setIsMobile] = useState(false)

  const { width } = useWindowSize()

  useEffect(() => {
    setIsMobile(width < sizes.tablet)
  }, [width])

  useNoOverflow(isOpen ?? false)

  return (
    <SC.Container className={cx('AuthPopin', className)} aria-hidden={!isOpen}>
      <SC.Popin>
        {logoProps && (
          <SC.LogoContainer>
            <SC.StyledArc></SC.StyledArc>
            {logoProps && <SC.LogoImage {...logoProps} />}
            {closeHandler && (
              <SC.Close onClick={closeHandler}>
                <span>{'Close'}</span>
                <SC.CloseIcon icon={Icons.close} />
              </SC.Close>
            )}
          </SC.LogoContainer>
        )}
        <SC.Content>
          <SC.Title>{title}</SC.Title>
          <SC.Box>
            <SC.Login>
              <SC.Subtitle>{login.title}</SC.Subtitle>
              {login.formikForm && (
                <SC.Form {...login.formikForm}>
                  <SC.AuthFields>
                    {login.formikFieldEmailProps && (
                      <FormikField {...login.formikFieldEmailProps} />
                    )}
                    {login.formikFieldPasswordProps && (
                      <FormikField {...login.formikFieldPasswordProps} />
                    )}
                  </SC.AuthFields>

                  {login.linkResetPasswordProps && (
                    <SC.LinkResetPassword {...login.linkResetPasswordProps} />
                  )}

                  {login.errors && <SC.Errors {...login.errors} />}

                  <SC.SubmitButton type="submit">
                    {login.submitButtonText}
                  </SC.SubmitButton>
                </SC.Form>
              )}
            </SC.Login>
            <SC.Divider $show={!isMobile} />
            <SC.Other>
              {social && (
                <SC.Social>
                  <SC.Text>{social.title}</SC.Text>
                  {social.actionFacebookButtonProps && (
                    <SC.FacebookBtn {...social.actionFacebookButtonProps} />
                  )}
                  {social.actionGoogleButtonProps && (
                    <SC.GoogleBtn {...social.actionGoogleButtonProps} />
                  )}
                </SC.Social>
              )}
              <SC.Divider $show={isMobile} />
              {props.signUp && (
                <SC.SignUp>
                  <SC.Subtitle>{signUp.title}</SC.Subtitle>
                  {signUp.signUpButtonProps && (
                    <SC.SignUpButton {...signUp.signUpButtonProps} />
                  )}
                </SC.SignUp>
              )}
            </SC.Other>
          </SC.Box>
        </SC.Content>
      </SC.Popin>
      <SC.Overlay $isOpen={isOpen} onClick={closeHandler} type="button" />
    </SC.Container>
  )
}

export default AuthPopin
