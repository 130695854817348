import gql from 'graphql-tag'

export const FavoriteFragment = gql`
  fragment FavoriteFragment on Favorite {
    reach5Uid
    type
    id
    createdAt
  }
`
