import gql from 'graphql-tag'
import { RecipeCardFragment } from 'src/graphql/fragments/RecipeCardFragment'

import { ServiceQuery } from '../../../../redux/api/types/state'

const favoritesRecipes: ServiceQuery = {
  query: gql`
    query favoritesRecipes {
      favoritesRecipes {
        ...RecipeCardFragment
      }
    }
    ${RecipeCardFragment}
  `,
  transformer: (data: any) => {
    return data.favoritesRecipes
  },
}

export default favoritesRecipes
