import gql from 'graphql-tag'

import { SubNavColumn } from './SubNavColumn'

export const HeaderFragment = gql`
  fragment HeaderFragment on Configuration {
    header {
      items {
        href
        label
        columns {
          left {
            ...SubNavColumn
          }
          right {
            ...SubNavColumn
          }
        }
        mobileLinks {
          href
          label
          target
          obfuscate
        }
      }
    }
  }
  ${SubNavColumn}
`
