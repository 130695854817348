import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import type { RootState } from '../store'
import { i18nLabels } from '../../i18n'

import { actionTypes } from './types'
import { Locale } from './types/state'

export const name = 'i18n'

export type I18nState = {
  defaultLocale: string
  locale: string | null
  locales: [Locale] | null
  labels: i18nLabels | null
}

//
// Initial state
//

const initialState: I18nState = {
  defaultLocale: 'fr',
  locale: null,
  locales: null,
  labels: null,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLocale: (state, action: actionTypes.setLocale) => {
      const { locale } = action.payload
      state.locale = locale
    },
    setLocales: (state, action: actionTypes.setLocales) => {
      const { locales } = action.payload
      state.locales = locales
    },
    setLabels: (state, action: actionTypes.setLabels) => {
      const { labels } = action.payload
      state.labels = labels
    },
  },
  extraReducers: (builder) =>
    builder.addCase<any, PayloadAction<any>>(
      REHYDRATE,
      (_state, action: PayloadAction<any>) => {
        return action.payload?.[name] ?? initialState
      }
    ),
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[name]
const defaultLocale = (state: RootState) => root(state).defaultLocale
const locales = (state: RootState) => root(state).locales
const locale = (state: RootState) => root(state).locale
const labels = (state: RootState) => root(state).labels

export const selectors = {
  defaultLocale,
  locales,
  locale,
  labels,
}
