import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { PushAuthPopinProps } from '../../components/molecules/PushAuthPopin'
import { Icons } from '../../components/atoms/Icon'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'
import { actions, selectors } from '../../redux'

const useAuthPushPopin = (): PushAuthPopinProps => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const authStatusIsComputed = useSelector(selectors.auth.authStatusIsComputed)
  const authPushPopin = useSelector(selectors.app.authPushPopin)
  const isConnected = useSelector(selectors.auth.isConnected)
  const dispatch = useDispatch()

  const openPopin = () => {
    setOpen(true)
  }

  const handleClosePopin = () => {
    setOpen(false)
    dispatch(actions.app.setAuthPushPopin(true))
  }

  const handleOpenAuthPopin = () => {
    dispatch(actions.auth.setFullAuthPopinOpen(true))
  }

  useEffect(() => {
    if (authStatusIsComputed && !authPushPopin && !isConnected) {
      setTimeout(() => {
        openPopin()
      }, 5000)
    }
  }, [authPushPopin, authStatusIsComputed, isConnected])

  return {
    popin: {
      isOpen: open,
      closeHandler: handleClosePopin,
      showHeader: false,
      noWrapping: true,
    },
    background: {
      withHD: true,
      maxWidth: 880,
      width: 880,
      height: 215,
      alt: 'Lorem ipsum',
      images: [
        {
          src: '/static/assets/images/common/auth_push_popin_banner_mobile.webp',
          size: 750,
        },
        {
          src: '/static/assets/images/common/auth_push_popin_banner.webp',
          size: 1760,
        },
      ],
    },
    logo: {
      withHD: true,
      maxWidth: 200,
      width: 100,
      height: 100,
      alt: 'QVDF Logo',
      images: [
        {
          src: '/static/assets/images/common/logo-220-min.webp',
          size: 660,
        },
        {
          src: '/static/assets/images/common/logo-110-min.webp',
          size: 100,
        },
      ],
    },
    title: t('auth_push_popin_title'),
    subtitle: t('auth_push_popin_subtitle'),
    advantages: [
      {
        title: t('auth_push_popin_advantage_1_title'),
        text: t('auth_push_popin_advantage_1_text'),
        icon: {
          icon: Icons.toque,
        },
      },
      {
        title: t('auth_push_popin_advantage_2_title'),
        text: t('auth_push_popin_advantage_2_text'),
        icon: {
          icon: Icons.priceTag,
        },
      },
      {
        title: t('auth_push_popin_advantage_3_title'),
        text: t('auth_push_popin_advantage_3_text'),
        icon: {
          icon: Icons.puzzle,
        },
      },
    ],
    registerCta: {
      label: t('auth_push_popin_register_label'),
      href: Router.getRouteUrl(routes.signup),
      onClick: handleClosePopin,
    },
    loginCta: {
      label: t('auth_push_popin_login_label'),
      onClick: () => {
        handleClosePopin()
        handleOpenAuthPopin()
      },
    },
  }
}

export default useAuthPushPopin
