import gql from 'graphql-tag'

export const FormatNutritionalFoodFragment = gql`
  fragment FormatNutritionalFoodFragment on FormatNutritionalFood {
    unit
    value
    precision
    lessThan
  }
`
