import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { HighcoCart } from '../../graphql/generated/api-graphql'

import { actionTypes } from './types'
import {
  addToCartService,
  brandCouponsService,
  couponsService,
  formatBrandCouponsService,
  birthdayCouponsService,
  getCartService,
  printCartService,
  removeFromCartService,
} from './services'

export const name = 'coupons'

type SearchState = {
  cart: HighcoCart | null
  coupons: typeof couponsService.values
  brandCoupons: typeof brandCouponsService.values
  formatBrandCoupons: typeof formatBrandCouponsService.values
  getCart: typeof getCartService.values
  printCart: typeof printCartService.values
  addToCart: typeof addToCartService.values
  removeFromCart: typeof removeFromCartService.values
  birthdayCoupons: typeof birthdayCouponsService.values
}

//
// Initial state
//

const initialState: SearchState = {
  cart: null,
  coupons: couponsService.values,
  brandCoupons: brandCouponsService.values,
  formatBrandCoupons: formatBrandCouponsService.values,
  getCart: getCartService.values,
  printCart: printCartService.values,
  addToCart: addToCartService.values,
  removeFromCart: removeFromCartService.values,
  birthdayCoupons: birthdayCouponsService.values,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    init: () => undefined,
    resetCoupon: () => initialState,
    setCart: (state, action: actionTypes.setCart) => {
      state.cart = action.payload
    },
    ...couponsService.reducers,
    ...brandCouponsService.reducers,
    ...formatBrandCouponsService.reducers,
    ...getCartService.reducers,
    ...printCartService.reducers,
    ...addToCartService.reducers,
    ...removeFromCartService.reducers,
    ...birthdayCouponsService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[name]
const cart = (state: RootState) => root(state).cart
const coupons = (state: RootState) => root(state).coupons
const brandCoupons = (state: RootState) => root(state).brandCoupons
const formatBrandCoupons = (state: RootState) => root(state).formatBrandCoupons
const getCart = (state: RootState) => root(state).getCart
const printCart = (state: RootState) => root(state).printCart
const addToCart = (state: RootState) => root(state).addToCart
const removeFromCart = (state: RootState) => root(state).removeFromCart
const birthdayCoupons = (state: RootState) => root(state).birthdayCoupons

export const selectors = {
  cart,
  coupons,
  brandCoupons,
  getCart,
  printCart,
  addToCart,
  removeFromCart,
  formatBrandCoupons,
  birthdayCoupons,
}
