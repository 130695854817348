import React, { FC, useEffect, useState } from 'react'
import cx from 'classnames'

import { MainNavProps } from '../MainNav'
import { SkipNavProps } from '../../atoms/SkipNav'
import { SocialsProps } from '../../atoms/Socials'
import { IconsNavProps } from '../../atoms/IconsNav'
import { HeaderSearchProps } from '../HeaderSearch'
import { BurgerNavProps } from '../BurgerNav'
import HeaderPopinAuth, { HeaderPopinAuthProps } from '../HeaderPopinAuth'

import * as SC from './styled'

export type HeaderProps = {
  className?: string
  skipNav?: SkipNavProps
  burger?: BurgerNavProps
  socials?: SocialsProps
  search?: HeaderSearchProps
  iconsNav?: IconsNavProps
  mainNav?: MainNavProps
  mobileMenu?: any
  headerPopinProps?: HeaderPopinAuthProps
}

const Header: FC<HeaderProps> = (props) => {
  const {
    className,
    skipNav,
    burger,
    socials,
    search,
    iconsNav,
    mainNav,
    headerPopinProps,
  } = props

  const [active, setActive] = useState(false)

  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY > 0)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    let countActivated = 0

    window.addEventListener('keydown', function (event) {
      if (event.key === 'Tab') {
        countActivated = countActivated + 1
        if (countActivated <= 2) {
          setActive((current) => !current)
        }
      }
    })
  }, [])

  return (
    <>
      <SC.Header
        className={cx('Header', className, { fixed: isFixed })}
        id="mainHeader"
      >
        {skipNav && active && <SC.StyledSkipNav {...skipNav} />}
        {(socials || search || iconsNav) && (
          <SC.Overhead>
            <div>
              {burger && <SC.StyledBurgerNav {...burger} />}
              {socials && <SC.StyledSocials {...socials} />}
            </div>
            <div>
              {search && <SC.StyledSearch {...search} />}
              {iconsNav && <SC.StyledIconsNav {...iconsNav} />}
            </div>
          </SC.Overhead>
        )}
        {mainNav && <SC.StyledMainNav {...mainNav} />}
      </SC.Header>
      {headerPopinProps && <HeaderPopinAuth {...headerPopinProps} />}
    </>
  )
}

export default Header
