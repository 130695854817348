import { PictureProps } from '../../components/atoms/Picture'
import {
  Maybe,
  WediaImage,
  WediaVideo,
} from '../../graphql/generated/api-graphql'
import { VideoProps } from '../../components/atoms/Video'

export const wediaImagesTransform = (
  wedia?: Maybe<WediaImage>,
  transparentOnly?: boolean
): PictureProps['images'] => {
  const images = wedia?.variations
    ?.map((variation) => ({
      src: variation.url,
      size: variation.width,
      type: variation.mimeType,
    }))
    // remove the unusable variations
    .filter(
      (variation) =>
        variation.src.includes('square_2000') === false &&
        variation.src.includes('grignoux') === false
    )
    // remove the non-transparent variations if transparentOnly is true
    .filter((variation) => {
      if (!transparentOnly) return true
      return variation.type === 'image/png' ||
        variation.type === 'image/webp' ||
        variation.type === 'image/jpeg' ||
        variation.type === 'image/' // include jpeg as Wedia does not always provide PNG or WEBP
        ? true
        : false
    })

  return images ?? []
}

// useless since better to use fully formed url as they might vary if the image has been reframed
export const getDamImageUrl = (uid: string, name: string): string =>
  `https://dxm.dam.savencia.com/api/wedia/dam/variation/${uid}/${name}`

export const getWediaImageUrl = (uid: string): string => {
  return `${process.env.REACT_APP_API_URL}/api/dam/proxy?wedia_uuid=${uid}`
}

export const wediaImageToPoster = (image: WediaImage | Maybe<WediaImage>) => {
  return image?.variations?.find(
    (variation) => variation.name === 'savencia_400_webp'
  )?.url
}

export const transformWediaVideo = (
  video?: WediaVideo | Maybe<WediaVideo>,
  extra?: {
    image?: WediaImage | Maybe<WediaImage>
    options?: { [key: string]: string | boolean }
    srt?: string | null
  }
): VideoProps => {
  if (!video) {
    return {}
  }
  const { options, image, srt } = extra ?? {}

  return {
    sources:
      video?.sources?.map((source) => ({
        src: source?.source ?? '',
        type: source?.mimeType ?? '',
      })) ?? [],
    ...mdleSubtitleTrack(srt),
    ...(options ? options : { preload: 'metadata', controls: true }),
    ...(image && {
      poster: wediaImageToPoster(image),
      posterAlt: image?.alt ?? '',
    }),
  }
}

export const mdleSubtitleTrack = (
  srtUrl?: string | null
): {
  tracks: {
    src: string
    kind: string
    label: string
    srcLang: string
    default?: boolean
  }[]
} | null => {
  return srtUrl
    ? {
        tracks: [
          {
            src: srtUrl,
            kind: 'subtitles',
            label: 'en',
            srcLang: 'en',
            default: true,
          },
        ],
      }
    : null
}
