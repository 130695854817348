import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'
import { MemberFragment } from '../../../fragments/MemberFragment'

const memberUpdate: ServiceMutation = {
  mutation: gql`
    mutation memberUpdate($refreshToken: String, $input: MemberUpdateInput!) {
      memberUpdate(refreshToken: $refreshToken, input: $input) {
        member {
          ...MemberFragment
        }
        token {
          accessToken
          refreshToken
          expiresIn
        }
      }
    }
    ${MemberFragment}
  `,
  transformer: (data: any) => {
    return data?.memberUpdate
  },
}

export default memberUpdate
