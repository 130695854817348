import { useCallback, useEffect, useRef } from 'react'
import { Selector, useDispatch, useSelector } from 'react-redux'

import { ServiceValues } from '../helpers/ReduxHelpers'

export const useServiceRequest = <Data, Params>(
  selector: Selector<any, ServiceValues<Data, Params>>,
  action: any,
  actionReset?: any,
  onComplete?: (s: ServiceValues<Data, Params>) => void | null
): [ServiceValues<any, any>, (values: any) => void] => {
  const isPendingRef = useRef<boolean>(false)
  const dispatch = useDispatch()
  const submit: ServiceValues<Data, Params> = useSelector(selector)

  const handleSubmit = useCallback(
    (values: Params) => {
      isPendingRef.current = true
      dispatch?.(action(values))
    },
    [dispatch, action]
  )

  useEffect(() => {
    if (submit?.success && isPendingRef.current) {
      onComplete?.(submit)
    }
  }, [dispatch, submit, onComplete])

  useEffect(() => {
    if (
      (submit?.pending || submit?.complete) &&
      !isPendingRef.current &&
      actionReset
    ) {
      dispatch?.(actionReset())
    }
  }, [dispatch, actionReset, submit?.complete, submit?.pending])

  return [submit, handleSubmit]
}
