import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'

import { planService, plansService } from './services'

export const name = 'plans'

type PlansState = {
  plans: typeof plansService.values
  plan: typeof planService.values
}

//
// Initial state
//

const initialState: PlansState = {
  plans: plansService.values,
  plan: planService.values,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    init: () => undefined,
    ...plansService.reducers,
    ...planService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[name]
const plans = (state: RootState) => root(state).plans
const plan = (state: RootState) => root(state).plan

export const selectors = {
  plans,
  plan,
}
