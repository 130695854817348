import { BrandColorType } from './types'

const brandColors: BrandColorType = {
  DarkToneColor: '#1B5733',
  MediumToneColor: '#567841',
  LightToneColor: '#EDF5E6',
  UltraLightToneColor: '#FDF8EA',
  HighLightToneColor: '#C00000',
}

export default brandColors
