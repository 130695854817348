import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import {
  FLUSH,
  getStoredState,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'

import { app } from '../configuration'
import { getAuthCookie } from '../helpers/CookieHelpers'

import { reducers } from './index'
import rootSaga from './sagas'
import { persistConfig } from './persist'

export const rootReducer = combineReducers(reducers)

export type RootState = ReturnType<typeof rootReducer>

// use this in your components when you need a typed version of useSelector() hook
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

const makeStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = configureStore({
    reducer: persistedReducer,
    preloadedState: initialState,
    middleware: [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
      sagaMiddleware,
    ],
    devTools: process.env.NODE_ENV !== 'production',
  })

  const persistor = persistStore(store)

  if (!app.PERSIST_STORE_ENABLED) {
    persistor.pause()
    persistor.purge()
  }

  // purge store if user is disconnected
  getStoredState(persistConfig).then((state: any) => {
    const cookie = getAuthCookie()
    if (state?.auth?.token?.accessToken && !cookie?.accessToken) {
      persistor.purge()
    }
  })

  sagaMiddleware.run(rootSaga)

  return { store, persistor, sagaMiddleware }
}

export default makeStore()
