import { getService } from '../../helpers/ReduxHelpers'
import type {
  HighcoCart,
  HighcoCoupon,
  MutationCouponAddToCartArgs,
  MutationCouponRemoveFromCartArgs,
  QueryBrandCouponsArgs,
  QueryCouponsByModelArgs,
} from '../../graphql/generated/api-graphql'

export const couponsService = getService<'coupons', HighcoCoupon[], null>(
  'coupons'
)

export const brandCouponsService = getService<
  'brandCoupons',
  HighcoCoupon[],
  QueryBrandCouponsArgs
>('brandCoupons')
export const formatBrandCouponsService = getService<
  'formatBrandCoupons',
  HighcoCoupon[],
  QueryCouponsByModelArgs
>('formatBrandCoupons')
export const birthdayCouponsService = getService<
  'birthdayCoupons',
  HighcoCoupon[],
  QueryBrandCouponsArgs
>('birthdayCoupons')
export const getCartService = getService<'getCart', HighcoCart, null>('getCart')
export const addToCartService = getService<
  'addToCart',
  HighcoCart,
  MutationCouponAddToCartArgs
>('addToCart')
export const removeFromCartService = getService<
  'removeFromCart',
  HighcoCart,
  MutationCouponRemoveFromCartArgs
>('removeFromCart')
export const printCartService = getService<'printCart', string, null>(
  'printCart'
)
