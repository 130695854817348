import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'

const planJWT: ServiceQuery = {
  name: 'planJWT',
  query: gql`
    query planById($id: ID!) {
      planById(id: $id) {
        id
        iframe
      }
    }
  `,
  transformer: (data: any) => {
    return {
      plan: data?.planById,
    }
  },
}

export default planJWT
