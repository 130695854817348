import styled from 'styled-components'

import FormFieldInput from '../FormFieldInput'
import { Wrapper } from '../FormFieldInput/styled'

export const Input = styled(FormFieldInput)<{ $idDisabled: boolean }>`
  opacity: ${({ $idDisabled }) => ($idDisabled ? '0.5' : '1')};
`

export const AutoSuggest = styled.div`
  & .react-autosuggest__container {
    position: relative;
  }

  & .react-autosuggest__container--open {
    & ${Wrapper} {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  & .react-autosuggest__input--focused {
    outline: none;
  }

  & .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & .react-autosuggest__suggestions-container {
    display: none;
  }

  & .react-autosuggest__suggestions-container--open {
    ${({ theme }) => theme.textStyles.textMedium};
    display: block;
    width: 100%;
    position: absolute;
    top: 5.6rem;
    border: 1px solid ${({ theme }) => theme.colors.bismark};
    color: ${({ theme }) => theme.colors.arapawa};
    border-top: 0;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    overflow: hidden;
    padding-top: 1rem;

    &:before {
      position: absolute;
      content: '';
      background-color: ${({ theme }) => theme.colors.bismark};
      height: 1px;
      left: 1rem;
      right: 1rem;
      top: 0;
    }
  }

  & .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  & .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
`
