import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'

const searchEngineAutocomplete: ServiceQuery = {
  query: gql`
    query searchEngineAutocomplete($q: String!, $type: SearchEngineType) {
      searchEngineAutocomplete(q: $q, type: $type, page: 1, first: 10) {
        data {
          label
          type
          slug
        }
      }
    }
  `,
  transformer: (data: any) => {
    return data?.searchEngineAutocomplete?.data
  },
}

export default searchEngineAutocomplete
