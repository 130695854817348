import React, { FC, useMemo, useState } from 'react'

import Icon, { Icons } from '../../../atoms/Icon'
import { FormFieldInputProps } from '../FormFieldInput'
import { colors } from '../../../../theme'

import * as SC from './styled'

export type FormFieldPasswordProps = FormFieldInputProps & {
  texts?: {
    label?: string
  }
  rules?: {
    validation: RegExp
    message: string
  }[]
}

const FormFieldPassword: FC<FormFieldPasswordProps> = (props) => {
  const { texts, rules, value } = props
  const [visible, setVisible] = useState(false)

  const validationState = useMemo(() => {
    if (!value) {
      return rules?.map(() => false) || []
    }
    const v = value as string

    return rules?.map((rule) => rule.validation.test(v)) || []
  }, [rules, value])

  return (
    <SC.Container>
      <SC.Input
        {...props}
        type={visible ? 'text' : 'password'}
        rightAdornment={
          <SC.Button type="button" onClick={() => setVisible(!visible)}>
            {texts?.label && <i>{texts?.label}</i>}
            <Icon
              icon={visible ? Icons.eyeSlash : Icons.eye}
              width={24}
              height={24}
            />
          </SC.Button>
        }
      ></SC.Input>
      {rules && rules.length > 0 && (
        <SC.Validations>
          {rules?.map((rule, index) => (
            <React.Fragment key={index}>
              <SC.Validation $valid={validationState[index]}>
                <SC.ValidationIcon
                  icon={validationState[index] ? Icons.check : Icons.close}
                  color={
                    validationState[index] ? colors.bayLeaf : colors.standardRed
                  }
                />
                {rule.message}
              </SC.Validation>
              {index < rules.length - 1 && <SC.Divider />}
            </React.Fragment>
          ))}
        </SC.Validations>
      )}
    </SC.Container>
  )
}

export default FormFieldPassword
