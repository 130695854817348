import { FC } from 'react'
import cx from 'classnames'

import { PopinProps } from '../../atoms/Popin'
import { PictureProps } from '../../atoms/Picture'
import { ActionButtonProps } from '../../atoms/ActionButton'
import { EcoPushProps } from '../../atoms/EcoPush'
import { CouponCardsBlockProps } from '../CouponCardsBlock'
import { Variant as EcoPushVariant } from '../../atoms/EcoPush/types'
import { HtmlProps } from '../../atoms/Html'
import { NutritionBlockProps } from '../NutritionBlock'

import * as SC from './styled'

export type ProductPopinProps = MinimalSeoBlock & {
  className?: string
  popin: Omit<PopinProps, 'children'>
  imageProps?: PictureProps
  thumbnailImageProps?: PictureProps
  introductionhtmlProps?: HtmlProps
  title?: string
  buy?: ActionButtonProps
  htmlProps?: HtmlProps
  seeMore?: ActionButtonProps
  coupons?: CouponCardsBlockProps
  nutrition?: NutritionBlockProps
  ecoPush?: EcoPushProps
}

const ProductPopin: FC<ProductPopinProps> = (props) => {
  const {
    className,
    popin,
    imageProps,
    thumbnailImageProps,
    title,
    htmlTitleTag,
    buy,
    introductionhtmlProps,
    htmlProps,
    seeMore,
    coupons,
    nutrition,
    ecoPush,
  } = props

  return (
    <SC.ProductPopin className={cx('ProductPopin', className)} {...popin}>
      <SC.Header>
        {imageProps && <SC.Banner {...imageProps} />}
        {thumbnailImageProps && <SC.Thumbnail {...thumbnailImageProps} />}
        <SC.TitleZone>
          {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
          {buy && <SC.Buy {...buy} />}
        </SC.TitleZone>
      </SC.Header>
      {introductionhtmlProps && <SC.Introduction {...introductionhtmlProps} />}
      {htmlProps && <SC.Text {...htmlProps} />}
      {coupons && <SC.Coupons {...coupons} />}
      {nutrition && <SC.Nutrition {...nutrition} />}
      {seeMore && <SC.SeeMore {...seeMore} />}
      {ecoPush && (
        <SC.StyledEcoPush
          {...ecoPush}
          htmlTag="div"
          variant={EcoPushVariant.Small}
        />
      )}
    </SC.ProductPopin>
  )
}

export default ProductPopin
