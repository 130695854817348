import styled, { css } from 'styled-components'

import Icon from '../../../atoms/Icon'

type InputStyleProps = {
  $isError?: boolean
  $isActive?: boolean
  $hasLabel?: boolean
  $isDisabled?: boolean
  $hasErrorMessage?: boolean
}

export const Container = styled.div<InputStyleProps>`
  display: flex;
  flex-flow: column;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      opacity: 0.8;
      &,
      & * {
        cursor: not-allowed;
      }
    `}
`

export const InputContainer = styled.label<InputStyleProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.colors.pattensBlue};
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 0.6rem;
  position: relative;
  width: 100%;

  ${({ $isError, theme }) =>
    $isError === true &&
    css`
      border-color: ${theme.colors.error};
    `}
`

export const DropDown = styled(Icon)`
  width: 1.5rem;
  position: absolute;
  color: ${({ theme }) => theme.colors.bismark};
  top: calc(50% - 0.75rem);
  right: 1rem;
`

export const Label = styled.div<{ $required?: boolean; $active?: boolean }>`
  ${({ theme }) => theme.textStyles.textSmallStrong}
  color: ${({ theme }) => theme.colors.bismark};
  position: absolute;
  transition: top 0.2s, font-size 0.2s, transform 0.2s, font-weight 0.2s;
  left: 2rem;
  top: calc(50% - 1rem);
  ${(props) =>
    props.$required &&
    css`
      &::after {
        content: '*';
        position: relative;
        padding-left: 0.2rem;
      }
    `}
  ${(props) =>
    props.$active &&
    css`
      ${({ theme }) => theme.textStyles.textXSmall}
      top: 0.75rem;
    `}
`
export const selectStyles = css<InputStyleProps>`
  appearance: none;
  // Additional resets for further consistency
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  outline: none;
  position: relative;
  z-index: 2;

  ${({ theme }) => theme.textStyles.textMedium};
  line-height: 1.1;
  border: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  background: transparent;
  padding: 1.8rem 3rem 1.8rem 2rem;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      pointer-events: none;
    `}

  ${({ $hasLabel, $isActive }) =>
    $hasLabel &&
    css`
      position: relative;
      transition: padding-top 0.2s, padding-bottom 0.2s;
      padding-top: 1.8rem;
      padding-bottom: 1.8rem;
      ${$isActive &&
      css`
        padding-top: 2.8rem;
        padding-bottom: 0.8rem;
      `}
    `}

  &::placeholder {
    opacity: 0.5;
  }

  // readonly
  &[readonly] {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &[type='password'] {
    letter-spacing: 3px;
  }
`
export const Input = styled.select<InputStyleProps>`
  ${selectStyles}

  &:focus {
    outline: 0.1rem dotted #000;
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:focus-visible {
    outline: 0.1rem dotted #000;
  }
`

const HintMessage = styled.p`
  ${({ theme }) => theme.textStyles.textXSmall}
  margin-top: 0.5rem;
  padding-inline: 0.8rem;
  text-align: left;
`
export const HelpMessage = styled(HintMessage)`
  color: ${({ theme }) => theme.colors.bismark};
`
export const ErrorMessage = styled(HintMessage)`
  color: ${({ theme }) => theme.colors.error};
`
