import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Html from '../Html'
import Picture from '../Picture'
import Icon from '../Icon'

import { Variant } from './types'

export const EcoPush = styled.aside<{ $variant?: Variant }>`
  display: grid;
  grid-template-columns: 4.2rem 1fr;
  grid-gap: 1rem;
  align-items: flex-start;
  padding: 2rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
  border-radius: 3rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.tablet)} {
    grid-template-columns: 6.2rem 1fr;
    grid-gap: 2rem;
    padding: ${({ $variant }) =>
      $variant === Variant.Small ? '2rem' : '4rem'};
    border-radius: 8rem;
  }
`

export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.titleH8}
  align-self: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.arapawa};
  & > * {
    margin: 0;
  }
  & > :not(:first-child) {
    margin-top: 1rem;
  }
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.textSmall}
  }
`
export const StyledPicture = styled(Picture)`
  width: 4.2rem;
  aspect-ratio: 1 / 1;
  &,
  & img {
    display: block;
  }
  & img {
    width: 100%;
    object-fit: contain;
  }
  @media ${mq(sizes.tablet)} {
    width: 6.2rem;
  }
`

export const StyledIcon = styled(Icon)`
  width: 4.2rem;
  @media ${mq(sizes.tablet)} {
    width: 6.2rem;
  }
`
