import { getService } from '../../helpers/ReduxHelpers'
import type {
  CapriceValentineSession,
  MutationSoloPageCapriceValentineFetchSessionResponseArgs,
  MutationSoloPageCapriceValentineSaveSessionInputArgs,
  QuerySoloPageCapriceValentineSessionByUidArgs,
} from '../../graphql/generated/api-graphql'

export const capriceValentineCreateSessionService = getService<
  'capriceValentineCreateSession',
  CapriceValentineSession,
  null
>('capriceValentineCreateSession')

export const capriceValentineFetchSessionResponseService = getService<
  'capriceValentineFetchSessionResponse',
  CapriceValentineSession,
  MutationSoloPageCapriceValentineFetchSessionResponseArgs
>('capriceValentineFetchSessionResponse')

export const capriceValentineSaveSessionInputService = getService<
  'capriceValentineSaveSessionInput',
  CapriceValentineSession,
  MutationSoloPageCapriceValentineSaveSessionInputArgs
>('capriceValentineSaveSessionInput')

export const capriceValentineSessionByUidService = getService<
  'capriceValentineSessionByUid',
  CapriceValentineSession,
  QuerySoloPageCapriceValentineSessionByUidArgs
>('capriceValentineSessionByUid')
