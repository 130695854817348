import React, { FC } from 'react'

import * as SC from './styled'
import { HtmlLayout } from './types'

export type HtmlProps = Pick<MinimalSeoBlock, 'htmlTag'> & {
  className?: string
  text?: string
  children?: string
  layout?: HtmlLayout
}

const Html: FC<HtmlProps> = ({
  className,
  text,
  children,
  htmlTag,
  layout = HtmlLayout.default,
}) => {
  return text || children ? (
    <SC.Html
      className={className}
      dangerouslySetInnerHTML={{ __html: (text || children) ?? '' }}
      as={htmlTag}
      $layout={layout}
    />
  ) : null
}

export default Html
