import * as api from './api/redux'
import * as app from './app/redux'
import * as auth from './auth/redux'
import * as i18n from './i18n/redux'
import * as coupons from './coupons/redux'
import * as member from './member/redux'
import * as search from './search/redux'
import * as plans from './plans/redux'
import * as solo from './solo/redux'

export const actions = {
  app: app.actions,
  api: api.actions,
  auth: auth.actions,
  i18n: i18n.actions,
  coupons: coupons.actions,
  member: member.actions,
  search: search.actions,
  plans: plans.actions,
  solo: solo.actions,
}

export const selectors = {
  app: app.selectors,
  api: api.selectors,
  auth: auth.selectors,
  i18n: i18n.selectors,
  coupons: coupons.selectors,
  member: member.selectors,
  search: search.selectors,
  plans: plans.selectors,
  solo: solo.selectors,
}

export const reducers = {
  app: app.reducer,
  api: api.reducer,
  auth: auth.reducer,
  i18n: i18n.reducer,
  coupons: coupons.reducer,
  member: member.reducer,
  search: search.reducer,
  plans: plans.reducer,
  solo: solo.reducer,
}
