import React, { FC } from 'react'
import cx from 'classnames'

import { PictureProps } from '../Picture'
import { HtmlProps } from '../Html'
import { IconProps } from '../Icon'

import * as SC from './styled'
import { Variant } from './types'

export type EcoPushProps = MinimalSeoBlock & {
  className?: string
  htmlProps?: HtmlProps
  imageProps?: PictureProps
  variant?: Variant
  iconProps?: IconProps
}

const EcoPush: FC<EcoPushProps> = (props) => {
  const { className, htmlTag, htmlProps, imageProps, variant, iconProps } =
    props

  return htmlProps ? (
    <SC.EcoPush
      className={cx('EcoPush', className)}
      as={htmlTag}
      $variant={variant}
    >
      {imageProps && <SC.StyledPicture {...imageProps} />}
      {iconProps && <SC.StyledIcon {...iconProps} />}
      <SC.Text {...htmlProps} />
    </SC.EcoPush>
  ) : null
}

export default EcoPush
