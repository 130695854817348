import {
  declareFont,
  defineFont,
  fontStyles,
  fontWeights,
  Font,
} from '../utils/FontUtils'

enum FontKeys {
  WorkSansRegular = 'WorkSansRegular',
  WorkSansMedium = 'WorkSansMedium',
  WorkSansSemiBold = 'WorkSansSemiBold',
  WorkSansBold = 'WorkSansBold',
  PoppinsExtraBold = 'PoppinsExtraBold',
  PoppinsBold = 'PoppinsBold',
  PoppinsMedium = 'PoppinsMedium',
  PoppinsLight = 'PoppinsLight',
  Baloo2SemiBold = 'Baloo2SemiBold',
  SuperclarendonRegular = 'SuperclarendonRegular',
  TartineScriptBold = 'TartineScriptBold',
}

export const declarations: { [key in FontKeys]: Font } = {
  WorkSansRegular: {
    basename: 'WorkSans',
    fontFamily: 'WorkSans',
    fontWeight: fontWeights.regular,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
    isVariable: true,
    fontWeightRange: [100, 900],
  },
  WorkSansMedium: {
    basename: 'WorkSans',
    fontFamily: 'WorkSans',
    fontWeight: fontWeights.medium,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
    isVariable: true,
    isAlreadyDeclared: true,
  },
  WorkSansSemiBold: {
    basename: 'WorkSans',
    fontFamily: 'WorkSans',
    fontWeight: fontWeights.semiBold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
    isVariable: true,
    isAlreadyDeclared: true,
  },
  WorkSansBold: {
    basename: 'WorkSans',
    fontFamily: 'WorkSans',
    fontWeight: fontWeights.bold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
    isVariable: true,
    isAlreadyDeclared: true,
  },
  PoppinsExtraBold: {
    basename: 'PoppinsExtraBold',
    fontFamily: 'Poppins',
    fontWeight: fontWeights.extraBold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsBold: {
    basename: 'PoppinsBold',
    fontFamily: 'Poppins',
    fontWeight: fontWeights.bold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsMedium: {
    basename: 'PoppinsMedium',
    fontFamily: 'Poppins',
    fontWeight: fontWeights.medium,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsLight: {
    basename: 'PoppinsLight',
    fontFamily: 'Poppins',
    fontWeight: fontWeights.light,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  Baloo2SemiBold: {
    basename: 'Baloo2',
    fontFamily: 'Baloo2',
    fontWeight: fontWeights.semiBold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
    isVariable: true,
  },
  SuperclarendonRegular: {
    basename: 'Superclarendon-Regular',
    fontFamily: 'Superclarendon',
    fontWeight: fontWeights.regular,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  TartineScriptBold: {
    basename: 'TartineScript-Bold',
    fontFamily: 'TartineScript',
    fontWeight: fontWeights.bold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
}

export const fontsFaces = (): string =>
  Object.values(declarations)
    .filter((font) => !font.isAlreadyDeclared && font)
    .map(declareFont)
    .join(' ')

const fonts: { [key in FontKeys]?: string } = {
  WorkSansRegular: defineFont(declarations.WorkSansRegular),
  WorkSansMedium: defineFont(declarations.WorkSansMedium),
  WorkSansSemiBold: defineFont(declarations.WorkSansSemiBold),
  WorkSansBold: defineFont(declarations.WorkSansBold),
  PoppinsExtraBold: defineFont(declarations.PoppinsExtraBold),
  PoppinsBold: defineFont(declarations.PoppinsBold),
  PoppinsMedium: defineFont(declarations.PoppinsMedium),
  PoppinsLight: defineFont(declarations.PoppinsLight),
  Baloo2SemiBold: defineFont(declarations.Baloo2SemiBold),
  SuperclarendonRegular: defineFont(declarations.SuperclarendonRegular),
  TartineScriptBold: defineFont(declarations.TartineScriptBold),
}

export default fonts
