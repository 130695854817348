import Router, { routes } from '../../routes/Router'
import { SearchEngineAutocompleteResponseItem } from '../../graphql/generated/api-graphql'
import { FormFieldAutoSuggestValue } from '../../components/form/fields/FormFieldAutoSuggest'

export const suggestionTransformer = (
  suggestion: SearchEngineAutocompleteResponseItem
): FormFieldAutoSuggestValue => {
  const route =
    suggestion.type === 'recipe'
      ? routes.recipe
      : suggestion.type === 'brand'
      ? routes.brand
      : suggestion.type === 'article' || suggestion.type === 'culinaryarticle'
      ? routes.culinaryArticle
      : suggestion.type === 'culinaryfolder'
      ? routes.folder
      : suggestion.type === 'plate'
      ? routes.plate
      : suggestion.type === 'cheese'
      ? routes.cheese
      : null

  return {
    label: suggestion.label,
    value: route ? Router.getRouteUrl(route, { slug: suggestion.slug }) : '',
  }
}
