import gql from 'graphql-tag'
import { Query } from 'src/graphql/generated/api-graphql'

import { HeaderFragment } from '../../../fragments/HeaderFragment'
import { FooterFragment } from '../../../fragments/FooterFragment'
import { ServiceQuery } from '../../../../redux/api/types/state'
import { WediaImageFragment } from '../../../fragments/WediaImageFragment'
import { PagesByTemplate } from '../../../../relay/Page/PageByTemplate'
import { Configuration } from '../../../../relay/Common/PageProps'

const configuration: ServiceQuery = {
  name: 'configuration',
  query: gql`
    query header {
      configuration {
        ...HeaderFragment
        ...FooterFragment
        aopImage {
          ...WediaImageFragment
        }
        notFound {
          image {
            ...WediaImageFragment
          }
        }
        maintenance {
          enabled
          image {
            ...WediaImageFragment
          }
        }
        highco {
          maintenance
        }
        globalPageWrapEnabled
      }
      pages(first: 500) {
        data {
          title
          slug
          template
        }
      }
    }
    ${HeaderFragment}
    ${FooterFragment}
    ${WediaImageFragment}
  `,
  transformer: (data: Query): Configuration => {
    return {
      header: data?.configuration.header,
      footer: data.configuration.footer,
      aopImage: data?.configuration?.aopImage,
      pagesByTemplate:
        data?.pages?.data?.reduce((obj, { template, ...page }) => {
          if (obj?.[template]) {
            if (page.slug?.includes('/')) {
              return obj
            }
          }
          return {
            ...obj,
            [template]: {
              title: page?.title,
              slug: ('/' + page?.slug)?.replace(/\/+/, '/'),
            },
          }
        }, {} as PagesByTemplate) ?? {},
      notFound: data?.configuration?.notFound,
      maintenance: data?.configuration?.maintenance,
      highco: data?.configuration?.highco,
      globalPageWrapEnabled:
        data?.configuration?.globalPageWrapEnabled ?? false,
    }
  },
}

export default configuration
