import styled, { css } from 'styled-components'

import Picture from '../../atoms/Picture'
import Socials from '../../atoms/Socials'
import FooterRegister from '../FooterRegister'
import Link from '../../atoms/Link'
import { mq, sizes } from '../../../theme'
import FooterMenu from '../FooterMenu'

const boxedBlock = css`
  width: 100%;
  max-width: ${({ theme }) => (theme.sizes.scopingMedium + 40) / 10}rem;
`

export const Main = styled.footer`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.pureWhite};

  @media print {
    display: none;
  }
`

export const Wrapper = styled.nav`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Head = styled.div`
  ${boxedBlock}
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${mq(sizes.tablet)} {
    flex-direction: row;
    & > :not(:first-child) {
      width: 100%;
    }
    gap: 6rem;
  }
`

export const Section = styled.div<{
  $alignSelf?: string
  $flexDirection?: string
  $alignItems?: string
}>`
  display: flex;
  gap: 4rem;
  align-items: center;
  padding: 2rem;
  @media ${mq(sizes.tablet)} {
    align-self: ${({ $alignSelf }) => $alignSelf ?? 'stretch'};
    flex-direction: ${({ $flexDirection }) => $flexDirection ?? 'row'};
    align-items: ${({ $alignItems }) => $alignItems ?? 'initial'};
  }
`
export const Separator = styled.hr<{ $ishiddenMobile?: boolean }>`
  display: ${({ $ishiddenMobile }) =>
    $ishiddenMobile === true ? `none` : 'block'};
  margin: 0;
  width: 100%;
  height: 0;
  border: 0;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.chablis};
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`
export const Logo = styled(Picture)`
  & img {
    object-fit: contain;
    width: 11rem;
    height: 8.5rem;
    max-width: initial;
    max-height: initial;

    @media ${mq(sizes.tablet)} {
      width: 18rem;
      height: 14rem;
    }
  }
`

export const StyledSocial = styled(Socials)`
  max-width: 21rem;
  & svg {
    width: 3.2rem;
    height: 3.2rem;
  }
`

export const Register = styled(FooterRegister)``

export const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.snow};
`

export const Menu = styled(FooterMenu)`
  display: none;
  @media ${mq(sizes.tablet)} {
    ${boxedBlock}
    display: flex;
  }
`

export const LegalLinksWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.snow};
`

export const LegalLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 3rem;
  text-align: center;
  @media ${mq(sizes.tablet)} {
    ${boxedBlock}
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem 2.6rem;
  }
`
export const Legal = styled(Link)`
  ${({ theme }) => theme.textStyles.textXSmall};
  color: ${({ theme }) => theme.colors.bismark};
  transition: color 0.3s;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.arapawa};
  }
  @media ${mq(sizes.tablet)} {
    position: relative;
    &:not(:last-child):after {
      content: '';
      position: absolute;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.atomicTangerine};
      left: 100%;
      top: 0;
      bottom: 0;
      margin: auto 1rem;
    }
  }
`
