import React, { FC } from 'react'
import cx from 'classnames'

import { PictureProps } from '../../atoms/Picture'
import { LinkProps } from '../../atoms/Link'
import { ActionButtonVariant } from '../../atoms/ActionButton/styled'

import * as SC from './styled'

export type NavCardProps = {
  className?: string
  title?: string
  subtitle?: string
  imageProps?: PictureProps
  link?: LinkProps
}

const NavCard: FC<NavCardProps> = (props) => {
  const { className, link, title, subtitle, imageProps } = props

  return (
    <SC.NavCard className={cx('NavCard', className)} {...link}>
      {title && <SC.CardTitle>{title}</SC.CardTitle>}
      {subtitle && <SC.CardSubtitle>{subtitle}</SC.CardSubtitle>}
      {link?.label && (
        <SC.CardAction
          label={link?.label}
          variant={ActionButtonVariant.secondary}
        />
      )}
      <SC.CardPicture {...imageProps} />
    </SC.NavCard>
  )
}

export default NavCard
