import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const ArticleCardFragment = gql`
  fragment ArticleCardFragment on Article {
    __typename
    id
    slug
    title
    title_homepage
    type
    mainImage {
      ...WediaImageFragment
    }
    thumbnail01 {
      ...WediaImageFragment
    }
    positiveFood
    # commented because it was breaking the "brand" page
    # e.g. http://127.0.0.1:3000/marque/saint-albray
    # brand {
    #   pictureLogo
    # }
    tagHealth {
      name
    }
    tagHealthTheme {
      name
    }
    tagHealthType {
      name
    }
    tagRegion {
      name
    }
    tagTheme {
      name
    }
  }
  ${WediaImageFragment}
`
