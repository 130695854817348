export default {
  DOMAIN: String(process.env.NEXT_PUBLIC_REACH_FIVE_DOMAIN),
  CLIENT_ID: String(process.env.NEXT_PUBLIC_REACH_FIVE_CLIENT_ID),
  REDIRECT_URI: String(process.env.NEXT_PUBLIC_REACH_FIVE_REDIRECT_URI),
  FIELDS:
    'id,lite_only,external_id,custom_identifier,name,given_name,family_name,username,middle_name,nickname,email,email_verified,phone_number,phone_number_verified,first_login,last_login,last_login_type,logins_count,gender,birthdate,age,addresses,custom_fields,consents,created_at,updated_at',
  REQUEST_PASSWORD_RESET_REDIRECT_URI: String(
    process.env.NEXT_PUBLIC_REACH_FIVE_REQUEST_PASSWORD_RESET_REDIRECT_URI
  ),
}
