import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const PlateCardFragment = gql`
  fragment PlateCardFragment on Plate {
    __typename
    id
    slug
    title
    mainImage {
      ...WediaImageFragment
    }
    thumbnail01 {
      ...WediaImageFragment
    }
    cheeses {
      id
    }
  }
  ${WediaImageFragment}
`
