import gql from 'graphql-tag'

export const WediaImageFragment = gql`
  fragment WediaImageFragment on WediaImage {
    uuid
    title
    downloadUrl
    alt
    variations {
      name
      url
      width
      height
      mimeType
    }
  }
`
