import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const RecipeCardFragment = gql`
  fragment RecipeCardFragment on Recipe {
    id
    slug
    title
    mainImage {
      ...WediaImageFragment
    }
    thumbnail01 {
      ...WediaImageFragment
    }
    brand {
      id
      name
      pictureLogo {
        ...WediaImageFragment
      }
    }
    positiveFood
    nutriscoreLetter
    #"tags" used to display details
    tagsPreparationTime {
      name
    }
    # actual tags
    tagsConsumptionTime {
      name
    }
    tagsDifficulty {
      name
    }
    tagsTheme {
      name
    }
  }
  ${WediaImageFragment}
`
