import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'

const getFavoritesByType: ServiceQuery = {
  query: gql`
    query favoritesByType($type: FavoriteType) {
      favoritesByType(type: $type) {
        reach5Uid
        type
        id
        createdAt
      }
    }
  `,
  transformer: (data: any) => {
    return data.favoritesByType
  },
}

export default getFavoritesByType
