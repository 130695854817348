import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'

const memberLogout: ServiceQuery = {
  query: gql`
    query memberLogout {
      memberLogout
    }
  `,
  transformer: (data: any) => {
    return data?.memberLogout
  },
}

export default memberLogout
