import gql from 'graphql-tag'

import { LocaleFragment } from '../../../fragments/LocaleFragment'
import { ServiceQuery } from '../../../../redux/api/types/state'

const localesAndLabels: ServiceQuery = {
  query: gql`
    query loadLocalesAndLabels($localeFilters: LocaleFilters!) {
      locales {
        ...LocaleFragment
      }
      labels(localeFilters: $localeFilters) {
        key
        value
        type
      }
    }
    ${LocaleFragment}
  `,
  transformer: (data: any) => {
    return {
      locales: data?.locales,
      labels: data?.labels,
    }
  },
}

export default localesAndLabels
