import styled, { css } from 'styled-components'

import Icon from '../../../atoms/Icon'
import { mq, sizes } from '../../../../theme'

export const Container = styled.div<{ $isDisabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;

  ${(props) =>
    props.$isDisabled &&
    css`
      opacity: 0.4;
    `}
`

export const Label = styled.label<{ $isDisabled: boolean }>`
  display: flex;
  align-items: baseline;
  gap: 1.5rem;
  cursor: pointer;
  position: relative;

  @media ${mq(sizes.tablet)} {
    align-items: center;
  }

  & input:checked ~ svg {
    background-color: ${({ theme }) => theme.colors.arapawa};

    & use {
      color: white;
    }
  }

  & input:checked ~ svg use {
    visibility: visible;
  }

  ${(props) =>
    props.$isDisabled &&
    css`
      cursor: not-allowed;
    `}
`

export const LabelText = styled.p<{ $required: boolean; $isError?: boolean }>`
  color: ${(props) => props.theme.colors.arapawa};
  ${({ theme }) => theme.textStyles.textMedium};

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.textLarge};
  }

  & a {
    text-decoration: underline;
  }

  ${(props) =>
    props.$required &&
    css`
      &:after {
        content: ' *';
        color: ${(props) => props.theme.colors.error};
      }
    `}

  ${(props) =>
    props.$isError &&
    css`
      color: ${props.theme.colors.error};
    `}
`

export const Checkbox = styled.input<{ $isError?: boolean }>`
  left: 0;
  position: absolute;
  z-index: 0;
  top: 0;
  opacity: 0;
  width: 1.5rem;
  height: 1.5rem;

  &:focus ~ svg {
    outline: 0.1rem dotted #000;
  }
  &:focus:not(:focus-visible) ~ svg {
    outline: none;
  }
  &:focus-visible ~ svg {
    outline: 0.1rem dotted #000;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`

export const StyledIcon = styled(Icon)<{ $isError?: boolean }>`
  z-index: 1;
  padding: 0px;
  height: 1.5rem;
  min-width: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.arapawa};
  border-radius: 0.3rem;

  & use {
    visibility: hidden;
  }
  ${({ $isError, theme }) =>
    $isError &&
    css`
      border-color: ${theme.colors.error};
    `}
`

export const Error = styled.p`
  ${({ theme }) => theme.textStyles.textMedium}
  color: ${({ theme }) => theme.colors.error};
  margin-top: 0.5rem;
`

export const Help = styled.p`
  ${({ theme }) => theme.textStyles.textSmall}
  color: grey;
  margin-top: 0.5rem;
`
