import React, { FC } from 'react'
import cx from 'classnames'

import { SearchFieldProps } from '../../atoms/SearchField'
import { LinkProps } from '../../atoms/Link'
import { IconProps, Icons } from '../../atoms/Icon'

import * as SC from './styled'

export type HeaderSearchProps = {
  className?: string
  search: SearchFieldProps
  link?: LinkProps
  icon?: IconProps
}

const HeaderSearch: FC<HeaderSearchProps> = (props) => {
  const {
    className,
    search,
    link,
    icon = { icon: Icons.search, width: 32, height: 32 },
  } = props

  return (
    <SC.HeaderSearch className={cx('HeaderSearch', className)}>
      <SC.Search {...search} />
      {link && icon && (
        <SC.StyledLink {...link}>
          <SC.StyledIcon {...icon} />
          {link.label && <SC.Label>{link.label}</SC.Label>}
        </SC.StyledLink>
      )}
    </SC.HeaderSearch>
  )
}

export default HeaderSearch
