import styled from 'styled-components'

import Popin from '../../atoms/Popin'
import { Wrapper } from '../../atoms/Popin/styled'
import Picture from '../../atoms/Picture'
import Icon from '../../atoms/Icon'
import { mq, sizes } from '../../../theme'
import ActionButton from '../../atoms/ActionButton'
import Link from '../../atoms/Link'

export const PopinContainer = styled(Popin)`
  color: ${({ theme }) => theme.colors.arapawa};
  & ${Wrapper} {
    max-width: 88rem;
  }
`

export const Header = styled.header`
  position: relative;
`

export const Background = styled(Picture)`
  & img {
    object-fit: cover;
    max-height: 21.5rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }
`

export const LogoWrapper = styled.div`
  display: none;

  @media ${mq(sizes.tablet)} {
    display: block;
  }
`

export const Logo = styled(Picture)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(20%);
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 0.5rem solid ${({ theme }) => theme.colors.pureWhite};
  z-index: 5;
`

export const Shape = styled(Icon)`
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 4;
  transform: translateX(-50%) translateY(20%);
  width: 16rem;
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Close = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: white;
  border-radius: 1.8rem;
  padding: 0.8rem 0.8rem 0.5rem 0.8rem;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.arapawa};
  & span {
    ${({ theme }) => theme.textStyles.accessibleHiddenText}
  }
`
export const CloseIcon = styled(Icon)`
  width: 3.2rem;
  height: 3.2rem;
`

export const Content = styled.div`
  z-index: 6;
  padding: 2rem 1.5rem 0 1.5rem;

  @media ${mq(sizes.tablet)} {
    padding: 4rem 3rem 1rem 3rem;
  }
`

export const Title = styled.p`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.ruby};
  text-align: center;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH4}
  }
`

export const Subtitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH4}
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  margin-top: 0.8rem;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH3}
  }
`

export const Advantages = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  margin-top: 2rem;
  background: ${({ theme }) => theme.colors.linen};
  padding: 1.5rem;
  padding-bottom: 5.5rem;
  border-radius: 1rem;
  justify-content: space-between;

  @media ${mq(sizes.tablet)} {
    flex-flow: row nowrap;
  }
`

export const Advantage = styled.div`
  display: flex;
  flex-direction: row;

  @media ${mq(sizes.tablet)} {
    flex-direction: column;
    align-items: center;
    max-width: 26rem;
  }
`

export const AdvantageIcon = styled(Icon)`
  width: 3rem;
  height: 3rem;
  color: ${({ theme }) => theme.colors.arapawa};
`

export const AdvantageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 3rem - 2rem);
  margin-left: 2rem;

  @media ${mq(sizes.tablet)} {
    width: 100%;
    margin-left: 0;
  }
`

export const AdvantageTitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.ruby};

  @media ${mq(sizes.tablet)} {
    margin-top: 1rem;
    text-align: center;
    ${({ theme }) => theme.textStyles.titleH4}
  }
`

export const AdvantageText = styled.p`
  ${({ theme }) => theme.textStyles.textMedium}
  color: ${({ theme }) => theme.colors.bismark};
  margin-top: 0.8rem;

  @media ${mq(sizes.tablet)} {
    text-align: center;
  }
`

export const Ctas = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: -1.5rem;
`

export const RegisterCta = styled(ActionButton)`
  text-align: center;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.zircon};
  margin: 2rem 0;
`

export const LoginCta = styled(Link)`
  ${({ theme }) => theme.textStyles.textLarge}
  color: ${({ theme }) => theme.colors.ruby};
  text-decoration: underline;
`
