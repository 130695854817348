import React, { FC, InputHTMLAttributes, useId, useState } from 'react'
import cx from 'classnames'

import { Icons } from '../../../atoms/Icon'

import * as SC from './styled'

export type SelectOption = {
  label: string
  value: string
  disabled?: boolean
  hidden?: boolean
}

export type FormFieldSelectProps = InputHTMLAttributes<HTMLSelectElement> & {
  name: string
  className?: string
  error?: string
  errors?: { message?: string }[]
  helpText?: string
  label?: string
  required?: boolean
  options?: SelectOption[]
  nullable?: boolean
  staticLabel?: boolean
  preselectFirstEntry?: boolean
}

const FormFieldSelect: FC<FormFieldSelectProps> = (props) => {
  const {
    className,
    value,
    name,
    id: propId,
    error,
    helpText,
    placeholder,
    onChange,
    label,
    required,
    options,
    nullable,
    staticLabel,
    preselectFirstEntry,
    ...inputProps
  } = props
  const localId = useId()
  const id = propId || localId
  const [isFocused, setFocused] = useState(false)
  const isActive = isFocused || !!value || staticLabel || !!placeholder
  const hasLabel = !!label

  return (
    <SC.Container className={cx('FormFieldSelect', className)}>
      <SC.InputContainer
        $isError={!!error}
        $isActive={isActive}
        $isDisabled={inputProps.disabled}
        $hasLabel={hasLabel}
        htmlFor={id}
      >
        <SC.Label $required={required} $active={isActive}>
          {label}
        </SC.Label>
        <SC.DropDown icon={Icons.chevronDown} />
        <SC.Input
          {...inputProps}
          {...(id && { id })}
          value={value}
          name={name}
          // @ts-ignore
          placeholder={placeholder}
          $isError={!!error}
          $isActive={isActive}
          $hasLabel={hasLabel}
          $isDisabled={inputProps.disabled}
          onFocus={() => setFocused(true)}
          onChange={onChange}
          onBlur={(e) => {
            inputProps?.onBlur?.(e)
            setFocused(false)
          }}
        >
          {/* Remove because not accessible to keyboord navigation */}
          {(placeholder || !preselectFirstEntry) && (
            <option value={''} disabled={!nullable} hidden={!nullable}>
              {placeholder}
            </option>
          )}
          {options?.map((option, index) => (
            <option
              value={option?.value}
              key={index}
              {...(option?.disabled && { disabled: true })}
              {...(option?.hidden && { hidden: true })}
            >
              {option?.label}
            </option>
          ))}
        </SC.Input>
      </SC.InputContainer>
      {!error && helpText && <SC.HelpMessage>{helpText}</SC.HelpMessage>}
      {error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
    </SC.Container>
  )
}

export default FormFieldSelect
