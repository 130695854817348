import { google } from '../configuration'

export const getRecaptchaToken = (): Promise<string> => {
  const grecaptcha = (window as any).grecaptcha
  if (!grecaptcha) {
    return Promise.reject('grecaptcha not found')
  }
  return new Promise((resolve) => {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(google.RECAPTCHA_SITE_KEY, { action: 'submit' })
        .then(function (token: string) {
          resolve(token)
        })
    })
  })
}
