import React, { FC } from 'react'
import * as Yup from 'yup'

import { PictureProps } from '../../atoms/Picture'
import { HtmlProps } from '../../atoms/Html'
import { Icons } from '../../atoms/Icon'
import FormMessages, { FormMessagesProps } from '../../form/FormMessages'
import FormFieldCheckbox, {
  FormFieldCheckboxProps,
} from '../../form/fields/FormFieldCheckbox'
import { ActionButtonProps } from '../../atoms/ActionButton'

import * as SC from './styled'

export type NewsletterPushValues = {
  consent1: boolean
  consent2: boolean
}

export type NewsletterPushProps = {
  className?: string
  imageProps: PictureProps
  title?: string
  legendProps?: HtmlProps
  fieldCheckProps?: FormFieldCheckboxProps
  fieldCheck2Props?: FormFieldCheckboxProps
  submitProps?: ActionButtonProps
  validation: {
    required?: string
    consent?: string
  }
  onSubmit: (v: NewsletterPushValues) => void
  successProps?: {
    title?: string
    text?: string
  }
  messagesProps?: FormMessagesProps
}

const NewsletterPush: FC<NewsletterPushProps> = ({
  className,
  imageProps,
  title,
  legendProps,
  fieldCheckProps,
  fieldCheck2Props,
  submitProps,
  onSubmit,
  successProps,
  messagesProps,
}) => {
  return (
    <SC.Main className={className}>
      <SC.BackgroundImage {...imageProps} aria-hidden />
      <SC.Overlay aria-hidden />
      <SC.Content>
        {successProps ? (
          <>
            <SC.Title>{successProps?.title}</SC.Title>
            <SC.CheckMark icon={Icons.check} />
            <SC.Text>{successProps?.text}</SC.Text>
          </>
        ) : (
          <>
            <SC.Title>{title}</SC.Title>
            <SC.Form
              initialValues={{ consent1: false, consent2: false }}
              scrollOnError={false}
              validationSchema={Yup.object().shape({
                consent1: Yup.boolean(),
                consent2: Yup.boolean(),
              })}
              validateOnChange
              onSubmit={onSubmit}
            >
              <SC.LegalField
                name="consent1"
                Component={FormFieldCheckbox}
                {...fieldCheckProps}
              />
              <SC.LegalField
                name="consent2"
                Component={FormFieldCheckbox}
                {...fieldCheck2Props}
              />
              <SC.SubmitCta type="submit" {...submitProps} />
            </SC.Form>
            {messagesProps && <FormMessages {...messagesProps} />}
            {legendProps && <SC.Legend {...legendProps} />}
          </>
        )}
      </SC.Content>
    </SC.Main>
  )
}

export default NewsletterPush
