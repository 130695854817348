import gql from 'graphql-tag'

import { LocaleFragment } from '../../../fragments/LocaleFragment'
import { ServiceQuery } from '../../../../redux/api/types/state'

const locales: ServiceQuery = {
  query: gql`
    query locales {
      locales {
        ...LocaleFragment
      }
    }
    ${LocaleFragment}
  `,
  transformer: (data: any) => {
    return data?.locales
  },
}

export default locales
