import React, { FC } from 'react'

import { ActionButtonProps } from '../../components/atoms/ActionButton'
import { PictureProps } from '../../components/atoms/Picture'
import { MainLayoutProps } from '../../layouts/MainLayout'

import * as SC from './styled'

export type MaintenanceTemplateProps = MainLayoutProps & {
  imageProps?: PictureProps
  logoProps?: PictureProps
  title?: string
  subtitle?: string
  text?: string
  buttonProps?: ActionButtonProps[]
}

const MaintenanceTemplate: FC<MaintenanceTemplateProps> = ({
  imageProps,
  logoProps,
  title,
  subtitle,
  text,
  buttonProps,
}) => {
  return (
    <SC.Maintenance>
      {imageProps && <SC.BackgroundImage {...imageProps} />}
      <SC.Content>
        {logoProps && (
          <SC.LogoContainer>
            <SC.StyledArc></SC.StyledArc>
            {logoProps && <SC.LogoImage {...logoProps} />}
          </SC.LogoContainer>
        )}
        <SC.TextsContent>
          {title && <SC.Title>{title}</SC.Title>}
          {subtitle && (
            <SC.Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
          )}
          {text && <SC.Text dangerouslySetInnerHTML={{ __html: text }} />}
          {buttonProps && (
            <SC.ButtonsContainer>
              {buttonProps?.map((item, i) => {
                return <SC.Button key={i} {...item} />
              })}
            </SC.ButtonsContainer>
          )}
        </SC.TextsContent>
      </SC.Content>
    </SC.Maintenance>
  )
}

export default MaintenanceTemplate
