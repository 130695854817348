import type { Client, Config } from '@reachfive/identity-core'

let reachFiveCreateClient: (creationConfig: Config) => Client
let client: Client
export const loadReachFive = async () => {
  if (!reachFiveCreateClient)
    reachFiveCreateClient = (await import('@reachfive/identity-core'))
      .createClient
  return reachFiveCreateClient
}

export const getClient = async (creationConfig: Config) => {
  if (!client) {
    const createClient: (creationConfig: Config) => Client =
      await loadReachFive()
    client = createClient(creationConfig)
  }
  return client
}
