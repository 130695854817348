import { BrandColorType } from './types'

const brandColors: BrandColorType = {
  DarkToneColor: '#006937',
  MediumToneColor: '#569D5A',
  LightToneColor: '#DBF2DD',
  UltraLightToneColor: '#EFFAF2',
  HighLightToneColor: '#C84540',
}

export default brandColors
