import gql from 'graphql-tag'

export const CapriceValentineSessionFragment = gql`
  fragment CapriceValentineSessionFragment on CapriceValentineSession {
    sessionUid
    reachFiveUid
    input1
    input1Tries
    input2
    input2Tries
    response
    createdAt
    updatedAt
  }
`
