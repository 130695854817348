import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'

const newsletterUnsubscribe: ServiceMutation = {
  mutation: gql`
    mutation newsletterUnsubscribe(
      $uid: String
      $email: String
      $reason: String!
      $consents: [String!]!
    ) {
      newsletterUnsubscribe(
        uid: $uid
        email: $email
        reason: $reason
        consents: $consents
      )
    }
  `,
  transformer: (data: any) => {
    return data?.newsletterUnsubscribe
  },
}

export default newsletterUnsubscribe
