import { ErrorResponse } from '@reachfive/identity-core'

export type GraphqlError = {
  label?: string
  field?: string
  type?: string
}
export type GraphqlErrors = GraphqlError[]

const transformError = (error: any): GraphqlError => {
  if (error?.extensions?.category === 'validation') {
    const validation = error?.extensions[error?.extensions?.category]

    return Object.keys(validation).reduce(
      (_r: any, v) => ({
        field: v.replace('input.', ''),
        label: validation[v][0],
      }),
      {}
    )
  }

  if (error?.message) {
    return {
      label: error.message,
      type: error?.extensions?.category,
    }
  }

  return {}
}

const transformReach5Error = (error: ErrorResponse): GraphqlError => {
  return {
    label: error.errorUserMsg,
  }
}

export const transformErrors = (errors: any): GraphqlErrors | null =>
  (errors && Array.isArray(errors)
    ? errors.map(transformError)?.filter((e) => !!e.label)
    : errors?.errorUserMsg // Reach5 error
    ? [transformReach5Error(errors)]
    : null) || null

export const enumOption = (
  t: any,
  name: string,
  value: string
): { label: string; value: string } => ({
  label: t(`enum_${name}_${value}`),
  value,
})
