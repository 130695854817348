import styled from 'styled-components'

import Icon from '../Icon'
import { Wrapper } from '../../form/fields/FormFieldInput/styled'
import FormFieldAutoSuggest from '../../form/fields/FormFieldAutoSuggest'

export const Form = styled.form``
export const Input = styled(FormFieldAutoSuggest)`
  & ${Wrapper} {
    padding: 0.8rem 1rem 0.8rem 2rem;
    border-radius: 1.2rem;
    border-color: ${({ theme }) => theme.colors.greyChateau};
  }
  & input {
    &::placeholder {
      opacity: 1;
      color: ${({ theme }) => theme.colors.bismark};
    }
  }
  & .react-autosuggest__suggestions-container--open {
    top: 100%;
    border-color: ${({ theme }) => theme.colors.greyChateau};
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    &:before {
      background-color: ${({ theme }) => theme.colors.greyChateau};
    }
  }
`
export const SearchButton = styled.button`
  width: 3.2rem;
  height: 3.2rem;
  flex-shrink: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  &:hover {
    cursor: pointer;
  }
`
export const SearchIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.arapawa};
`
export const SearchLabel = styled.span`
  ${({ theme }) => theme.textStyles.accessibleHiddenText}
`
