import i18next from 'i18next'
import { getOperationName } from '@apollo/client/utilities'
import { GetServerSidePropsContext } from 'next'

import { ApiResponse, ServiceQuery } from '../redux/api/types/state'
import { Configuration, Seo } from '../graphql/generated/api-graphql'
import { MetasProps } from '../components/atoms/Metas'
import { app } from '../configuration'
import ssrPersistedClient from '../graphql/ssrPersistedClient'
import ssrClient from '../graphql/ssrClient'

import { transformErrors } from './GraphqlHelpers'

export const ssrQuery = async function <T extends ServiceQuery = any>(
  service: T,
  variables?: any
): Promise<ApiResponse<T>> {
  let result
  const start = new Date().getTime()
  try {
    result = await ssrClient.query({
      query: service.query,
      variables,
    })
  } catch (e: any) {
    result = {
      errors: e,
      data: null,
    }
  }
  console.log(
    '[ssrQuery]',
    'non-persisted',
    service?.name ?? getOperationName(service.query),
    'duration',
    new Date().getTime() - start,
    'ms'
  )
  const data = result?.data
  if (result?.errors) {
    console.error(`ssrQuery Errors: `, result?.errors)
  }
  const errors = transformErrors(result?.errors)
  const transformer = service?.transformer
  return {
    ...result,
    errors,
    data: data && transformer ? transformer(data) : data,
  }
}

export const ssrPersistedQuery = async function <T extends ServiceQuery = any>(
  service: T,
  variables?: any
): Promise<ApiResponse<T>> {
  let result: any
  const start = new Date().getTime()
  try {
    result = await ssrPersistedClient.query({
      query: service.query,
      variables,
    })
  } catch (e: any) {
    result = {
      errors: e,
      data: null,
    }
  }
  console.log(
    '[SSRQuery]',
    'persisted',
    service?.name ?? getOperationName(service.query),
    'duration',
    new Date().getTime() - start,
    'ms',
    variables
  )
  const data = result?.data
  if (result?.errors) {
    console.error(`ssrQuery Errors: `, result?.errors)
  }
  const errors = transformErrors(result?.errors)
  const transformer = service?.transformer
  return {
    ...result,
    errors,
    data: data && transformer ? transformer(data) : data,
  }
}

export const noUndefined = function (source: any) {
  const result = source ? Object.assign(source) : null
  if (result) {
    Object.keys(source).forEach((k) => {
      if (result[k] === undefined) result[k] = null
      else if (Array.isArray(result[k])) result[k] = result[k].map(noUndefined)
      else if (typeof result[k] === 'object') result[k] = noUndefined(result[k])
    })
  }
  return source
}

export const serializeProof = function (source: any) {
  return JSON.parse(JSON.stringify(source))
}

export const getAllPaginatedResults = async (
  query: ServiceQuery,
  transform: (v: any) => any = (data) => data
) => {
  const firstResult = await ssrQuery(query, { page: 1 })
  const results = await Promise.all(
    Array.from({ length: firstResult?.data?.paginatorInfo?.lastPage - 1 }).map(
      (_a, index) => ssrQuery(query, { page: index + 2 })
    )
  )
  results.unshift(firstResult)
  return results.reduce(
    (arr, result) => arr.concat(result?.data?.data?.map(transform)),
    []
  )
}

export const transformSeoToMetas = (asPath: string, seo?: Seo): MetasProps => {
  return {
    applicationName: i18next.t('applicationName'),
    locale: 'fr_FR',
    title: seo?.title ?? '',
    description: seo?.description ?? '',
    image: seo?.picture ? app.APP_URL + seo?.picture : '', // todo transform picture ?
    robots: seo?.robots ?? '',
    url: app.APP_URL + asPath,
  }
}

export function isMaintenance(configuration: Configuration) {
  return configuration?.maintenance?.enabled
}

export function set503Header(context: GetServerSidePropsContext) {
  context.res.setHeader('Retry-After', '3600')
  context.res.setHeader(
    'Cache-Control',
    'private, no-cache, no-store, max-age=0, must-revalidate'
  )
  context.res.statusCode = 503
}

export function setDefaultCacheHeader(context: GetServerSidePropsContext) {
  // cache CDN
  context.res.setHeader(
    'Surrogate-Control',
    'public, max-age=86400, stale-while-revalidate=259200, stale-if-error=259200' // 1 journée / 3 jours
  )
  context.res.setHeader(
    'CDN-Cache-Control',
    'public, max-age=86400, stale-while-revalidate=259200, stale-if-error=259200' // 1 journée / 3 jours
  )
  // cache client
  context.res.setHeader(
    'Cache-Control',
    'public, max-age=3600, s-maxage=86400' // 1h client / 1 journée CDN
  )
}

export function setNoCacheHeader(context: GetServerSidePropsContext) {
  return context.res.setHeader(
    'Cache-Control',
    'private, no-cache, no-store, max-age=0, must-revalidate'
  )
}
