import { Icons } from '../Icon'

import { ActionButtonProps } from './index'

const defaultProps: ActionButtonProps = {}

export const actionButtonMock: ActionButtonProps = {
  ...defaultProps,
  label: 'Button',
}

export const actionButtonLinkMock: ActionButtonProps = {
  ...defaultProps,
  label: 'Button',
  href: '/',
}

export const actionButtonIconLabelMock: ActionButtonProps = {
  ...defaultProps,
  label: 'Button',
  iconPosition: 'right',
  iconProps: {
    icon: Icons.arrowRight,
  },
}

export const actionButtonSeeAllMock: ActionButtonProps = {
  ...defaultProps,
  label: 'Voir tout',
  iconPosition: 'right',
  iconProps: {
    icon: Icons.arrowRight,
  },
  href: '/',
}

export const actionButtonIconLabelFavoritesMock: ActionButtonProps = {
  ...defaultProps,
  label: 'Button',
  iconPosition: 'left',
  iconProps: {
    icon: Icons.heartLine,
  },
}

export const actionButtonIconMock: ActionButtonProps = {
  ...defaultProps,
  iconProps: {
    icon: Icons.socialFacebook,
  },
}
