import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'
import { TagDetailsFragment } from '../../../fragments/TagDetailsFragment'

const tag: ServiceQuery = {
  query: gql`
    query tag(
      $slug: String
      $type: TagItemType
      $page: Int
      $first: Int
      $orderBy: TagOrderBy
    ) {
      tag(
        slug: $slug
        type: $type
        page: $page
        first: $first
        orderBy: $orderBy
      ) {
        ...TagDetailsFragment
      }
    }
    ${TagDetailsFragment}
  `,
  transformer: (data: any) => {
    return data?.tag
  },
}

export default tag
