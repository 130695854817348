import styled from 'styled-components'

import Icon from '../../atoms/Icon'

export const Messages = styled.div<{ $isError: boolean }>`
  --color-icon: ${({ theme, $isError }) =>
    $isError ? theme.colors.standardRed : theme.colors.cornflowerBlue};
  position: relative;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  padding: 1rem 1rem 1rem 4.2rem;
  align-items: flex-start;
  border: 0.1rem solid
    ${({ theme, $isError }) =>
      $isError ? theme.colors.standardRed : theme.colors.blue};
  border-radius: 0.6rem;
  &:not(:first-child) {
    margin-top: 2rem;
  }
`
export const Message = styled.p`
  ${({ theme }) => theme.textStyles.textXSmall}
  color: ${({ theme }) => theme.colors.arapawa};
  &:first-of-type {
    margin-top: auto;
  }
  &:last-child {
    margin-bottom: auto;
  }
`
export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 1rem;
  color: var(--color-icon);
  transform: translateY(-50%);
`
