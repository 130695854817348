export enum SoloContentBlockMediaPosition {
  Default = 'Default',
  Left = 'Left',
  Right = 'Right',
}

export enum SoloContentBlockMediaSize {
  Default = 'Default',
  Big = 'Big',
}

export enum SoloContentBlockMediaMobilePosition {
  Top = 'Top',
  Bottom = 'Bottom',
}
