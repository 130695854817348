import { BrandColorType } from './types'

const brandColors: BrandColorType = {
  DarkToneColor: '#4E2614',
  MediumToneColor: '#588839',
  LightToneColor: '#EFDFBE',
  UltraLightToneColor: '#FCF3E1',
  HighLightToneColor: '#B23834',
}

export default brandColors
