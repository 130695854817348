import gql from 'graphql-tag'
import { CulinaryFolderCardFragment } from 'src/graphql/fragments/CulinaryFolderCardFragment'

import { ServiceQuery } from '../../../../redux/api/types/state'

const favoritesArticles: ServiceQuery = {
  query: gql`
    query favoritesCulinaryFolders {
      favoritesCulinaryFolders {
        ...CulinaryFolderCardFragment
      }
    }
    ${CulinaryFolderCardFragment}
  `,
  transformer: (data: any) => {
    return data.favoritesCulinaryFolders
  },
}

export default favoritesArticles
