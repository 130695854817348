/*
 * This project use mobile first integration
 * Default media is mobile
 */

const sizes = {
  mobile: 576,
  tablet: 768,
  withAd: 980,
  desktop: 992,
  video: 1070,
  scoping: 1200,
  scopingMedium: 1280,
  scopingLarge: 1340,
  scopingMax: 1440,
  scopingwithAd: 1480,
}

export default sizes
