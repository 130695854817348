import * as Yup from 'yup'

import { FormikFormProps } from './index'

const basic: FormikFormProps = {
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
  }),
  initialValues: {
    name: '',
    email: '',
  },
  onSubmit: (values) => {
    console.log(values)
  },
}

export const formikFormMocks = {
  basic,
}
