import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'
import { HighcoCartFragment } from '../../../fragments/HighcoCartFragment'

const couponGetCart: ServiceQuery = {
  query: gql`
    query couponGetCart {
      couponGetCart {
        ...HighcoCartFragment
      }
    }
    ${HighcoCartFragment}
  `,
  transformer: (data: any) => {
    return data?.couponGetCart
  },
}

export default couponGetCart
