import { SearchEngineType } from '../../graphql/generated/api-graphql'

export enum SearchType {
  all = 'all',
  Article = 'articles',
  Cheese = 'fromages',
  Folder = 'dossiers',
  Recipe = 'recettes',
}

export const searchTypeArray = [
  SearchType.all,
  SearchType.Recipe,
  SearchType.Article,
  SearchType.Cheese,
  SearchType.Folder,
] as const

export function resolveSearchEngineType(
  type: SearchType
): SearchEngineType | undefined {
  switch (type) {
    case SearchType.Article:
      return SearchEngineType.Article
    case SearchType.Cheese:
      return SearchEngineType.Cheese
    case SearchType.Recipe:
      return SearchEngineType.Recipe
    case SearchType.Folder:
      return SearchEngineType.Folder
  }
}
