import { FormFieldPasswordProps } from '../../components/form/fields/FormFieldPassword'

export const OLD_PASSWORD_MIN_LENGTH = 8
export const PASSWORD_MIN_LENGTH = 12

export const OLD_PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/

// Same as backend one
export const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-,+]).{12,}$/

export const getPasswordRules = (t?: any): FormFieldPasswordProps['rules'] => {
  return [
    {
      message: t('form_password_rules_min_length') || '12 caractères',
      validation: /.{12,}/,
    },
    {
      message: t('form_password_rules_uppercase') || '1 majuscule',
      validation: /[A-Z]/,
    },
    {
      message: t('form_password_rules_number') || '1 chiffre',
      validation: /\d/,
    },
    {
      message: t('form_password_rules_special') || '1 caractère spécial',
      validation: /[#?!@$%^&*\-,+]/,
    },
  ]
}

export const getUsernameInputProps = () => {
  return {
    autoComplete: 'username',
  }
}

export const getPasswordInputPropsForLogin = () => {
  return {
    autoComplete: 'current-password',
  }
}

export const getPasswordInputPropsForRegister = () => {
  return {
    autoComplete: 'new-password',
    minLength: PASSWORD_MIN_LENGTH,
  }
}
