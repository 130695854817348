import { Icons } from '../../components/atoms/Icon'
import brandColors from '../brandColors/caprice'
import colors from '../colors'

import { BrandSchemeType } from './types'

const brandScheme: BrandSchemeType = {
  DarkToneColor: brandColors.DarkToneColor,
  MediumToneColor: brandColors.MediumToneColor,
  LightToneColor: brandColors.LightToneColor,
  UltraLightToneColor: brandColors.UltraLightToneColor,
  HighLightToneColor: brandColors.HighLightToneColor,
  // general
  generalHeaderFill: colors.pureWhite,
  generalBodyFill: brandColors.LightToneColor,
  // hero
  heroShape: Icons.soloHeroRound,
  // nav
  navButtonColor: colors.arapawa,
  navButtonColorActive: colors.pureWhite,
  navButtonBorder: colors.greyChateau,
  navButtonBorderActive: brandColors.DarkToneColor,
  navButtonBackground: colors.pureWhite,
  navButtonBackgroundActive: brandColors.DarkToneColor,
  // anchors
  anchorsFill: brandColors.UltraLightToneColor,
  anchorsFillHover: brandColors.LightToneColor,
  anchorsFillActive: brandColors.LightToneColor,
  anchorsColor: brandColors.DarkToneColor,
  anchorsColorHover: brandColors.DarkToneColor,
  anchorsColorActive: brandColors.DarkToneColor,
  anchorsFixedFill: colors.pureWhite,
  anchorsFixedFillHover: brandColors.LightToneColor,
  anchorsFixedFillActive: brandColors.DarkToneColor,
  anchorsFixedColor: brandColors.DarkToneColor,
  anchorsFixedColorHover: brandColors.DarkToneColor,
  anchorsFixedColorActive: colors.pureWhite,
  anchorsTitleColor: colors.arapawa,
  // brand intro
  brandIntroTitleColor: brandColors.DarkToneColor,
  brandIntroSubtitleColor: brandColors.HighLightToneColor,
  // contact us
  contactUsTitleColor: colors.arapawa,
  contactUsSubtitleColor: brandColors.HighLightToneColor,
  // socials
  socialsTitleColor: colors.arapawa,
  socialsFill: brandColors.UltraLightToneColor,
  socialsFillActive: brandColors.LightToneColor,
  socialsColor: brandColors.DarkToneColor,
  // news - cards
  newsCardsTitle: brandColors.DarkToneColor,
  // news - push image
  newsPushImageTitle: brandColors.DarkToneColor,
  // news - video player
  newsVideoTitle: brandColors.DarkToneColor,
  // news - video list
  newsVideoListFill: colors.pureWhite,
  newsVideoListTitle: brandColors.DarkToneColor,
  newsVideoListSubtitle: brandColors.HighLightToneColor,
  newsVideoListVideoTitle: colors.arapawa,
  newsVideoListThumbnailFill: brandColors.UltraLightToneColor,
  newsVideoListThumbnailFillHover: brandColors.LightToneColor,
  newsVideoListThumbnailFillActive: brandColors.HighLightToneColor,
  // content (common to history and engagement at least)
  contentSide: brandColors.HighLightToneColor,
  contentDateTitle: brandColors.HighLightToneColor,
  contentSectionTitle: brandColors.DarkToneColor,
  contentBlockTitle: colors.arapawa,
  contentInsideTitle: brandColors.DarkToneColor,
  contentQuote: brandColors.DarkToneColor,
  // content - action block
  contentActionTitle: brandColors.DarkToneColor,
  contentActionText: colors.grey,
  // content anchors (...)
  contentAnchorsColor: colors.arapawa,
  contentAnchorsColorActive: colors.pureWhite,
  contentAnchorsBorder: colors.greyChateau,
  contentAnchorsBorderActive: brandColors.DarkToneColor,
  contentAnchorsBackground: 'transparent',
  contentAnchorsBackgroundActive: brandColors.DarkToneColor,
  // history
  historyTitle: brandColors.DarkToneColor,
  // history - anchors
  historyAnchorsFill: colors.pureWhite,
  historyAnchorsFillHover: brandColors.LightToneColor,
  historyAnchorsFillActive: brandColors.DarkToneColor,
  historyAnchorsColor: colors.bismark,
  historyAnchorsColorHover: colors.bismark,
  historyAnchorsColorActive: colors.pureWhite,
  // contact
  contactTitle: brandColors.DarkToneColor,
}

export default brandScheme
