import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import cx from 'classnames'
import { useDebounce } from 'usehooks-ts'
import { useRouter } from 'next/router'

import { LinkProps } from '../../atoms/Link'
import { PictureProps } from '../../atoms/Picture'
import { IconProps, Icons } from '../../atoms/Icon'
import { SubNavProps } from '../SubNav'

import * as SC from './styled'

export type NavLink = LinkProps & {
  altIcon?: IconProps
  subNavProps?: SubNavProps
}

export type MainNavProps = {
  className?: string
  logoImageProps: PictureProps
  // backHome is only passed when not on HP
  backLinkProps?: NavLink
  navLinkProps?: NavLink[]
  title?: string
}

const MainNav: FC<MainNavProps> = (props) => {
  const { className, logoImageProps, backLinkProps, navLinkProps, title } =
    props

  const [overlowingLeft, setOverflowingLeft] = useState(false)
  const [overlowingRight, setOverflowingRight] = useState(false)
  const [openMenuIndex, setOpenMenuIndex] = useState(-1)
  const [focusedMenuIndex, setFocusedMenuIndex] = useState(-1)

  const debouncedOverlowingLeft = useDebounce<boolean>(overlowingLeft, 200)
  const debouncedOverlowingRight = useDebounce<boolean>(overlowingRight, 200)

  const refList = useRef<HTMLDivElement>(null)
  const refWrapper = useRef<HTMLDivElement>(null)

  const router = useRouter()

  const handleCloseMenu = useCallback(() => {
    setOpenMenuIndex(-1)
  }, [])

  const handleBlurMenu = useCallback(() => {
    setFocusedMenuIndex(-1)
    setOpenMenuIndex(-1)
  }, [])

  const handleOpenMenu = useCallback(
    (index: number) => {
      handleCloseMenu()
      setOpenMenuIndex(index)
    },
    [handleCloseMenu]
  )

  const handleFocusMenu = useCallback((index: number) => {
    setFocusedMenuIndex(index)
  }, [])

  // render Logo with a link or not
  const Logo = useMemo(() => {
    return backLinkProps ? (
      <SC.LogoLink {...backLinkProps}>
        <SC.LogoPicture {...logoImageProps} />
      </SC.LogoLink>
    ) : (
      <SC.LogoTitle>
        {title && <span>{title}</span>}
        <SC.LogoPicture {...logoImageProps} />
      </SC.LogoTitle>
    )
  }, [backLinkProps, logoImageProps, title])

  // add "scroller" indicators
  useEffect(() => {
    const Wrapper = refWrapper.current
    const List = refList.current

    if (!window || !Wrapper || !List) return

    const handleScrollIndicators = () => {
      const WrapperBox = Wrapper.getBoundingClientRect()
      const ListBox = List.getBoundingClientRect()

      setOverflowingRight(ListBox.right > WrapperBox.right)
      setOverflowingLeft(ListBox.left < WrapperBox.left)
    }

    // on mount
    handleScrollIndicators()
    // on scroll
    Wrapper.addEventListener('scroll', handleScrollIndicators)
    window.addEventListener('resize', handleScrollIndicators)
    return () => {
      Wrapper.removeEventListener('scroll', handleScrollIndicators)
      window.removeEventListener('resize', handleScrollIndicators)
    }
  }, [])

  // divide level 1 in 2 so that the lgo can get its space
  const middleIndex = useMemo(() => {
    return navLinkProps ? Math.ceil(navLinkProps?.length / 2) : 0
  }, [navLinkProps])

  const firstMenu = useMemo(() => {
    return navLinkProps ? [...navLinkProps].splice(0, middleIndex) : []
  }, [navLinkProps, middleIndex])

  const lastMenu = useMemo(() => {
    return navLinkProps ? [...navLinkProps].splice(middleIndex) : []
  }, [navLinkProps, middleIndex])

  const renderSubList = useCallback(
    (menu: NavLink[], startIndex: number) => {
      return (
        <SC.Item>
          <SC.SubList>
            {menu.map((item, index) => {
              const trueIndex = index + startIndex

              return (
                <SC.SubItem
                  key={trueIndex}
                  onMouseEnter={() => handleOpenMenu(trueIndex)}
                  onMouseLeave={handleCloseMenu}
                  onFocus={() => handleFocusMenu(trueIndex)}
                  tabIndex={0}
                  id={`MainNav-SubItem-${trueIndex}`}
                  role="menu"
                  {...(item.subNavProps && {
                    'aria-controls': `MainNav-SubNav-${trueIndex}`,
                    'aria-expanded': trueIndex === openMenuIndex,
                  })}
                >
                  <SC.StyledLink
                    {...item}
                    onFocus={(e) => e.stopPropagation()}
                    role="menuitem"
                    $clickable={item.href !== undefined}
                  >
                    {item.altIcon && (
                      <SC.Styledicon {...item.altIcon} width={21} height={21} />
                    )}
                    <SC.Label>{item.label}</SC.Label>
                  </SC.StyledLink>
                  {item.subNavProps && (
                    <SC.StyledSubNav
                      id={`MainNav-SubNav-${trueIndex}`}
                      aria-labelledby={`MainNav-SubItem-${trueIndex}`}
                      aria-hidden={trueIndex !== openMenuIndex}
                      role="group"
                      open={trueIndex === openMenuIndex}
                      {...item.subNavProps}
                    />
                  )}
                </SC.SubItem>
              )
            })}
          </SC.SubList>
        </SC.Item>
      )
    },
    [handleCloseMenu, openMenuIndex, handleOpenMenu, handleFocusMenu]
  )

  // close the submenus on "escape" // toggle submenu on "space"
  useEffect(() => {
    if (focusedMenuIndex === -1) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleCloseMenu?.()
      } else if (
        focusedMenuIndex === openMenuIndex &&
        (e.key === ' ' || e.key === 'ArrowUp' || e.key === 'Up')
      ) {
        e.preventDefault()
        e.stopPropagation()
        handleCloseMenu()
      } else if (
        focusedMenuIndex !== openMenuIndex &&
        (e.key === ' ' || e.key === 'ArrowDown' || e.key === 'Up')
      ) {
        e.preventDefault()
        e.stopPropagation()
        handleOpenMenu(focusedMenuIndex)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleOpenMenu, handleCloseMenu, focusedMenuIndex, openMenuIndex])

  // on focus loss, close all menus
  useEffect(() => {
    const handleFocusOutside = (e: FocusEvent) => {
      const list = refList.current
      const target = e.target
      if (
        focusedMenuIndex !== -1 &&
        target instanceof Element &&
        list !== null &&
        !list.contains(target)
      ) {
        handleBlurMenu()
      }
    }

    // document.addEventListener('focus', this.documentFocus, true)
    window.addEventListener('focus', handleFocusOutside, true)
    return () => {
      window.removeEventListener('focus', handleFocusOutside)
    }
  }, [handleBlurMenu, focusedMenuIndex, openMenuIndex])

  // close the nav on route change
  useEffect(() => {
    if (!router) return
    router?.events.on('routeChangeStart', handleBlurMenu)
    return () => {
      router?.events.off('routeChangeStart', handleBlurMenu)
    }
  }, [handleBlurMenu, router])

  return (
    <SC.MainNav className={cx('MainNav', className)}>
      {logoImageProps && Logo}
      {navLinkProps && navLinkProps.length > 0 && (
        <SC.Wrapper ref={refWrapper} role="navigation">
          <SC.ScrollIndicator $isVisible={debouncedOverlowingLeft} aria-hidden>
            <SC.Styledicon icon={Icons.chevronLeft} width={21} height={21} />
          </SC.ScrollIndicator>
          <SC.List ref={refList}>
            {firstMenu && renderSubList(firstMenu, 0)}
            {lastMenu && renderSubList(lastMenu, firstMenu.length)}
          </SC.List>
          <SC.ScrollIndicator $isVisible={debouncedOverlowingRight} aria-hidden>
            <SC.Styledicon icon={Icons.chevronRight} width={21} height={21} />
          </SC.ScrollIndicator>
        </SC.Wrapper>
      )}
    </SC.MainNav>
  )
}

export default MainNav
