import { useTranslation } from 'react-i18next'
import { NextPage } from 'next'
import tracking, { TrackingContentGroup, TrackingUserType } from 'src/tracking'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectors } from 'src/redux/auth/redux'

import { ssrPersistedQuery } from '../helpers/SSRHelpers'
import { initWithLangAndLabels } from '../i18n'
import { Icons } from '../components/atoms/Icon'
import { ActionButtonVariant } from '../components/atoms/ActionButton/styled'
import { Configuration } from '../graphql/Services'
import { SocialNetwork } from '../graphql/generated/api-graphql'
import { wediaImagesTransform } from '../relay/Common/wedia'
import MaintenanceTemplate, {
  MaintenanceTemplateProps,
} from '../templates/MaintenanceTemplate'
import { PageProps } from '../relay/Common/PageProps'

export type MaintenancePageProps = PageProps & {
  //
}

const MaintenancePage: NextPage<MaintenancePageProps> = ({ configuration }) => {
  const { t } = useTranslation()

  const user = useSelector(selectors.user)
  useEffect(() => {
    tracking.page({
      group: TrackingContentGroup.MAINTENANCE,
      userType:
        user && user?.id ? TrackingUserType.MEMBER : TrackingUserType.VISITOR,
      userId: user?.id ?? '',
    })
  }, [user])

  const props: MaintenanceTemplateProps = {
    title: t('maintenance_title'),
    subtitle: configuration?.maintenance?.enabled
      ? t('maintenance_subtitle')
      : t('maintenance_subtitle_highco'),
    text: t('maintenance_text'),
    imageProps: {
      maxWidth: 2000,
      width: 1440,
      height: 1048,
      withHD: true,
      images: wediaImagesTransform(configuration?.maintenance?.image),
      alt: configuration?.maintenance?.image?.alt ?? '',
    },
    logoProps: {
      maxWidth: 160,
      width: 160,
      height: 160,
      withHD: true,
      images: [
        {
          src: '/static/assets/images/common/logo-220-min.png',
          size: 586,
        },
        {
          src: '/static/assets/images/common/logo-220-min.webp',
          size: 160,
        },
      ],
      alt: t('maintenance_title') ?? '',
    },
    buttonProps: configuration?.footer?.socialLinks?.map((item) => ({
      iconPosition: 'left',
      iconProps: {
        icon:
          {
            [SocialNetwork.Facebook]: Icons.socialFacebookColor,
            [SocialNetwork.Instagram]: Icons.socialInstagramColor,
            [SocialNetwork.Pinterest]: Icons.socialPinterestColor,
            [SocialNetwork.Twitter]: Icons.socialX,
          }?.[item.type] ?? Icons.cheese,
      },
      variant: ActionButtonVariant.secondary,
      label: t(`social_${item.type}`),
      href: item?.link?.href ?? '',
      target: '_blank',
      onClick: () => tracking.social(item.type, 'maintenance'),
    })),
  }
  return <MaintenanceTemplate {...props} />
}

export async function getStaticProps(): Promise<{
  props: MaintenancePageProps
}> {
  const i18n = initWithLangAndLabels('fr', {})

  const configurationRs = await ssrPersistedQuery(
    Configuration.queries.configuration
  )

  const configuration = configurationRs?.data ?? null

  return {
    props: {
      i18n,
      configuration,
    },
  }
}

export default MaintenancePage
