import { BrandColorType } from './types'

const brandColors: BrandColorType = {
  DarkToneColor: '#00ff00',
  MediumToneColor: '#00ff00',
  LightToneColor: '#00ff00',
  UltraLightToneColor: '#00ff00',
  HighLightToneColor: '#00ff00',
}

export default brandColors
