import styled from 'styled-components'

import Link from '../../atoms/Link'

export const FooterMenu = styled.div``

export const Wrapper = styled.div<{ $length?: number }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: ${({ $length }) =>
    $length ? `repeat(${$length - 2}, auto) 1fr` : 'auto'};
  width: 100%;
  padding: 2rem 3rem;
  gap: 3rem 1.6rem;
`

export const Section = styled.div`
  grid-column: 1 / 2;
  &:nth-last-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / -1;
  }
  &:last-child {
    grid-column: 3 / 4;
    grid-row: 1 / -1;
  }
`

export const SectionTitle = styled(Link)`
  display: inline-flex;
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.ruby};
  &:hover {
    text-decoration: underline;
  }
`

export const SectionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  ${Section}:last-child & {
    columns: 2;
    column-gap: 1.6rem;
  }
  & > li:not(:first-child) {
    margin-top: 0.5rem;
  }
`
export const SectionEntry = styled(Link)`
  display: inline-flex;
  ${({ theme }) => theme.textStyles.textSmall}
  color: ${({ theme }) => theme.colors.arapawa};
  transition: font-size 0.3s, font-weight 0.3s;
  &:hover {
    ${({ theme }) => theme.textStyles.textSmallStrong}
  }
`

export const SectionEntryTitle = styled(SectionEntry)`
  ${({ theme }) => theme.textStyles.textSmallStrong}
`
