import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import type { RootState } from '../store'
import { CapriceValentineSession } from '../../graphql/generated/api-graphql'
import {
  resolveCapriceValentineStep,
  SoloCapriceValentineError,
  SoloCapriceValentineStep,
} from '../../components/molecules/SoloCapriceValentineGame/types'

import { actionTypes } from './types'
import {
  capriceValentineCreateSessionService,
  capriceValentineFetchSessionResponseService,
  capriceValentineSaveSessionInputService,
  capriceValentineSessionByUidService,
} from './services'

export const name = 'solo'

type SoloState = {
  capriceValentineStep: SoloCapriceValentineStep
  capriceValentineCount: number
  capriceValentineError: SoloCapriceValentineError | null
  capriceValentineSession: CapriceValentineSession | null
  capriceValentineCreateSession: typeof capriceValentineCreateSessionService.values
  capriceValentineFetchSessionResponse: typeof capriceValentineFetchSessionResponseService.values
  capriceValentineSaveSessionInput: typeof capriceValentineSaveSessionInputService.values
  capriceValentineSessionByUid: typeof capriceValentineSessionByUidService.values
}

//
// Initial state
//

const servicesInitialState = {
  capriceValentineCreateSession: capriceValentineCreateSessionService.values,
  capriceValentineFetchSessionResponse:
    capriceValentineFetchSessionResponseService.values,
  capriceValentineSaveSessionInput:
    capriceValentineSaveSessionInputService.values,
  capriceValentineSessionByUid: capriceValentineSessionByUidService.values,
}

const initialState: SoloState = {
  capriceValentineError: null,
  capriceValentineStep: SoloCapriceValentineStep.INTRO,
  capriceValentineCount: 0,
  capriceValentineSession: null,
  ...servicesInitialState,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    init: () => undefined,
    linkCapriceSession: () => undefined,
    setCapriceValentineCount: (state, action: PayloadAction<number>) => {
      state.capriceValentineCount = action.payload
    },
    setCapriceValentineSession: (
      state,
      action: actionTypes.setCapriceValentineSession
    ) => {
      state.capriceValentineSession = action.payload
      state.capriceValentineStep = resolveCapriceValentineStep(
        state.capriceValentineSession
      )
    },
    setCapriceValentineStep: (
      state,
      action: actionTypes.setCapriceValentineStep
    ) => {
      state.capriceValentineStep = action.payload
    },
    setCapriceValentineError: (
      state,
      action: actionTypes.setCapriceValentineError
    ) => {
      state.capriceValentineError = action.payload
    },
    ...capriceValentineCreateSessionService.reducers,
    ...capriceValentineFetchSessionResponseService.reducers,
    ...capriceValentineSaveSessionInputService.reducers,
    ...capriceValentineSessionByUidService.reducers,
  },
  extraReducers: (builder) =>
    builder.addCase<any, PayloadAction<any>>(
      REHYDRATE,
      (_state, action: PayloadAction<any>) => {
        return {
          ...(action.payload?.[name] ?? initialState),
          ...servicesInitialState,
        }
      }
    ),
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[name]
const capriceValentineStep = (state: RootState) =>
  root(state).capriceValentineStep
const capriceValentineCount = (state: RootState) =>
  root(state).capriceValentineCount
const capriceValentineError = (state: RootState) =>
  root(state).capriceValentineError
const capriceValentineSession = (state: RootState) =>
  root(state).capriceValentineSession
const capriceValentineCreateSession = (state: RootState) =>
  root(state).capriceValentineCreateSession
const capriceValentineFetchSessionResponse = (state: RootState) =>
  root(state).capriceValentineFetchSessionResponse
const capriceValentineSaveSessionInput = (state: RootState) =>
  root(state).capriceValentineSaveSessionInput
const capriceValentineSessionByUid = (state: RootState) =>
  root(state).capriceValentineSessionByUid

export const selectors = {
  capriceValentineStep,
  capriceValentineCount,
  capriceValentineError,
  capriceValentineSession,
  capriceValentineCreateSession,
  capriceValentineFetchSessionResponse,
  capriceValentineSaveSessionInput,
  capriceValentineSessionByUid,
}
