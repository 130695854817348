import gql from 'graphql-tag'

export const MemberFragment = gql`
  fragment MemberFragment on Member {
    id
    userName
    name
    givenName
    familyName
    email
    phoneNumber
    gender
    birthdate
    lastLoginType
    addresses {
      id
      default
      streetAddress
      locality
      postalCode
      country
    }
    consents {
      type
      granted
      consentType
    }
    customFields {
      source
      dateInscritSite
      accountEnabled
      contactLanguage
      acquisitionChannel
      homePhone
      mobilePhone
      highcoId
      device
      cid
      qvdfKnowledge
      editorialInterestOne
      editorialInterestTwo
      editorialInterestThree
      favoriteBrandOne
      favoriteBrandTwo
      favoriteBrandThree
      favoriteBrandFour
      favoriteBrandFive
      favoriteCheeseOne
      favoriteCheeseTwo
      favoriteCheeseThree
      purchasingPreferenceOne
      purchasingPreferenceTwo
      purchasingPreferenceThree
      foodInterestMomentsOne
      foodInterestMomentsTwo
      foodInterestMomentsThree
      foodInterestMealOne
      foodInterestMealTwo
      foodInterestMealThree
      foodInterestRecipeOne
      foodInterestRecipeTwo
      foodInterestRecipeThree
      purchasingShelfPreferenceOne
      purchasingShelfPreferenceTwo
      hasChildren
      numberOfChildren
      yearOfBirthChildOne
      yearOfBirthChildTwo
      yearOfBirthChildThree
      yearOfBirthChildFour
      yearOfBirthChildFive
      passwordUpdatedAt
    }
    createdAt
  }
`
