import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import type { RootState } from '../store'

import { actionTypes } from './types'
import {
  newsletterSubscribeService,
  newsletterUnsubscribeService,
  memberRegisterService,
  memberDeleteService,
  memberUpdateService,
  favoriteToggleService,
  favoritesRecipesService,
  favoritesArticlesService,
  favoritesFoldersService,
  favoritesBrandsService,
  autocompleteByZipCodeService,
  autocompleteByCityIdAndAddress,
  checkMail,
} from './services'

export const name = 'member'

export type MemberState = {
  newsletterSubscribe: typeof newsletterSubscribeService.values
  newsletterUnsubscribe: typeof newsletterUnsubscribeService.values
  memberRegister: typeof memberRegisterService.values
  memberUpdate: typeof memberUpdateService.values
  memberDelete: typeof memberDeleteService.values
  favoriteToggle: typeof favoriteToggleService.values
  favoritesRecipes: typeof favoritesRecipesService.values
  favoritesArticles: typeof favoritesArticlesService.values
  favoritesFolders: typeof favoritesFoldersService.values
  favoritesBrands: typeof favoritesBrandsService.values
  dqeAutocompleteByZipCode: typeof autocompleteByZipCodeService.values
  dqeAutocompleteByCityIdAndAddress: typeof autocompleteByCityIdAndAddress.values
  dqeCheckMail: typeof checkMail.values
  isTryingToAccessUnauthorizedPage: string | null
  hasSeenAccountNewsletterPopin: boolean
}

//
// Initial state
//

const initialState: MemberState = {
  newsletterSubscribe: newsletterSubscribeService.values,
  newsletterUnsubscribe: newsletterUnsubscribeService.values,
  memberRegister: memberRegisterService.values,
  memberUpdate: memberUpdateService.values,
  memberDelete: memberDeleteService.values,
  favoriteToggle: favoriteToggleService.values,
  favoritesRecipes: favoritesRecipesService.values,
  favoritesArticles: favoritesArticlesService.values,
  favoritesFolders: favoritesFoldersService.values,
  favoritesBrands: favoritesBrandsService.values,
  dqeAutocompleteByZipCode: autocompleteByZipCodeService.values,
  dqeAutocompleteByCityIdAndAddress: autocompleteByCityIdAndAddress.values,
  dqeCheckMail: checkMail.values,
  isTryingToAccessUnauthorizedPage: null,
  hasSeenAccountNewsletterPopin: false,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...newsletterSubscribeService.reducers,
    ...newsletterUnsubscribeService.reducers,
    ...memberRegisterService.reducers,
    ...memberUpdateService.reducers,
    ...memberDeleteService.reducers,
    ...favoriteToggleService.reducers,
    ...favoritesRecipesService.reducers,
    ...favoritesArticlesService.reducers,
    ...favoritesFoldersService.reducers,
    ...favoritesBrandsService.reducers,
    ...autocompleteByZipCodeService.reducers,
    ...autocompleteByCityIdAndAddress.reducers,
    ...checkMail.reducers,
    setIsTryingToAccessUnauthorizedPage: (
      state,
      action: actionTypes.setIsTryingToAccessUnauthorizedPage
    ) => {
      state.isTryingToAccessUnauthorizedPage = action.payload
    },
    setHasSeenAccountNewsletterPopin: (
      state,
      action: actionTypes.setHasSeenAccountNewsletterPopin
    ) => {
      state.hasSeenAccountNewsletterPopin = action.payload
    },
  },
  extraReducers: (builder) =>
    builder.addCase<any, PayloadAction<any>>(
      REHYDRATE,
      (state, action: PayloadAction<any>) => {
        state.memberRegister = memberRegisterService.values
        state.newsletterSubscribe = newsletterSubscribeService.values
        if (action.payload?.member?.isTryingToAccessUnauthorizedPage) {
          state.isTryingToAccessUnauthorizedPage =
            action.payload.member.isTryingToAccessUnauthorizedPage
        }
        state.hasSeenAccountNewsletterPopin =
          action.payload?.member?.hasSeenAccountNewsletterPopin ?? false
      }
    ),
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]

const newsletterSubscribe = (state: RootState) =>
  root(state).newsletterSubscribe
const newsletterUnsubscribe = (state: RootState) =>
  root(state).newsletterUnsubscribe
const memberRegister = (state: RootState) => root(state).memberRegister
const memberUpdate = (state: RootState) => root(state).memberUpdate
const memberDelete = (state: RootState) => root(state).memberDelete

const favoriteToggle = (state: RootState) => root(state).favoriteToggle
const favoritesRecipes = (state: RootState) => root(state).favoritesRecipes
const favoritesArticles = (state: RootState) => root(state).favoritesArticles
const favoritesFolders = (state: RootState) => root(state).favoritesFolders
const favoritesBrands = (state: RootState) => root(state).favoritesBrands
const dqeAutocompleteByZipCode = (state: RootState) =>
  root(state).dqeAutocompleteByZipCode
const dqeAutocompleteByCityIdAndAddress = (state: RootState) =>
  root(state).dqeAutocompleteByCityIdAndAddress
const dqeCheckMail = (state: RootState) => root(state).dqeCheckMail
const isTryingToAccessUnauthorizedPage = (state: RootState) =>
  root(state).isTryingToAccessUnauthorizedPage
const hasSeenAccountNewsletterPopin = (state: RootState) =>
  root(state).hasSeenAccountNewsletterPopin

export const selectors = {
  // services
  newsletterSubscribe,
  newsletterUnsubscribe,
  memberRegister,
  memberUpdate,
  memberDelete,
  favoriteToggle,
  favoritesRecipes,
  favoritesArticles,
  favoritesFolders,
  favoritesBrands,
  dqeAutocompleteByZipCode,
  dqeAutocompleteByCityIdAndAddress,
  dqeCheckMail,
  // custom
  isTryingToAccessUnauthorizedPage,
  hasSeenAccountNewsletterPopin,
}
