import Cookies from 'universal-cookie'

import { app } from '../configuration'

export const getCookiesObj = (req: any = null) => {
  if (req?.universalCookies) {
    return req.universalCookies
  }

  return new Cookies()
}

export const setAuthCookie = (value: any, req = null) => {
  const cookies = getCookiesObj(req)

  cookies.set(app.COOKIE_NAME, value, {
    domain: app.COOKIE_DOMAIN,
    secure: app.SECURE_COOKIE,
    path: '/',
    expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 30), // 30 days
  })
}

export const getAuthCookie = (req = null) => {
  const cookies = getCookiesObj(req)
  return cookies.get(app.COOKIE_NAME)
}

export const removeAuthCookie = (req = null) => {
  const cookies = getCookiesObj(req)

  cookies.remove(app.COOKIE_NAME, {
    domain: app.COOKIE_DOMAIN,
    secure: app.SECURE_COOKIE,
    path: '/',
    expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 30), // 30 days
  })
}

export const getCookie = (cookie: string, req = null) => {
  const cookies = getCookiesObj(req)
  return cookies.get(cookie)
}

export const setCookie = (cookie: string, value: any, req = null) => {
  const cookies = getCookiesObj(req)

  cookies.set(cookie, value, {
    domain: app.COOKIE_DOMAIN,
    secure: app.SECURE_COOKIE,
    path: '/',
    expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 30), // 30 days
  })
}

export const removeCookie = (cookie: string, req = null) => {
  const cookies = getCookiesObj(req)

  cookies.remove(cookie, {
    domain: app.COOKIE_DOMAIN,
    secure: app.SECURE_COOKIE,
    path: '/',
    expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 30), // 30 days
  })
}
