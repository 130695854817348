import { getService } from '../../helpers/ReduxHelpers'

import { User } from './types/state'

export const registerService = getService<
  'register',
  User,
  { email: string; password: string; firstName: string; lastName: string }
>('register')
export const loginService = getService<
  'login',
  User,
  { email: string; password: string }
>('login')
export const socialLoginService = getService<
  'socialLogin',
  User,
  { provider: string }
>('socialLogin')
export const requestPasswordResetService = getService<
  'requestPasswordReset',
  boolean,
  { email: string }
>('requestPasswordReset')
export const resetPasswordService = getService<
  'resetPassword',
  boolean,
  { verification_code: string; email: string; password: string }
>('resetPassword')
