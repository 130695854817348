import { Icons } from '../../components/atoms/Icon'
import { SoloContentBlockMediaMobilePosition } from '../../components/molecules/SoloContentBlock/types'
import { SoloActionBlockTextSize } from '../../components/molecules/SoloActionBlock/types'
import { SoloContentTitleSize } from '../../components/molecules/SoloContentTitle/types'
import brandColors from '../brandColors/lion'
import colors from '../colors'
import { SoloHeroShapeStyle } from '../../components/molecules/SoloHero/types'

import { BrandSchemeType } from './types'

const brandScheme: BrandSchemeType = {
  DarkToneColor: brandColors.DarkToneColor,
  MediumToneColor: brandColors.MediumToneColor,
  LightToneColor: brandColors.LightToneColor,
  UltraLightToneColor: brandColors.UltraLightToneColor,
  HighLightToneColor: brandColors.HighLightToneColor,
  // general
  generalHeaderFill: colors.pureWhite,
  generalBodyFill: brandColors.UltraLightToneColor,
  // hero
  heroShape: Icons.soloHeroRound,
  heroShapeStyle: SoloHeroShapeStyle.lion,
  // nav
  navButtonColor: brandColors.DarkToneColor,
  navButtonColorActive: colors.pureWhite,
  navButtonBorder: brandColors.MediumToneColor,
  navButtonBorderActive: brandColors.HighLightToneColor,
  navButtonBackground: colors.pureWhite,
  navButtonBackgroundActive: brandColors.HighLightToneColor,
  // anchors (brand)
  anchorsFill: brandColors.UltraLightToneColor,
  anchorsFillHover: brandColors.LightToneColor,
  anchorsFillActive: brandColors.LightToneColor,
  anchorsColor: brandColors.DarkToneColor,
  anchorsColorHover: brandColors.DarkToneColor,
  anchorsColorActive: brandColors.DarkToneColor,
  anchorsFixedFill: colors.pureWhite,
  anchorsFixedFillHover: brandColors.LightToneColor,
  anchorsFixedFillActive: brandColors.DarkToneColor,
  anchorsFixedColor: brandColors.DarkToneColor,
  anchorsFixedColorHover: brandColors.DarkToneColor,
  anchorsFixedColorActive: colors.pureWhite,
  anchorsTitleColor: brandColors.DarkToneColor,
  // brand intro
  brandIntroTitleColor: brandColors.HighLightToneColor,
  brandIntroSubtitleColor: brandColors.DarkToneColor,
  // contact us
  contactUsTitleColor: brandColors.HighLightToneColor,
  contactUsSubtitleColor: brandColors.DarkToneColor,
  // socials
  socialsTitleColor: brandColors.DarkToneColor,
  socialsFill: brandColors.UltraLightToneColor,
  socialsFillActive: brandColors.LightToneColor,
  socialsColor: brandColors.HighLightToneColor,
  // news - cards
  newsCardsTitle: brandColors.DarkToneColor,
  // news - push image
  newsPushImageTitle: brandColors.DarkToneColor,
  // news - video player
  newsVideoTitle: brandColors.DarkToneColor,
  // news - video list
  newsVideoListFill: colors.pureWhite,
  newsVideoListTitle: brandColors.DarkToneColor,
  newsVideoListSubtitle: brandColors.HighLightToneColor,
  newsVideoListVideoTitle: colors.arapawa,
  newsVideoListThumbnailFill: brandColors.UltraLightToneColor,
  newsVideoListThumbnailFillHover: brandColors.LightToneColor,
  newsVideoListThumbnailFillActive: brandColors.HighLightToneColor,
  // content (common to history and engagement at least)
  contentSide: brandColors.HighLightToneColor,
  contentDateTitle: brandColors.HighLightToneColor,
  contentSectionTitle: brandColors.HighLightToneColor,
  contentSectionTitleVariant: SoloContentTitleSize.Big,
  contentBlockTitle: brandColors.HighLightToneColor,
  contentInsideTitle: brandColors.DarkToneColor,
  contentInsideSubTitle: brandColors.DarkToneColor,
  contentQuote: brandColors.MediumToneColor,
  contentMobileMediaPosition: SoloContentBlockMediaMobilePosition.Top,
  // content - action block
  contentActionTitle: colors.arapawa,
  contentActionText: colors.matterhorn,
  contentActionTextSize: SoloActionBlockTextSize.Small,
  // content anchors (...)
  contentAnchorsColor: brandColors.HighLightToneColor,
  contentAnchorsColorActive: colors.pureWhite,
  contentAnchorsBorder: brandColors.MediumToneColor,
  contentAnchorsBorderActive: brandColors.HighLightToneColor,
  contentAnchorsBackground: brandColors.UltraLightToneColor,
  contentAnchorsBackgroundActive: brandColors.HighLightToneColor,
  // history
  historyTitle: brandColors.DarkToneColor,
  // history - anchors
  historyAnchorsFill: colors.pureWhite,
  historyAnchorsFillHover: brandColors.UltraLightToneColor,
  historyAnchorsFillActive: brandColors.HighLightToneColor,
  historyAnchorsColor: colors.bismark,
  historyAnchorsColorHover: colors.bismark,
  historyAnchorsColorActive: colors.pureWhite,
  // contact
  contactTitle: colors.arapawa,
  // coupons
  couponsTitle: brandColors.DarkToneColor,
}

export default brandScheme
