import { useEffect } from 'react'

let overflows = 0
const refreshOverflow = (val: number) => {
  if (val > 0) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }
}

export const useNoOverflow = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      refreshOverflow(++overflows)
    }
    return () => {
      if (isOpen) {
        refreshOverflow(--overflows)
      }
    }
  }, [isOpen])
}
