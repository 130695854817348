import {
  SagaReturnType,
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { FavoriteType } from 'src/graphql/generated/api-graphql'
import favoritesRecipes from 'src/graphql/Services/Member/queries/favoritesRecipes'
import favoritesArticles from 'src/graphql/Services/Member/queries/favoritesArticles'
import favoritesFolders from 'src/graphql/Services/Member/queries/favoritesFolders'
import favoritesBrands from 'src/graphql/Services/Member/queries/favoritesBrands'
import {
  dqeAutocompleteByCityIdAndAddress,
  dqeAutocompleteByZipCode,
  dqeCheckMail,
} from 'src/graphql/Services/Member/queries'

import { actions, selectors } from '..'
import ApiSagas from '../api/sagas'
import {
  memberRegister,
  memberUpdate,
  newsletterSubscribe,
  newsletterUnsubscribe,
  favoriteToggle,
  memberDelete,
} from '../../graphql/Services/Member/mutations'
import { ApiResponse } from '../api/types/state'
import Router from '../../routes/Router'

export default class MemberSagas {
  static *onNewsletterSubscribeRequest({
    payload,
  }: ReturnType<typeof actions.member.newsletterSubscribeRequest>) {
    const rs: ApiResponse<typeof newsletterSubscribe> = yield call(
      ApiSagas.mutate,
      newsletterSubscribe,
      payload
    )

    if (rs.errors) {
      yield put(actions.member.newsletterSubscribeError(rs.errors))
    } else {
      yield put(actions.member.newsletterSubscribeSuccess(rs.data))
    }
  }

  static *onNewsletterUnsubscribeRequest({
    payload,
  }: ReturnType<typeof actions.member.newsletterUnsubscribeRequest>) {
    const rs: ApiResponse<typeof newsletterUnsubscribe> = yield call(
      ApiSagas.mutate,
      newsletterUnsubscribe,
      payload
    )

    if (rs.errors) {
      yield put(actions.member.newsletterUnsubscribeError(rs.errors))
    }
    // Always show success to avoid email fishing
    yield put(actions.member.newsletterUnsubscribeSuccess(rs.data))
    yield put(
      actions.app.setSuccessToaster('toaster_unsubscribe_newsletter_success')
    )
  }

  static *onMemberRegisterRequest({
    payload,
  }: ReturnType<typeof actions.member.memberRegisterRequest>) {
    // CapriceValentineSession
    const capriceValentineSession: SagaReturnType<
      typeof selectors.solo.capriceValentineSession
    > = yield select(selectors.solo.capriceValentineSession)
    const capriceValentineSessionUid = capriceValentineSession?.sessionUid

    const rs: ApiResponse<typeof memberRegister> = yield call(
      ApiSagas.mutate,
      memberRegister,
      { input: { ...payload.input, capriceValentineSessionUid } }
    )

    if (rs.errors) {
      yield put(actions.member.memberRegisterError(rs.errors))
    } else {
      yield put(actions.auth.setToken(rs.data?.token))
      yield put(actions.auth.setUser(rs.data?.member))
      yield put(actions.member.memberRegisterSuccess(rs.data))
    }
  }

  static *onMemberUpdateRequest({
    payload,
  }: ReturnType<typeof actions.member.memberUpdateRequest>) {
    const isTryingToAccessUnauthorizedPage: SagaReturnType<
      typeof selectors.member.isTryingToAccessUnauthorizedPage
    > = yield select(selectors.member.isTryingToAccessUnauthorizedPage)
    const token: SagaReturnType<typeof selectors.auth.token> = yield select(
      selectors.auth.token
    )

    const newPayload = {
      ...payload,
      ...(payload.input.oldPassword && {
        refreshToken: token?.refreshToken,
      }),
    }

    const rs: ApiResponse<typeof memberUpdate> = yield call(
      ApiSagas.mutate,
      memberUpdate,
      newPayload
    )

    if (rs.errors) {
      if (rs.errors.some((error) => error.extensions?.extra?.token)) {
        const token = rs.errors.find((error) => error.extensions?.extra?.token)
          ?.extensions?.extra?.token
        yield put(
          actions.auth.setToken({
            accessToken: token.access_token,
            refreshToken: token.refresh_token,
            expiresIn: token.expires_in,
          })
        )
      }
      yield put(actions.member.memberUpdateError(rs.errors))
    } else {
      if (rs.data?.token) {
        yield put(actions.auth.setToken(rs.data?.token))
      }
      yield put(actions.auth.setUser(rs.data?.member))
      yield put(actions.member.memberUpdateSuccess(rs.data))
      if (
        payload.input.address &&
        payload.input.city &&
        payload.input.zipCode &&
        isTryingToAccessUnauthorizedPage
      ) {
        yield call(Router.push, isTryingToAccessUnauthorizedPage)
        yield put(actions.member.setIsTryingToAccessUnauthorizedPage(null))
        yield put(
          actions.app.setSuccessToaster('toaster_member_access_coupon_success')
        )
      }
    }
  }

  static *onMemberDeleteRequest() {
    const rs: ApiResponse<typeof memberDelete> = yield call(
      ApiSagas.mutate,
      memberDelete
    )

    if (rs.errors) {
      yield put(actions.member.memberDeleteError(rs.errors))
    } else {
      yield put(actions.auth.resetAuth())
    }
  }

  static *onFavoriteToggle({
    payload,
  }: ReturnType<typeof actions.member.favoriteToggleRequest>) {
    const rs: ApiResponse<typeof favoriteToggle> = yield call(
      ApiSagas.mutate,
      favoriteToggle,
      payload
    )

    if (rs.errors) {
      yield put(actions.member.favoriteToggleError(rs.errors))
    } else {
      if (rs.data.find((item: any) => item.type === FavoriteType.Brand)) {
        yield put(actions.auth.setFavoriteBrands(rs.data))
        yield put(actions.member.favoriteToggleSuccess(rs.data))
      }
      if (rs.data.find((item: any) => item.type === FavoriteType.Recipe)) {
        yield put(actions.auth.setFavoriteRecipes(rs.data))
        yield put(actions.member.favoriteToggleSuccess(rs.data))
      }
      if (rs.data.find((item: any) => item.type === FavoriteType.Article)) {
        yield put(actions.auth.setFavoriteArticles(rs.data))
        yield put(actions.member.favoriteToggleSuccess(rs.data))
      }
      if (
        rs.data.find((item: any) => item.type === FavoriteType.CulinaryFolder)
      ) {
        yield put(actions.auth.setFavoriteFolders(rs.data))
        yield put(actions.member.favoriteToggleSuccess(rs.data))
      }
    }
  }

  static *onFavoritesRecipes() {
    const rs: ApiResponse<typeof favoritesRecipes> = yield call(
      ApiSagas.query,
      favoritesRecipes
    )

    if (rs.errors) {
      yield put(actions.member.favoritesRecipesError(rs.errors))
    } else {
      yield put(actions.member.favoritesRecipesSuccess(rs.data))
    }
  }

  static *onFavoritesArticles() {
    const rs: ApiResponse<typeof favoritesArticles> = yield call(
      ApiSagas.query,
      favoritesArticles
    )

    if (rs.errors) {
      yield put(actions.member.favoritesArticlesError(rs.errors))
    } else {
      yield put(actions.member.favoritesArticlesSuccess(rs.data))
    }
  }
  static *onFavoritesFolders() {
    const rs: ApiResponse<typeof favoritesFolders> = yield call(
      ApiSagas.query,
      favoritesFolders
    )

    if (rs.errors) {
      yield put(actions.member.favoritesFoldersError(rs.errors))
    } else {
      yield put(actions.member.favoritesFoldersSuccess(rs.data))
    }
  }
  static *onFavoritesBrands() {
    const rs: ApiResponse<typeof favoritesBrands> = yield call(
      ApiSagas.query,
      favoritesBrands
    )

    if (rs.errors) {
      yield put(actions.member.favoritesBrandsError(rs.errors))
    } else {
      yield put(actions.member.favoritesBrandsSuccess(rs.data))
    }
  }

  static *onAutoCompleteByZipCodeRequest({
    payload,
  }: ReturnType<typeof actions.member.dqeAutocompleteByZipCodeRequest>) {
    const rs: ApiResponse<typeof dqeAutocompleteByZipCode> = yield call(
      ApiSagas.query,
      dqeAutocompleteByZipCode,
      payload
    )

    if (rs.errors) {
      yield put(actions.member.dqeAutocompleteByZipCodeError(rs.errors))
    } else {
      yield put(actions.member.dqeAutocompleteByZipCodeSuccess(rs.data))
    }
  }
  static *onAutocompleteByCityIdAndAddressRequest({
    payload,
  }: ReturnType<
    typeof actions.member.dqeAutocompleteByCityIdAndAddressRequest
  >) {
    const rs: ApiResponse<typeof dqeAutocompleteByCityIdAndAddress> =
      yield call(ApiSagas.query, dqeAutocompleteByCityIdAndAddress, payload)

    if (rs.errors) {
      yield put(
        actions.member.dqeAutocompleteByCityIdAndAddressError(rs.errors)
      )
    } else {
      yield put(
        actions.member.dqeAutocompleteByCityIdAndAddressSuccess(rs.data)
      )
    }
  }
  static *onCheckMailRequest({
    payload,
  }: ReturnType<typeof actions.member.dqeCheckMailRequest>) {
    const rs: ApiResponse<typeof dqeCheckMail> = yield call(
      ApiSagas.query,
      dqeCheckMail,
      payload
    )

    if (rs.errors) {
      yield put(actions.member.dqeCheckMailError(rs.errors))
    } else {
      yield put(actions.member.dqeCheckMailSuccess(rs.data))
    }
  }

  static *loop() {
    yield all([
      takeLeading(
        actions.member.newsletterSubscribeRequest,
        this.onNewsletterSubscribeRequest
      ),
      takeLeading(
        actions.member.newsletterUnsubscribeRequest,
        this.onNewsletterUnsubscribeRequest
      ),
      takeLeading(
        actions.member.memberRegisterRequest,
        this.onMemberRegisterRequest
      ),
      takeLatest(
        actions.member.memberUpdateRequest,
        this.onMemberUpdateRequest
      ),
      takeLatest(
        actions.member.memberDeleteRequest,
        this.onMemberDeleteRequest
      ),
      takeLeading(actions.member.favoriteToggleRequest, this.onFavoriteToggle),
      takeLeading(
        actions.member.favoritesRecipesRequest,
        this.onFavoritesRecipes
      ),
      takeLeading(
        actions.member.favoritesArticlesRequest,
        this.onFavoritesArticles
      ),
      takeLeading(
        actions.member.favoritesFoldersRequest,
        this.onFavoritesFolders
      ),
      takeLeading(
        actions.member.favoritesBrandsRequest,
        this.onFavoritesBrands
      ),
      takeLatest(
        actions.member.dqeAutocompleteByZipCodeRequest,
        this.onAutoCompleteByZipCodeRequest
      ),
      takeLatest(
        actions.member.dqeAutocompleteByCityIdAndAddressRequest,
        this.onAutocompleteByCityIdAndAddressRequest
      ),
      takeLatest(actions.member.dqeCheckMailRequest, this.onCheckMailRequest),
    ])
  }
}
