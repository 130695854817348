import { CapriceValentineSession } from '../../../graphql/generated/api-graphql'

export enum SoloCapriceValentineStep {
  INTRO = 'intro',
  INPUT1 = 'input1',
  INPUT_LOAD = 'input_load',
  INPUT2 = 'input2',
  GPT = 'gpt',
  GPT_ERROR = 'gptError',
  RESULT = 'result',
  ERROR = 'error',
}

export const resolveCapriceValentineStep = (
  session?: CapriceValentineSession | null
) => {
  if (session?.sessionUid) {
    if (session?.response) {
      return SoloCapriceValentineStep.RESULT
    } else if (session?.input2) {
      return SoloCapriceValentineStep.GPT
    } else if (session?.input1) {
      return SoloCapriceValentineStep.INPUT_LOAD
    } else {
      return SoloCapriceValentineStep.INPUT1
    }
  }
  return SoloCapriceValentineStep.INTRO
}

export enum SoloCapriceValentineError {
  caprice_valentine_max_input_tries_reached = 'caprice_valentine_max_input_tries_reached',
  caprice_valentine_max_sessions_reached = 'caprice_valentine_max_sessions_reached',
  caprice_valentine_invalid_session = 'caprice_valentine_invalid_session',
  caprice_valentine_input_moderation = 'caprice_valentine_input_moderation',
  caprice_valentine_game_closed = 'caprice_valentine_game_closed',
}
