export { default as arrowRight } from './arrowRight.svg'
export { default as bell } from './bell.svg'
export { default as bread } from './bread.svg'
export { default as burger } from './burger.svg'
export { default as check } from './check.svg'
export { default as cheese } from './cheese.svg'
export { default as chevronBreadcrumb } from './chevronBreadcrumb.svg'
export { default as chevronDown } from './chevronDown.svg'
export { default as chevronLeft } from './chevronLeft.svg'
export { default as chevronRight } from './chevronRight.svg'
export { default as chevronUp } from './chevronUp.svg'
export { default as circleChevronDown } from './circleChevronDown.svg'
export { default as circleChevronUp } from './circleChevronUp.svg'
export { default as close } from './close.svg'
export { default as conseil } from './conseil.svg'
export { default as convive } from './convive.svg'
export { default as cooking } from './cooking.svg'
export { default as coupons } from './coupons.svg'
export { default as coupons2 } from './coupons2.svg'
export { default as download } from './download.svg'
export { default as euro } from './euro.svg'
export { default as eye } from './eye.svg'
export { default as eyeSlash } from './eyeSlash.svg'
export { default as filter } from './filter.svg'
export { default as fingerLike } from './fingerLike.svg'
export { default as folderCheese } from './folderCheese.svg'
export { default as google } from './google.svg'
export { default as heartFull } from './heartFull.svg'
export { default as heartLine } from './heartLine.svg'
export { default as help } from './help.svg'
export { default as home } from './home.svg'
export { default as info } from './info.svg'
export { default as knife } from './knife.svg'
export { default as level } from './level.svg'
export { default as like } from './like.svg'
export { default as location } from './location.svg'
export { default as mail } from './mail.svg'
export { default as milk } from './milk.svg'
export { default as minus } from './minus.svg'
export { default as nutriscore } from './nutriscore.svg'
export { default as pageNext } from './pageNext.svg'
export { default as pagePrev } from './pagePrev.svg'
export { default as panier } from './panier.svg'
export { default as pizza } from './pizza.svg'
export { default as play } from './play.svg'
export { default as plus } from './plus.svg'
export { default as positiveFood } from './positiveFood.svg'
export { default as positiveFoodBadge } from './positiveFoodBadge.svg'
export { default as positiveFoodLogo } from './positiveFoodLogo.svg'
export { default as priceTag } from './price-tag.svg'
export { default as print } from './print.svg'
export { default as puzzle } from './puzzle.svg'
export { default as quote } from './quote.svg'
export { default as refresh } from './refresh.svg'
export { default as scoreA } from './nutriscore-a.svg'
export { default as scoreB } from './nutriscore-b.svg'
export { default as scoreC } from './nutriscore-c.svg'
export { default as scoreD } from './nutriscore-d.svg'
export { default as scoreE } from './nutriscore-e.svg'
export { default as search } from './search.svg'
export { default as settings } from './settings.svg'
export { default as share } from './share.svg'
export { default as sherlock } from './sherlock.svg'
export { default as socialFacebook } from './social-facebook.svg'
export { default as socialFacebookColor } from './social-facebook-color.svg'
export { default as socialInstagram } from './social-instagram.svg'
export { default as socialInstagramColor } from './social-instagram-color.svg'
export { default as socialPinterest } from './social-pinterest.svg'
export { default as socialPinterestColor } from './social-pinterest-color.svg'
export { default as socialTwitter } from './social-twitter.svg'
export { default as socialWhatsapp } from './social-whatsapp.svg'
export { default as socialMessenger } from './social-messenger.svg'
export { default as socialXWhite } from './social-x-white.svg'
export { default as socialX } from './social-x.svg'
export { default as socialYoutube } from './social-youtube.svg'
export { default as soloHeroFullRound } from './soloHeroFullRound.svg'
export { default as soloHeroRound } from './soloHeroRound.svg'
export { default as soloHeroSquare } from './soloHeroSquare.svg'
export { default as sun } from './sun.svg'
export { default as tag } from './tag.svg'
export { default as tendance } from './tendance.svg'
export { default as time } from './time.svg'
export { default as timer } from './timer.svg'
export { default as toque } from './toque.svg'
export { default as triman } from './triman.svg'
export { default as user } from './user.svg'
export { default as video } from './video.svg'
export { default as wineGlass } from './wineGlass.svg'
