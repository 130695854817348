import gql from 'graphql-tag'
import { WediaImageFragment } from 'src/graphql/fragments/WediaImageFragment'

import { ServiceQuery } from '../../../../redux/api/types/state'

const favoritesBrands: ServiceQuery = {
  query: gql`
    query favoritesBrands {
      favoritesBrands {
        id
        name
        slug
        introductionTitle
        pictureLogo {
          ...WediaImageFragment
        }
      }
    }
    ${WediaImageFragment}
  `,
  transformer: (data: any) => {
    return data.favoritesBrands
  },
}

export default favoritesBrands
