import { all, call, put, takeLeading } from 'redux-saga/effects'

import { actions } from '..'
import AuthSagas from '../auth/sagas'
import ApiSagas from '../api/sagas'
import clientConfiguration from '../../graphql/Services/Configuration/queries/clientConfiguration'
import { ApiResponse } from '../api/types/state'

export default class AppSagas {
  static *initClientSide() {
    const configuration: ApiResponse<typeof clientConfiguration> = yield call(
      ApiSagas.persistQuery,
      clientConfiguration
    )
    if (configuration.data) {
      yield put(actions.app.setConfiguration(configuration.data))
    }
    yield call(AuthSagas.init)
    yield put(actions.app.setIsInit({ isInit: true }))
  }

  static *loop() {
    yield all([
      //
      takeLeading(actions.app.init, this.initClientSide),
    ])
  }
}
