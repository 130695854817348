import styled from 'styled-components'

import Popin from '../../atoms/Popin'
import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import ActionButton from '../../atoms/ActionButton'
import CouponCardsBlock from '../CouponCardsBlock'
import EcoPush from '../../atoms/EcoPush'
import NutritionBlock from '../NutritionBlock'
import Html from '../../atoms/Html'

export const ProductPopin = styled(Popin)`
  color: ${({ theme }) => theme.colors.arapawa};
`
export const Header = styled.header`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  @media ${mq(sizes.tablet)} {
    display: grid;
    grid-template-rows: 2rem 1fr 2rem;
    grid-template-columns: 2rem 20rem 3rem 39rem 2rem;
  }
`
export const Banner = styled(Picture)`
  position: relative;
  aspect-ratio: 5 / 2;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2rem 2rem 0 0;
  overflow: hidden;
  &,
  & img {
    display: block;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media ${mq(sizes.tablet)} {
    grid-column: 1 / span 5;
    grid-row: 1 / span 2;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: calc(100% / 3);
      background: linear-gradient(
        180deg,
        rgba(249, 249, 249, 0) 0%,
        #f9f9f9 100%
      );
    }
  }
`
export const Thumbnail = styled(Picture)`
  position: relative;
  align-self: center;
  max-width: 60vw;
  &,
  & img {
    display: block;
  }
  &:not(:first-child) {
    margin-top: -20vw;
  }
  @media ${mq(sizes.tablet)} {
    max-width: none;
    align-self: flex-end;
    grid-column: 2 / span 1;
    grid-row: 2 / span 2;
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
`
export const TitleZone = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  @media ${mq(sizes.tablet)} {
    align-self: flex-end;
    position: relative;
    grid-column: 4 / span 1;
    grid-row: 2 / span 2;
    border-radius: 2rem 2rem 0 0;
    padding: 2.5rem 1rem 3rem;
    background: ${({ theme }) => theme.colors.pureWhite};
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3}
  color: ${({ theme }) => theme.colors.arapawa};
  margin: 0;
  text-align: center;
  @media ${mq(sizes.tablet)} {
  }
`
export const Buy = styled(ActionButton)`
  ${({ theme }) => theme.textStyles.titleH2}
  @media ${mq(sizes.tablet)} {
  }
`
export const Introduction = styled(Html)`
  ${({ theme }) => theme.textStyles.textXLarge}
  &:not(:first-child) {
    margin-top: 3rem;
  }
  &,
  & > * {
    margin: 0;
    padding-bottom: 0;
  }
  & > :not(:first-child) {
    margin-top: 1.5rem;
  }
  & p,
  & li,
  & td,
  & th {
    &:not(:last-child) {
      padding-bottom: 0; // overriding default rules
    }
  }
`
export const Text = styled(Html)`
  &:not(:first-child) {
    margin-top: 3rem;
  }
  &,
  & > * {
    margin: 0;
    padding-bottom: 0;
  }
  & > :not(:first-child) {
    margin-top: 2rem;
  }
  & > h1:not(:first-child),
  & > h2:not(:first-child),
  & > h3:not(:first-child) {
    margin-top: 3rem;
  }
  & > h4 + p:not(:first-child),
  & > h5 + ol:not(:first-child),
  & > h6 + ul:not(:first-child) {
    margin-top: 0.5rem;
  }
  & h1,
  & h2 {
    ${({ theme }) => theme.textStyles.titleH3}
  }
  & h3 {
    ${({ theme }) => theme.textStyles.titleH4}
  }
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.colors.ruby};
  }
  & h4 {
    ${({ theme }) => theme.textStyles.titleH5}
  }
  & h5 {
    ${({ theme }) => theme.textStyles.titleH6}
  }
  & h6 {
    ${({ theme }) => theme.textStyles.titleH7}
  }
  & p,
  & li,
  & td,
  & th {
    ${({ theme }) => theme.textStyles.textMedium}
    &:not(:last-child) {
      padding-bottom: 0; // overriding default rules
    }
  }
  // just in case to emulate the intro if no intro and only if the Text starts with a paragraph
  ${Header} + & > p:first-child {
    ${({ theme }) => theme.textStyles.textXLarge}
  }
`
export const Coupons = styled(CouponCardsBlock)`
  padding: 0;
  background: none transparent;
  &:not(:first-child) {
    margin-top: 3rem;
  }
`
export const Nutrition = styled(NutritionBlock)`
  &:not(:first-child) {
    margin-top: 3rem;
  }
`
export const SeeMore = styled(ActionButton)`
  align-self: center;
  &:not(:first-child) {
    margin-top: 3rem;
  }
`
export const StyledEcoPush = styled(EcoPush)`
  &:not(:first-child) {
    margin-top: 3rem;
  }
`
