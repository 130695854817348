import styled from 'styled-components'

// import { mq, sizes } from '../../../theme'
import Icon from '../Icon'
import Link from '../Link'

export const Socials = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`
export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textSmall}
  color: ${({ theme }) => theme.colors.arapawa};
`
export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  gap: 2rem;
  margin: 0;
  padding: 0;
`
export const Item = styled.li`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`
export const StyledLink = styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.colors.bismark};
  transition: color 200ms ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.arapawa};
  }
`
export const Label = styled.span`
  ${({ theme }) => theme.textStyles.accessibleHiddenText}
`
export const StyledIcon = styled(Icon)`
  width: ${({ width }) => (width ? `${width / 10}rem` : '3.2rem')};
  height: auto;
`
