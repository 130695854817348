import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'
import { MemberFragment } from '../../../fragments/MemberFragment'

const memberByToken: ServiceQuery = {
  query: gql`
    query memberByToken {
      memberByToken {
        ...MemberFragment
      }
    }
    ${MemberFragment}
  `,
  transformer: (data: any) => {
    return data?.memberByToken
  },
}

export default memberByToken
