import { BrandColorType } from './types'

const brandColors: BrandColorType = {
  DarkToneColor: '#028ED0',
  MediumToneColor: '#80C7E7',
  LightToneColor: '#EAF3FA',
  UltraLightToneColor: '#F2F9FD',
  HighLightToneColor: '#BC2F27',
}

export default brandColors
