import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import IconsNav from '../../atoms/IconsNav'
import SkipNav from '../../atoms/SkipNav'
import Socials from '../../atoms/Socials'
import BurgerNav from '../BurgerNav'
import HeaderSearch from '../HeaderSearch'
import MainNav from '../MainNav'

export const Header = styled.header`
  position: relative;
  z-index: ${({ theme }) => theme.zIndexes.navigation};
  width: 100%;
  background: ${({ theme }) => theme.colors.linen};
  transition: position 0.3s ease;

  &.fixed {
    position: fixed;
    top: 0;
  }

  @media ${mq(sizes.tablet)} {
    &.fixed {
      position: relative;
      box-shadow: none;
    }
  }
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.titleH2}
  @media ${mq(sizes.tablet)} {
  }
`
export const StyledSkipNav = styled(SkipNav)`
  padding: 0.5rem 1.5rem;
`
export const Overhead = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.5rem;
  min-height: 6rem;
  & > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 2rem;
  }
  @media ${mq(sizes.desktop)} {
    padding: 1.5rem 3rem 2rem;
  }

  @media print {
    display: none;
  }
`
export const StyledBurgerNav = styled(BurgerNav)``
export const StyledSocials = styled(Socials)`
  display: none;
  @media ${mq(sizes.desktop)} {
    display: flex;
  }
`
export const StyledSearch = styled(HeaderSearch)``
export const StyledIconsNav = styled(IconsNav)``
export const StyledMainNav = styled(MainNav)``
