import styled from 'styled-components'

import IconButton from '../IconButton'

export const IconsNav = styled.nav``
export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  gap: 2rem;
  margin: 0;
  padding: 0;
`
export const Item = styled.li`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`
export const Button = styled(IconButton)``
