import Maybe from 'graphql/tsutils/Maybe'
import tracking from 'src/tracking'

import { NavMenu } from '../../components/molecules/SubNav'
import {
  ConfigurationHeaderItem2ColumnsColumn,
  ConfigurationHeaderItem2ColumnsColumnColumnLinks,
} from '../../graphql/generated/api-graphql'

export const headerColLinksTransformer = (
  left?: Maybe<ConfigurationHeaderItem2ColumnsColumn>,
  colLinks?: Array<ConfigurationHeaderItem2ColumnsColumnColumnLinks>,
  path?: string,
  navigate?: (href: string) => void
): NavMenu | undefined =>
  colLinks && colLinks.length > 0
    ? {
        bottomLink: left?.bottomText
          ? {
              ...(left?.bottomTextUrl && { href: left.bottomTextUrl }),
              label: left.bottomText,
            }
          : undefined,
        content: colLinks.map((column) => ({
          title: column?.title ?? undefined,
          bottomLink: column.bottomText
            ? {
                ...(column?.bottomTextUrl && {
                  href: column.bottomTextUrl,
                }),
                label: column?.bottomText,
              }
            : undefined,
          links:
            column.links && column.links.length > 0
              ? column.links.map((item: any) => ({
                  label: item?.label,
                  ...((!item?.obfuscate || !navigate) && { href: item?.href }),
                  onClick: () => {
                    if (item?.obfuscate && navigate) {
                      navigate?.(item?.href)
                    }
                    tracking.header.menu(item?.label, 2)
                  },
                  'aria-current': path === item.href ? 'true' : undefined,
                }))
              : undefined,
        })),
      }
    : undefined
