import { FC, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

import { actions } from '../../../redux'

const PreviousRoute: FC = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const ref = useRef<string | null>(null)

  router.events?.on('routeChangeStart', () => {
    ref.current = router.asPath
    dispatch(actions.app.setPreviousRoute(ref.current))
  })

  return null
}

export default PreviousRoute
