const API_URL = process.env.API_URL ?? process.env.NEXT_PUBLIC_API_URL
const GRAPH_API_URL = `${API_URL}/graphql`
const BACKEND_URL = String(process.env.NEXT_PUBLIC_BACKEND_URL)
const UPLOAD_ENDPOINT = `${API_URL}/api/image/upload`
const API_BASIC_AUTH =
  String(process.env.API_BASIC_AUTH) === '1' ||
  String(process.env.API_BASIC_AUTH) === 'false' ||
  false

export default {
  DEBUG: false,
  API_URL,
  GRAPH_API_URL,
  UPLOAD_ENDPOINT,
  BACKEND_URL,
  API_BASIC_AUTH,
  authCookieName: process.env.AUTH_COOKIE_NAME,
}
