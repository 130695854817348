import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'

const memberDelete: ServiceMutation = {
  mutation: gql`
    mutation memberDelete {
      memberDelete
    }
  `,
  transformer: (data: any) => {
    return data?.memberDelete
  },
}

export default memberDelete
