import styled from 'styled-components'

import { mq, sizes } from '../../theme'
import MainLayout from '../../layouts/MainLayout'
import Picture from '../../components/atoms/Picture'
import ActionButton from '../../components/atoms/ActionButton'

export const Layout = styled(MainLayout)`
  margin-top: 0;

  & main {
    margin-top: 0;
    max-width: 100%;
  }
`

export const Container = styled.div`
  padding: 0 2rem;

  @media ${mq(sizes.tablet)} {
    padding: 0;
  }
`

export const BackgroundImage = styled(Picture)`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

// export const LogoImage = styled(Picture)`
//   & img {
//     width: 12rem;
//     height: 12rem;

//     @media ${mq(sizes.mobile)} {
//       width: 16rem;
//       height: 16rem;
//     }
//   }
// `

export const Content = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 3rem;
  padding: 3rem 1.5rem 2rem 1.5rem;
  margin: 10rem auto 41rem auto;

  @media ${mq(sizes.tablet)} {
    padding: 3rem;
    margin: 16rem auto 60rem auto;
    max-width: 64rem;
  }
`

export const Title = styled.h1`
  ${({ theme }) => theme.textStyles.titleH2};
  color: ${({ theme }) => theme.colors.ruby};
  margin: 0 0 1rem 0;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH1};
  }
`

export const Subtitle = styled.p`
  ${({ theme }) => theme.textStyles.textLarge};
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
`

export const Button = styled(ActionButton)`
  margin-top: 2rem;

  @media ${mq(sizes.tablet)} {
    margin-top: 4rem;
  }
`
