import React, { FC } from 'react'
import cx from 'classnames'

import { LinkProps } from '../Link'
import { Icons } from '../Icon'

import * as SC from './styled'

export type SkipNavProps = {
  className?: string
  linksProps: LinkProps[]
}

const SkipNav: FC<SkipNavProps> = (props) => {
  const { className, linksProps } = props

  return linksProps.length > 0 ? (
    <SC.SkipNav className={cx('SkipNav', className)}>
      {linksProps.map((link, index) => (
        <SC.StyledLink key={`SkipNav-link-${index}`} {...link}>
          {link?.label}
          <SC.StyledIcon icon={Icons.chevronDown} />
        </SC.StyledLink>
      ))}
    </SC.SkipNav>
  ) : null
}

export default SkipNav
