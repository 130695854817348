import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  take,
} from 'redux-saga/effects'
import dayjs from 'dayjs'
import type { ApolloError } from '@apollo/client/errors'

import { actions, selectors } from '..'
import bugsnagClient from '../../helpers/BugsnagHelpers'
import * as configuration from '../../configuration'
import { transformErrors } from '../../helpers/GraphqlHelpers'
import memberRefreshToken from '../../graphql/Services/Member/queries/memberRefreshToken'
import { MemberReachfiveToken } from '../../graphql/generated/api-graphql'
import client, { defaultConfig, persistedClient } from '../../graphql/client'

import type {
  ApiResponse,
  GraphqlQueryVariables,
  QueryType,
  ServiceMutation,
  ServiceQuery,
} from './types/state'

const DEBUG = configuration.api.DEBUG
const log = DEBUG ? console.log : () => null

export type ApiTransformer = (data: any) => any

export default class ApiSagas {
  static *getHeaders(ignoreToken = false): any {
    const headers: Headers = yield select(selectors.api.headers)
    let token: string | undefined = yield select(selectors.auth.accessToken)

    if (!ignoreToken && typeof window !== 'undefined') {
      token = yield call(ApiSagas.checkTokenExpire)
    }

    log('ApiSagas - getHeaders token :', token)

    return {
      ...headers,
      ...(token && {
        authorization: `Bearer ${token}`,
      }),
    }
  }

  static *query(
    service: ServiceQuery,
    variables: GraphqlQueryVariables | null = null,
    ignoreToken = false
  ) {
    const response: ApiResponse = yield ApiSagas.call(
      client.query,
      service,
      variables,
      ignoreToken
    )
    return response
  }

  static *mutate(
    service: ServiceMutation,
    variables: GraphqlQueryVariables | null = null,
    ignoreToken = false
  ) {
    const response: ApiResponse = yield ApiSagas.call(
      client.mutate,
      service,
      variables,
      ignoreToken
    )
    return response
  }

  static *persistQuery(
    query: QueryType | any,
    variables: GraphqlQueryVariables | null = null,
    ignoreToken = false
  ) {
    const response: ApiResponse = yield ApiSagas.call(
      persistedClient.query,
      query?.query ? query : { query },
      variables,
      ignoreToken
    )
    return response
  }

  static *call(
    method:
      | typeof client.query
      | typeof client.mutate
      | typeof persistedClient.query,
    service: ServiceQuery | ServiceMutation,
    variables: GraphqlQueryVariables | null = null,
    ignoreToken = false
  ) {
    const headers: Headers = yield call(ApiSagas.getHeaders, ignoreToken)

    let result: ApiResponse
    try {
      result = yield call(method as any, {
        ...defaultConfig,
        ...service,
        ...(variables && { variables }),
        context: {
          ...service?.context,
          headers: {
            ...service?.context?.headers,
            ...headers,
          },
        },
      })
    } catch (e) {
      console.error(`ApiSagas - call errors :`, e, variables)

      if (bugsnagClient) {
        bugsnagClient.addMetadata('graphQL', {
          Variables: variables,
          Config: service,
        })
        bugsnagClient.notify(e as ApolloError)
      }

      return {
        errors: e,
      }
    }

    if (result.errors) {
      console.error(`ApiSagas - call result.errors `, result.errors)
    }

    if (
      result.errors?.find(
        (error) => error.extensions?.category === 'authentication'
      )
    ) {
      console.error(`ApiSagas - authentication -> logout`)
      yield put(actions.auth.resetAuth())
    }

    const resultTransformed: ApiTransformer = yield call(
      ApiSagas.transform,
      result,
      service?.transformer
    )

    return resultTransformed
  }

  static *transform(result: ApiResponse, transformer: ApiTransformer) {
    if (!result.data || !transformer) {
      return result
    }

    const data: ApiTransformer = yield call(transformer, result.data as any)

    return { ...result, data } as ApiResponse
  }

  static *checkTokenExpire() {
    const storeToken: ReturnType<typeof selectors.auth.token> = yield select(
      selectors.auth.token
    )
    const jwt: ReturnType<typeof selectors.auth.jwt> = yield select(
      selectors.auth.jwt
    )
    const expirationDate: number | undefined = jwt?.exp

    if (!expirationDate || !storeToken || !storeToken?.refreshToken) {
      return storeToken?.accessToken
    }

    if (
      dayjs(expirationDate * 1000)
        .subtract(5, 'minutes')
        .isBefore(dayjs())
    ) {
      log('Api: token is about to expire')
      const refreshingToken: SagaReturnType<typeof selectors.api.refreshing> =
        yield select(selectors.api.refreshing)

      if (refreshingToken) {
        log('Api: Token already refreshing')
        yield take(actions.api.setRefreshing)
        const newStoreToken: SagaReturnType<typeof selectors.auth.token> =
          yield select(selectors.auth.token)
        log('Api: Token refreshing complete', newStoreToken)
        return newStoreToken?.accessToken
      }
      yield put(actions.api.setRefreshing({ refreshing: true }))

      const result: ApiResponse<typeof memberRefreshToken> = yield call(
        ApiSagas.query,
        memberRefreshToken,
        {
          refreshToken: storeToken?.refreshToken,
        },
        true
      )

      if (result.errors) {
        log('Api: refresh token error', transformErrors(result.errors))
        yield put(actions.auth.resetAuth())
        return null
      }

      const token: MemberReachfiveToken = result?.data ?? null
      if (!token) {
        log('Api: refresh token error')
        yield put(actions.auth.resetAuth())
        return null
      }

      log('Api: refresh token success', result?.data)
      yield put(actions.auth.setToken(token))
      yield put(actions.api.setRefreshing({ refreshing: false }))

      return token?.accessToken
    }

    return storeToken?.accessToken
  }

  static *loop() {
    yield all([
      //
    ])
  }
}
