import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Link from '../Link'
import Icon from '../Icon'

export const Container = styled(Link)`
  display: flex;
  padding: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.pureWhite};
  border-radius: 1.8rem;
  color: ${({ theme }) => theme.colors.arapawa};
  background-color: ${({ theme }) => theme.colors.pureWhite};
  transition: all 200ms ease-in-out;
  &:hover {
    border-color: ${({ theme }) => theme.colors.arapawa};
    color: ${({ theme }) => theme.colors.pureWhite};
    background-color: ${({ theme }) => theme.colors.bismark};
  }
  &:active {
    border-color: ${({ theme }) => theme.colors.arapawa};
    color: ${({ theme }) => theme.colors.pureWhite};
    background-color: ${({ theme }) => theme.colors.arapawa};
  }
  @media ${mq(sizes.desktop)} {
    padding: 1.4rem;
    border-radius: 2.4rem;
  }
`
export const Label = styled.span`
  ${({ theme }) => theme.textStyles.accessibleHiddenText}
`
export const StyledIcon = styled(Icon)`
  width: 2.4rem;
  height: 2.4rem;
  @media ${mq(sizes.desktop)} {
    width: 3.2rem;
    height: 3.2rem;
  }
`
