import NextRoutes, { Router as RouterInterface } from 'next-routes'

import definitions, { RouteDefinition } from './definitions'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const nextRoutes: any = NextRoutes()
const NextRouter: RouterInterface = nextRoutes.Router
Object.values(definitions).map(
  ({ component, path, name, as }: RouteDefinition) => {
    if (as) {
      nextRoutes.add({
        page: component,
        name: name + '_as',
        pattern: as,
      })
    }
    nextRoutes.add({
      page: component,
      name,
      pattern: path,
    })
  }
)

const defaultParams = { lang: 'fr' }

export const routes = definitions

export type TransitionOptions = {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
  unstable_skipClientCache?: boolean
}

class Router {
  static match = (url: string) => {
    return nextRoutes.match(url)
  }

  static push = (
    url: string,
    as?: string,
    options?: TransitionOptions
  ): Promise<boolean> => {
    return NextRouter.push(url, as, options)
  }
  static replace = (
    url: string,
    as?: string,
    options?: TransitionOptions
  ): Promise<boolean> => {
    return NextRouter.replace(url, as, options)
  }
  static prefetch = (url: string): Promise<void> => {
    return NextRouter.prefetch(url)
  }

  static pushRoute = (
    route: string | RouteDefinition,
    params = {}
  ): Promise<boolean> => {
    const routeDefinition = Router.getRouteDefinition(route)
    if (routeDefinition)
      return NextRouter.pushRoute(routeDefinition.name, params)
    else return Promise.reject(false)
  }

  static replaceRoute = (
    route: string | RouteDefinition,
    params = {}
  ): Promise<boolean> => {
    const routeDefinition = Router.getRouteDefinition(route)
    if (routeDefinition)
      return NextRouter.replaceRoute(routeDefinition.name, params)
    else return Promise.reject(false)
  }

  static prefetchRoute = (
    route: string | RouteDefinition,
    params = {}
  ): Promise<any> => {
    const routeDefinition = Router.getRouteDefinition(route)
    if (routeDefinition)
      return NextRouter.prefetchRoute(routeDefinition.name, params)
    else return Promise.reject(false)
  }

  static getRouteDefinition = (
    route: string | RouteDefinition
  ): RouteDefinition =>
    typeof route === 'string' ? (routes as any)?.[route] : route

  static transformHrefToRouteConfig = (
    href?: string,
    target?: string
  ): { href?: string; target?: string; nextAs?: string } => {
    try {
      const match = href && Router.match(href)
      return match?.route
        ? {
            ...Router.getRouteConfig(match?.route, match?.query),
            ...(target && {
              target,
            }),
          }
        : {
            href,
            ...(target && {
              target,
            }),
          }
    } catch (e) {
      return {
        href,
        ...(target && {
          target,
        }),
      }
    }
  }

  static getRouteConfig = (
    route: string | RouteDefinition,
    params: { [key: string]: any } = {}
  ): { href?: string; nextAs?: string } => {
    const routeDefinition = Router.getRouteDefinition(route)

    if (!routeDefinition) {
      console.error('Router : route not found', route)
      return {}
    }

    const nextRoute =
      nextRoutes.findByName(routeDefinition.name + '_as') ??
      nextRoutes.findByName(routeDefinition.name)
    if (!nextRoute) {
      return {}
    }

    try {
      for (const z in params) {
        if (params[z]?.indexOf('/') !== -1) {
          params[z] = params[z].split('/')
        }
      }
      const urls = nextRoute.getUrls(params)
      return {
        href: urls?.href,
        nextAs: urls?.as,
      }
    } catch (e) {
      console.error('Router : error', e)
      return {}
    }
  }

  static getRouteUrl = (
    route: string | RouteDefinition,
    params = {}
  ): string => {
    const routeDefinition = Router.getRouteDefinition(route)

    if (!routeDefinition) {
      return ''
    }

    let routeUrl: string

    const p: any = {
      ...(routeDefinition?.path?.indexOf(':lang') !== -1 ? defaultParams : {}),
      ...params,
    }

    try {
      const nextRoute =
        nextRoutes.findByName(routeDefinition.name + '_as') ??
        nextRoutes.findByName(routeDefinition.name)

      routeUrl = nextRoute.getUrls(p)?.as
    } catch (e) {
      console.warn('findAndGetUrls Error', e)
      return ''
    }

    Object.keys(p).forEach((key) => {
      routeUrl = routeUrl.replace(new RegExp(`:${key}\\??`, 'gm'), p[key])
      routeUrl = decodeURIComponent(routeUrl)
      routeUrl = routeUrl.replace('//', '/')
    })
    return routeUrl
  }
}

export default Router
