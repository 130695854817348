import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'
import { FavoriteFragment } from '../../../fragments/FavoriteFragment'

const favorites: ServiceQuery = {
  query: gql`
    query favorites {
      articles: favoritesByType(type: ARTICLE) {
        ...FavoriteFragment
      }
      brands: favoritesByType(type: BRAND) {
        ...FavoriteFragment
      }
      folders: favoritesByType(type: CULINARY_FOLDER) {
        ...FavoriteFragment
      }
      recipes: favoritesByType(type: RECIPE) {
        ...FavoriteFragment
      }
    }
    ${FavoriteFragment}
  `,
  transformer: (data: any) => {
    return data
  },
}

export default favorites
