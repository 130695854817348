import { Member } from '../graphql/generated/api-graphql'

import {
  isAfter,
  isBefore,
  parseDateString,
  parseUnixTimestamp,
} from './DateHelpers'

export const isSocialConnectUser = (member: Member) =>
  member.lastLoginType === 'google' || member.lastLoginType === 'facebook'

export const doesItNeedPasswordReset = (
  member: Member,
  customFields: { [key: string]: any } | null,
  checkPasswordDate: Date
) => {
  const createdAt = parseDateString(member?.createdAt)
  return (
    !isSocialConnectUser(member) &&
    isAfter(
      checkPasswordDate,
      parseUnixTimestamp(customFields?.passwordUpdatedAt ?? 0)
    ) &&
    isBefore(createdAt, checkPasswordDate)
  )
}

export const isMemberValid = (member: Member) =>
  !!(
    member.email &&
    member.gender &&
    member.givenName &&
    member.birthdate &&
    member.familyName
  )
