import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import {
  Member,
  MemberConsent,
  MemberConsentType,
} from '../../graphql/generated/api-graphql'
import { actions, selectors } from '../../redux'
import { useServiceRequest } from '../../hooks/useServiceRequest'
import {
  NewsletterPushProps,
  NewsletterPushValues,
} from '../../components/molecules/NewsletterPush'
import { RealMemberConsents } from '../Common/transformReach5'

import { NewsletterPushDefaultProps, newsletterSubmit } from './transform'

type UseNewsletterPushProps = {
  trackingPage?: string
  submitCallback?: () => void
}

export function useNewsletterPush(
  props: UseNewsletterPushProps
): NewsletterPushProps | undefined {
  const { trackingPage, submitCallback } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectors.auth.user)

  const [newsletterSubscribe, newsletterSubscribeHandler] = useServiceRequest(
    selectors.member.newsletterSubscribe,
    actions.member.newsletterSubscribeRequest,
    actions.member.newsletterSubscribeReset
  )

  const [memberUpdate, memberUpdateHandler] = useServiceRequest(
    selectors.member.memberUpdate,
    actions.member.memberUpdateRequest
  )

  const memberConsents: RealMemberConsents | MemberConsent[] = Array.isArray(
    user?.consents
  )
    ? user?.consents
    : (user?.consents as RealMemberConsents)

  const newsletter =
    memberConsents instanceof Array
      ? memberConsents?.find(
          (consent: MemberConsent) =>
            consent?.type === MemberConsentType.Newsletter
        )?.granted ?? false
      : memberConsents?.newsletter?.granted ?? false

  const brands =
    memberConsents instanceof Array
      ? memberConsents?.find(
          (consent: MemberConsent) =>
            consent?.type === MemberConsentType.ConsentTypeBrands
        )?.granted ?? false
      : memberConsents?.consent_type_brands?.granted ?? false

  useEffect(() => {
    return () => {
      dispatch(actions.member.newsletterSubscribeReset())
      dispatch(actions.member.memberUpdateReset())
    }
  }, [dispatch])

  if (
    (newsletter || brands) &&
    !newsletterSubscribe?.success &&
    !newsletterSubscribe?.pending
  ) {
    return undefined
  }

  return {
    ...NewsletterPushDefaultProps(t, newsletterSubscribe, memberUpdate),
    onSubmit: (values: NewsletterPushValues) => {
      newsletterSubmit(
        values,
        user as Member,
        newsletterSubscribeHandler,
        memberUpdateHandler,
        trackingPage
      )
      if (submitCallback) {
        submitCallback()
      }
    },
  }
}
