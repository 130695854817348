import styled from 'styled-components'

import FormFieldInput from '../FormFieldInput'
import { Wrapper } from '../FormFieldInput/styled'
import FormSubmitButton from '../../FormSubmitButton'

export const Input = styled(FormFieldInput)`
  width: 100%;
  & ${Wrapper} {
    padding: 0.6rem 0.6rem 0.6rem 2rem;
    border-radius: 1.2rem;
  }
  & input {
    &::placeholder {
      opacity: 1;
      color: ${({ theme }) => theme.colors.bismark};
    }
  }
`
export const Submit = styled(FormSubmitButton)`
  height: 4.8rem;
  border-radius: 0.5rem;
`
