import { all, call, put, select } from 'redux-saga/effects'

import { actions, selectors } from '..'
import ApiSagas from '../api/sagas'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'
import i18nInstance, { locales as defaultLocales } from '../../i18n'
import {
  labels,
  locales,
  localesAndLabels,
} from '../../graphql/Services/Translations/queries'

import { transformLabels } from './transformers'

export default class I18nSagas {
  static *init(locale: string, data: any) {
    if (data?.locales) {
      yield put(
        actions.i18n.setLocales({
          locales: data?.locales,
        })
      )
    }

    if (data?.labels) {
      yield call(I18nSagas.initWithLocaleAndLabels, locale, data?.labels)
      return true
    }

    return false
  }

  static *initWithLocale(locale: string): any {
    const result = yield call(I18nSagas.loadLabels, locale)

    return yield call(I18nSagas.initWithLocaleAndLabels, locale, result?.data)
  }

  static *initWithLocaleAndLabels(locale: string, labels: any) {
    yield put(
      actions.i18n.setLabels({
        labels: {
          [locale]: {
            ...defaultLocales?.[locale as any],
            ...transformLabels(labels),
          },
        },
      })
    )
    yield put(actions.i18n.setLocale({ locale }))

    i18nInstance.addResourceBundle(
      locale,
      'translation',
      {
        ...defaultLocales?.[locale as any],
        ...transformLabels(labels),
      },
      true,
      true
    )
    i18nInstance.changeLanguage(locale)
    return true
  }

  static *resolvePreferedLanguage(ctx: any): any {
    const defaultLanguage = yield select(selectors.i18n.defaultLocale)
    if (process.browser) {
      const currentLocale = yield select(selectors.i18n.locale)
      return currentLocale || defaultLanguage
    } else {
      const headerAccept =
        ctx?.req?.headers?.['accept-language']?.match(/([a-z]{2})-[A-Z]{2}/gm)
      const acceptedLanguages = headerAccept?.concat(
        headerAccept?.map((iso: string) => iso?.substr(0, 2))
      )
      const locales = yield select(selectors.i18n.locales)
      const localesIso = locales?.map((l: { iso: string }) => l.iso)
      return (
        acceptedLanguages?.find((lang: string) => localesIso?.includes(lang)) ||
        defaultLanguage
      )
    }
  }

  static *redirectToPreferedLanguage(ctx: any): any {
    const locales = yield select(selectors.i18n.locales)
    if (!locales?.length) {
      yield call(I18nSagas.loadLanguages)
    }
    const lang = yield call(I18nSagas.resolvePreferedLanguage, ctx)

    if (process.browser) {
      Router.pushRoute(routes.page.name, { lang })
    } else {
      const url = Router.getRouteUrl(routes.page.name, { lang })
      if (typeof ctx?.res?.redirect === 'function')
        return ctx?.res?.redirect(302, url)
    }
  }

  static *loadLanguages(locale: string | null = null): any {
    let result
    if (locale) {
      result = yield call(ApiSagas.query, localesAndLabels, {
        localeFilters: { locale: locale },
      })
    } else {
      result = yield call(ApiSagas.query, locales)
    }

    if (result.data) {
      yield put(
        actions.i18n.setLocales({
          locales: result?.data?.locales,
        })
      )
    }

    return result
  }

  static *loadLabels(locale: string): any {
    const result = yield call(ApiSagas.query, labels, {
      localeFilters: { locale },
    })

    if (result.errors) {
      console.error('Translations not loaded successfully', result.errors)
    }

    return result
  }

  static *loop() {
    yield all([
      //
    ])
  }
}
