import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'

const dqeCheckMail: ServiceQuery = {
  name: 'dqeCheckMail',
  query: gql`
    query dqeCheckEmail($email: String!) {
      dqeCheckEmail(email: $email) {
        id
        in
        error
      }
    }
  `,
  transformer: (data: any) => {
    return data.dqeCheckEmail
  },
}

export default dqeCheckMail
