import React, { FC } from 'react'
import cx from 'classnames'

import { LinkProps } from '../Link'
import { IconProps } from '../Icon'

import * as SC from './styled'

export type IconButtonProps = {
  className?: string
  link?: LinkProps
  icon: IconProps
}

const IconButton: FC<IconButtonProps> = (props) => {
  const { className, link, icon } = props

  return (
    <SC.Container className={cx('IconButton', className)} {...link}>
      {link?.label && <SC.Label>{link?.label}</SC.Label>}
      {icon && <SC.StyledIcon {...icon} aria-hidden />}
    </SC.Container>
  )
}

export default IconButton
