import gql from 'graphql-tag'
import { ArticleCardFragment } from 'src/graphql/fragments/ArticleCardFragment'

import { ServiceQuery } from '../../../../redux/api/types/state'

const favoritesArticles: ServiceQuery = {
  query: gql`
    query favoritesArticles {
      favoritesArticles {
        ...ArticleCardFragment
      }
    }
    ${ArticleCardFragment}
  `,
  transformer: (data: any) => {
    return data.favoritesArticles
  },
}

export default favoritesArticles
