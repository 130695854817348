import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'
import { CapriceValentineSession } from '../../../generated/api-graphql'
import { CapriceValentineSessionFragment } from '../../../fragments/CapriceValentineSessionFragment'

const soloPageCapriceValentineFetchSessionResponse: ServiceMutation = {
  name: 'soloPageCapriceValentineFetchSessionResponse',
  mutation: gql`
    mutation soloPageCapriceValentineFetchSessionResponse($sessionUid: ID!) {
      soloPageCapriceValentineFetchSessionResponse(sessionUid: $sessionUid) {
        ...CapriceValentineSessionFragment
      }
    }
    ${CapriceValentineSessionFragment}
  `,
  transformer: (data: any): CapriceValentineSession => {
    return data?.soloPageCapriceValentineFetchSessionResponse
  },
}

export default soloPageCapriceValentineFetchSessionResponse
