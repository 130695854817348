import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const CulinaryFolderCardFragment = gql`
  fragment CulinaryFolderCardFragment on CulinaryFolder {
    __typename
    id
    slug
    title
    mainImage {
      ...WediaImageFragment
    }
    thumbnail01 {
      ...WediaImageFragment
    }
    positiveFood
    # "tags" used to display details
    # actual tags
    tagsConsumptionTime {
      name
    }
    tagsTheme {
      name
    }
    articles {
      id
      title
      slug
    }
  }
  ${WediaImageFragment}
`
