import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'

const dqeAutocompleteByZipCode: ServiceQuery = {
  name: 'dqeAutocompleteByZipCode',
  query: gql`
    query dqeAutocompleteByZipCode($zipCode: String!) {
      dqeAutocompleteByZipCode(zipCode: $zipCode) {
        city
        cityId
      }
    }
  `,
  transformer: (data: any) => {
    return data.dqeAutocompleteByZipCode
  },
}

export default dqeAutocompleteByZipCode
