import { BrandColorType } from './types'

const brandColors: BrandColorType = {
  DarkToneColor: '#263583',
  MediumToneColor: '#F4CE6E',
  LightToneColor: '#ECEEFF',
  UltraLightToneColor: '#F6F7FF',
  HighLightToneColor: '#E40713',
}

export default brandColors
