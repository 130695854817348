import styled, { css } from 'styled-components'

import Icon from '../Icon'
import { mq, sizes } from '../../../theme'

export const Container = styled.button<{ $visible?: boolean }>`
  position: fixed;
  right: 1rem;
  bottom: 1.4rem;
  z-index: ${({ theme }) => theme.zIndexes.navigation};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 1.8rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  opacity: 0;
  visibility: hidden;
  transition: visibility 0ms ease-in-out 300ms, opacity 200ms ease-in-out,
    box-shadow 300ms ease-in-out;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  ${({ $visible }) =>
    $visible === true &&
    css`
      opacity: 1;
      visibility: visible;
      box-shadow: 0 1rem 3rem 0 rgba(0, 0, 0, 0.1);
      transition: visibility 0ms ease-in-out 0ms, opacity 200ms ease-in-out,
        box-shadow 300ms ease-in-out;
    `}
  @media ${mq(sizes.desktop)} {
    width: 6rem;
    height: 6rem;
    border-radius: 2.4rem;
  }
  @media print {
    display: none;
  }
`
export const Text = styled.span`
  ${({ theme }) => theme.textStyles.accessibleHiddenText};
`
export const StyledIcon = styled(Icon)`
  flex: 0 0 auto;
  width: 2.8rem;
  color: ${({ theme }) => theme.colors.arapawa};
`
