import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const BrandWrapFragment = gql`
  fragment BrandWrapFragment on Brand {
    pageWrapEnabled
    pageWrapBgColor
    pageWrapUrl
    pageWrapTitle
    pageWrapBody
    pageWrapImageTop {
      ...WediaImageFragment
    }
    pageWrapImageTopMobile {
      ...WediaImageFragment
    }
    pageWrapImageTopTablet {
      ...WediaImageFragment
    }
    pageWrapImageLeft {
      ...WediaImageFragment
    }
    pageWrapImageRight {
      ...WediaImageFragment
    }
  }
  ${WediaImageFragment}
`
