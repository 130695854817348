export const transformObjectKeysToCamelCase = (obj: {
  [key: string]: any
}): { [key: string]: any } => {
  const newObj: { [key: string]: any } = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = key.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '')
      })
      newObj[newKey] = obj[key]
    }
  }
  return newObj
}

export const dotToComma = (input: string | number | null): string =>
  input?.toString()?.replace('.', ',') ?? ''
