import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import Link from '../../atoms/Link'

export const Ad = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndexes.background};
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.scopingwithAd / 10}rem;
  margin-left: auto;
  margin-right: auto;
  @media ${mq(sizes.withAd)} {
    height: 24rem;
  }
`
export const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
`
const StyledPicture = styled(Picture)`
  position: relative;
  z-index: 1;
  &,
  & img {
    display: block;
  }
`
const Side = styled(StyledPicture)`
  display: none;
  @media ${mq(sizes.withAd)} {
    aspect-ratio: 240 / 640;
    width: 24rem;
    position: absolute;
    top: 24rem;
    display: block;
    & img {
      width: 100%;
      height: auto;
    }
  }
`
export const Top = styled(StyledPicture)`
  display: none;
  width: 100%;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const TopMobile = styled(Top)`
  display: block;
  aspect-ratio: 750/320;
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`
export const TopTablet = styled(Top)`
  aspect-ratio: 1000/240;
  @media ${mq(sizes.tablet)} {
    display: block;
  }
  @media ${mq(sizes.withAd)} {
    display: none;
  }
`
export const TopDesktop = styled(Top)`
  @media ${mq(sizes.withAd)} {
    aspect-ratio: 1480/240;
    position: absolute;
    height: 100%;
    left: 50%;
    display: block;
    transform: translateX(-50%);
  }
`

export const Left = styled(Side)`
  right: 50%;
  transform: translateX(
    calc(0px - ${({ theme }) => theme.sizes.withAd / 2 / 10}rem - 1rem)
  );
`
export const Right = styled(Side)`
  left: 50%;
  transform: translateX(
    calc(0px + ${({ theme }) => theme.sizes.withAd / 2 / 10}rem + 1rem)
  );
`
