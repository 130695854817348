import dayjs from 'dayjs'

import 'dayjs/locale/fr'

const dayjsMap: any = {
  fr: 'fr',
}

export const setDayjsLocale = (lng: string) => {
  dayjs.locale(dayjsMap?.[lng] || dayjsMap.en)
}
