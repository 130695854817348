import styled from 'styled-components'

import { mq, sizes } from '../../../../theme'
import FormikForm from '../../../form/FormikForm'
import FormMessages from '../../../form/FormMessages'
import FormSubmitButton from '../../../form/FormSubmitButton'
import FormikField from '../../../form/FormikField'
import { HelpMessage } from '../../../form/fields/FormFieldInput/styled'
import Html from '../../../atoms/Html'

export const Content = styled.div<{ $background?: string }>`
  background: radial-gradient(
      32.39% 100% at 50% 0%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    ${({ $background }) => $background || '#008ed2'};
  border-radius: 0 0 2rem 2rem;
  padding: 2rem 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media ${mq(sizes.desktop)} {
    border-radius: 0 0 3rem 3rem;
    padding: 2rem 3rem 4rem 3rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3}
  color: ${({ theme }) => theme.colors.salomie};
  text-align: center;
  padding: 0;
  margin: 0;
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.titleH1}
  }
`

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const AuthFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media ${mq(sizes.desktop)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

export const Field = styled(FormikField)`
  & ${HelpMessage} {
    color: ${({ theme }) => theme.colors.pureWhite};
  }
`

export const RulesField = styled(FormikField)`
  & label {
    align-items: center;
  }
  & label p {
    width: 100%;
    ${({ theme }) => theme.textStyles.textLarge}
    color: #FFF;
    text-align: left;
    & a {
      color: #fff;
    }
  }
  & svg {
    border-color: #546a77;
    background-color: #fff;
  }
  & [aria-invalid='true'] ~ svg {
    border-color: ${({ theme }) => theme.colors.error};
  }
`

export const Errors = styled(FormMessages)`
  margin: 1rem 0 !important;
`

export const SubmitButton = styled(FormSubmitButton)`
  align-self: center;
`

export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.textSmall};
  color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;

  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.textLarge};
  }
`

export const LegalNotice = styled(Html)`
  ${({ theme }) => theme.textStyles.textSmall};
  color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;

  & a {
    color: ${({ theme }) => theme.colors.pureWhite};
    text-decoration: underline;
  }
`
