import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'

const favoriteToggle: ServiceMutation = {
  mutation: gql`
    mutation favoriteToggle($type: FavoriteType!, $id: ID!) {
      favoriteToggle(type: $type, id: $id) {
        reach5Uid
        type
        id
        createdAt
      }
    }
  `,
  transformer: (data: any) => {
    return data?.favoriteToggle
  },
}

export default favoriteToggle
