import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'
import { CapriceValentineSession } from '../../../generated/api-graphql'
import { CapriceValentineSessionFragment } from '../../../fragments/CapriceValentineSessionFragment'

const soloPageCapriceValentineCreateSession: ServiceMutation = {
  name: 'soloPageCapriceValentineCreateSession',
  mutation: gql`
    mutation soloPageCapriceValentineCreateSession {
      soloPageCapriceValentineCreateSession {
        ...CapriceValentineSessionFragment
      }
    }
    ${CapriceValentineSessionFragment}
  `,
  transformer: (data: any): CapriceValentineSession => {
    return data?.soloPageCapriceValentineCreateSession
  },
}

export default soloPageCapriceValentineCreateSession
