import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'

const memberRefreshToken: ServiceQuery = {
  query: gql`
    query memberRefreshToken($refreshToken: String!) {
      memberRefreshToken(refreshToken: $refreshToken) {
        accessToken
        expiresIn
        refreshToken
      }
    }
  `,
  transformer: (data: any) => {
    return data?.memberRefreshToken
  },
}

export default memberRefreshToken
