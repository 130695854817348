import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../../theme'
import Picture from '../../../atoms/Picture'
import Icon from '../../../atoms/Icon'

export const Container = styled.div<{ $hideCloseButton: boolean }>`
  position: fixed;
  overflow: auto;
  margin: auto;
  inset: 0;
  z-index: ${({ theme }) => theme.zIndexes.highest};
  display: flex;
  padding: 8rem 2rem 2rem;
  &[aria-hidden='true'] {
    display: none;
  }
  ${({ $hideCloseButton }) =>
    $hideCloseButton &&
    css`
      ${Close} {
        display: none;
      }
      ${StyledArc} {
        top: -150%;
        height: calc(18rem / 2);
        width: 18rem;
        border-radius: calc(18rem / 2) calc(18rem / 2) 0 0;
      }
      ${LogoContainer} {
        height: 3.4rem;
        @media ${mq(sizes.desktop)} {
          height: 3.4rem;
          border-radius: 2rem 2rem 0 0;
        }
      }
      ${LogoImage} {
        @media ${mq(sizes.desktop)} {
          top: -5.2rem;
          & img {
            width: 10rem;
          }
        }
      }
    `}
`

export const Popin = styled.div`
  position: relative;
  margin: auto;
  z-index: 2;
  flex: 0 0 auto;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  border-radius: 2rem;
  box-shadow: 0 3rem 3rem rgba(0, 0, 0, 0.35);
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms ease-in, visibility 0s 0s;
  ${Container}[aria-hidden='true'] & {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in, visibility 0ms 200ms;
  }
  @media ${mq(sizes.desktop)} {
    max-width: 88rem;
    border-radius: 3rem;
  }
`

export const LogoContainer = styled.div`
  position: relative;
  height: 5.4rem;
  border-radius: 2rem 2rem 0 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.pureWhite};

  @media ${mq(sizes.desktop)} {
    height: 6.4rem;
    border-radius: 3rem 3rem 0 0;
  }
`

export const StyledArc = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -100%;
  height: calc(21rem / 2);
  width: 21rem;
  border-radius: calc(21rem / 2) calc(21rem / 2) 0 0;
  background-color: ${({ theme }) => theme.colors.pureWhite};
`

export const LogoImage = styled(Picture)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;

  top: -5.2rem;
  & img {
    width: 10rem;
  }
  @media ${mq(sizes.desktop)} {
    top: -5rem;
    & img {
      width: 11rem;
    }
  }
`

export const Close = styled.button`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  background: ${({ theme }) => theme.colors.pureWhite};
  padding: 0.8rem;
  border-radius: 1.8rem;
  color: ${({ theme }) => theme.colors.arapawa};
  align-items: center;
  justify-content: center;

  & span {
    ${({ theme }) => theme.textStyles.accessibleHiddenText}
  }
  @media ${mq(sizes.desktop)} {
    display: flex;
  }
`

export const CloseIcon = styled(Icon)`
  width: 3.2rem;
  height: 3.2rem;
`

export const Overlay = styled.button<{ $isOpen?: boolean }>`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.pureBlack};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ $isOpen }) => ($isOpen ? 0.2 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  transition: opacity 200ms ease-in, visibility 0ms 200ms;
  &:not(:disabled) {
    cursor: default;
  }
  inset: 0;
  z-index: 1;
  border: 0;
  cursor: default;
  pointer-events: none;
`
