import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'

import { actionTypes } from './types'
import { Header } from './types/state'

const name = 'api'

export type ApiState = {
  headers: Header | Record<string, never>
  refreshing: boolean
}

//
// Initial state
//

const initialState: ApiState = {
  headers: {},
  refreshing: false,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setHeaders: (state, action: actionTypes.setHeaders) => {
      const { headers } = action.payload
      state.headers = headers
    },
    setRefreshing: (state, action: actionTypes.setRefreshing) => {
      const { refreshing } = action.payload
      state.refreshing = refreshing
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state.api
const headers = (state: RootState) => root(state).headers
const refreshing = (state: RootState) => root(state).refreshing

export const selectors = {
  headers,
  refreshing,
}
