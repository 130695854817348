import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'

const newsletterSubscribe: ServiceMutation = {
  mutation: gql`
    mutation newsletterSubscribe(
      $email: String!
      $device: Device!
      $campaignId: ID
    ) {
      newsletterSubscribe(
        email: $email
        device: $device
        campaignId: $campaignId
      ) {
        id
      }
    }
  `,
  transformer: (data: any) => {
    return data?.newsletterSubscribe
  },
}

export default newsletterSubscribe
