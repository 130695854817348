import { BrandColorType } from './types'

const brandColors: BrandColorType = {
  DarkToneColor: '#533720',
  MediumToneColor: '#2E7B13',
  LightToneColor: '#D5C6BE',
  UltraLightToneColor: '#F5EFED',
  HighLightToneColor: '#EA1724',
}

export default brandColors
