import styled, { css } from 'styled-components'

import FormikForm from '../../form/FormikForm'
import { mq, sizes } from '../../../theme'
import FormMessages from '../../form/FormMessages'
import ActionButton from '../../atoms/ActionButton'

import { Variant } from './types'

export const CouponCardsBlock = styled.aside`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.snow};
  padding: 2rem 1rem;
  gap: 2rem;
  @media ${mq(sizes.tablet)} {
    padding: 2rem;
  }

  @media print {
    display: none;
  }
`

export const Title = styled.h2<{ $variant?: Variant }>`
  ${({ $variant, theme }) =>
    $variant !== Variant.Default
      ? theme.textStyles.titleH4
      : theme.textStyles.titleH2}
  margin: 0;
  color: ${({ theme }) => theme.scheme.couponsTitle ?? theme.colors.arapawa};
  text-align: left;
  width: 100%;
  flex-grow: 1;
  @media ${mq(sizes.tablet)} {
    ${({ $variant, theme }) =>
      $variant !== Variant.Default
        ? theme.textStyles.titleH3
        : theme.textStyles.titleH1}
  }
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textLarge};
  color: ${({ theme }) => theme.colors.arapawa};
`

export const Form = styled(FormikForm)``
export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  @media ${mq(sizes.mobile)} {
    flex-direction: row;
  }

  & div {
    width: inherit;
  }
`
export const Errors = styled(FormMessages)``

export const Cards = styled.div<{ $variant?: Variant }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
  @media ${mq(sizes.tablet)} {
    display: grid;
    gap: 2rem 2.4rem;
    align-items: stretch;
    ${({ $variant }) =>
      $variant === Variant.BigColumn
        ? css`
            grid-template-columns: repeat(
              auto-fill,
              minmax(min(100%, 30rem), 35.5rem)
            );
          `
        : css`
            grid-template-columns: repeat(
              auto-fill,
              minmax(min(100%, 30rem), 1fr)
            );
            & > * {
              max-width: none;
            }
          `}
  }
`

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NoResults = styled.p`
  ${({ theme }) => theme.textStyles.textMedium}
  color: ${({ theme }) => theme.colors.ruby};
`

export const Actions = styled.div`
  display: flex;
  gap: 1.5rem;
`

export const Action = styled(ActionButton)``
