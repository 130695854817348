import { SoloPageTheme } from '../graphql/generated/api-graphql'

import colors from './colors'
import fonts from './fonts'
import textStyles from './textStyles'
import sizes from './sizes'
import zIndexes from './zindexes'
// color scheme for brand pages
import defaultScheme from './brandScheme/default'
import capriceScheme from './brandScheme/caprice'
import bresseScheme from './brandScheme/bresse'
import giovanniScheme from './brandScheme/giovanni'
import rustiqueScheme from './brandScheme/rustique'
import stAgurScheme from './brandScheme/stAgur'
import stAlbrayScheme from './brandScheme/stAlbray'
import tartareScheme from './brandScheme/tartare'
import lionScheme from './brandScheme/lion'
import { BrandSchemeType } from './brandScheme/types'

export type Theme = {
  colors: typeof colors
  fonts: typeof fonts
  textStyles: typeof textStyles
  sizes: typeof sizes
  zIndexes: typeof zIndexes
  scheme: BrandSchemeType
}

const theme: Theme = {
  colors: {
    ...colors,
  },
  fonts: {
    ...fonts,
  },
  textStyles: {
    ...textStyles,
  },
  sizes: {
    ...sizes,
  },
  zIndexes: {
    ...zIndexes,
  },
  scheme: {
    ...defaultScheme,
  },
}

const themeCaprice: Theme = {
  ...theme,
  scheme: {
    ...capriceScheme,
  },
}

const themeBresse: Theme = {
  ...theme,
  scheme: {
    ...bresseScheme,
  },
}

const themeGiovanni: Theme = {
  ...theme,
  scheme: {
    ...giovanniScheme,
  },
}

const themeRustique: Theme = {
  ...theme,
  scheme: {
    ...rustiqueScheme,
  },
}

const themeStAgur: Theme = {
  ...theme,
  scheme: {
    ...stAgurScheme,
  },
}

const themeStAlbray: Theme = {
  ...theme,
  scheme: {
    ...stAlbrayScheme,
  },
}

const themeTartare: Theme = {
  ...theme,
  scheme: {
    ...tartareScheme,
  },
}

const themeLion: Theme = {
  ...theme,
  scheme: {
    ...lionScheme,
  },
}

export enum themesKey {
  theme = 'theme',
  themeCaprice = 'themeCaprice',
  themeBresse = 'themeBresse',
  themeGiovanni = 'themeGiovanni',
  themeRustique = 'themeRustique',
  themeStAgur = 'themeStAgur',
  themeStAlbray = 'themeStAlbray',
  themeTartare = 'themeTartare',
  themeLion = 'themeLion',
}

export const keyFromApi = (apiKey: string): themesKey => {
  switch (apiKey) {
    case SoloPageTheme.Bresse:
      return themesKey.themeBresse
    case SoloPageTheme.Caprice:
      return themesKey.themeCaprice
    case SoloPageTheme.Giovanni:
      return themesKey.themeGiovanni
    case SoloPageTheme.Rustique:
      return themesKey.themeRustique
    case SoloPageTheme.StAgur:
      return themesKey.themeStAgur
    case SoloPageTheme.StAlbray:
      return themesKey.themeStAlbray
    case SoloPageTheme.Tartare:
      return themesKey.themeTartare
    case SoloPageTheme.CoeurDeLion:
      return themesKey.themeLion
    default:
      return themesKey.theme
  }
}

export const themes: { [key in themesKey]: Theme } = {
  [themesKey.theme]: theme,
  [themesKey.themeCaprice]: themeCaprice,
  [themesKey.themeBresse]: themeBresse,
  [themesKey.themeGiovanni]: themeGiovanni,
  [themesKey.themeRustique]: themeRustique,
  [themesKey.themeStAgur]: themeStAgur,
  [themesKey.themeStAlbray]: themeStAlbray,
  [themesKey.themeTartare]: themeTartare,
  [themesKey.themeLion]: themeLion,
}
