import React, { ChangeEvent, FC, useId, useState } from 'react'
//import Autosuggest, { RenderInputComponentProps } from 'react-autosuggest'
import type { RenderInputComponentProps } from 'react-autosuggest'
import dynamic from 'next/dynamic'

const Autosuggest = dynamic(() => import('react-autosuggest'), {
  loading: () => <p>Loading...</p>,
})

import { FormFieldInputProps } from '../FormFieldInput'

import * as SC from './styled'

export type FormFieldAutoSuggestValue = {
  label: string
  value?: string
}

export type FormFieldAutoSuggestProps = Omit<
  FormFieldInputProps,
  'onSubmit'
> & {
  suggestions?: (FormFieldAutoSuggestValue | string)[]
  loadSuggestions?: (value: string) => void
  onSubmit?: (value: FormFieldAutoSuggestValue | string) => void
  renderSuggestionsOnFocus?: boolean
}

const FormFieldAutoSuggest: FC<FormFieldAutoSuggestProps> = (props) => {
  const {
    className,
    suggestions,
    onChange,
    onSubmit,
    name,
    value,
    loadSuggestions,
    renderSuggestionsOnFocus,
    disabled,
    ...extraProps
  } = props
  const id = useId()

  const [focused, setFocused] = useState(false)

  return (
    <SC.AutoSuggest className={className}>
      <Autosuggest
        id={id}
        inputProps={{
          value: (value as string) ?? '',
          onChange: onChange as any,
          onFocus: () => setFocused(true),
          onBlur: () => setFocused(false),
        }}
        multiSection={false}
        suggestions={suggestions ?? []}
        renderInputComponent={(p: RenderInputComponentProps) => (
          <SC.Input
            name={name}
            {...extraProps}
            {...p}
            theme={undefined}
            $idDisabled={disabled ?? false}
            disabled={disabled ?? false}
          />
        )}
        renderSuggestion={(entry: any) => {
          return <div>{typeof entry === 'string' ? entry : entry?.label}</div>
        }}
        alwaysRenderSuggestions={focused && renderSuggestionsOnFocus}
        onSuggestionSelected={(
          _event,
          {
            suggestion,
            suggestionValue,
          }: { suggestion: any; suggestionValue: string }
        ) => {
          onChange?.({
            target: {
              name,
              value: suggestionValue,
            },
          } as ChangeEvent<any>)
          onSubmit?.(suggestion)
        }}
        onSuggestionsFetchRequested={({ value: inputValue }) =>
          loadSuggestions?.(inputValue)
        }
        onSuggestionsClearRequested={() => loadSuggestions?.('')}
        getSuggestionValue={(entry: any): string => {
          return typeof entry === 'string' ? entry : entry?.label
        }}
        focusInputOnSuggestionClick={false}
      />
    </SC.AutoSuggest>
  )
}

export default FormFieldAutoSuggest
