import styled from 'styled-components'

import { mq, sizes } from '../../../../theme'
import FormikForm from '../../../form/FormikForm'
import FormMessages from '../../../form/FormMessages'
import Link from '../../../atoms/Link'
import FormSubmitButton from '../../../form/FormSubmitButton'
import ActionButton from '../../../atoms/ActionButton'

export const Content = styled.div<{ $background?: string }>`
  background: radial-gradient(
      32.39% 100% at 50% 0%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    ${({ $background }) => $background || '#008ed2'};
  border-radius: 0 0 2rem 2rem;
  padding: 2rem 1.5rem 3rem;
  @media ${mq(sizes.desktop)} {
    border-radius: 0 0 3rem 3rem;
    padding: 2rem 3rem 4rem 3rem;
  }
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  @media ${mq(sizes.desktop)} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3}
  color: ${({ theme }) => theme.colors.salomie};
  text-align: center;
  padding: 0;
  margin: 0;
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.titleH1}
  }
`

export const Subtitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH4}
  color: ${({ theme }) => theme.colors.salomie};
  text-align: center;
`

export const Login = styled.div`
  width: 100%;
`

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`

export const AuthFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const Errors = styled(FormMessages)`
  margin: 1rem 0 !important;
`

export const LinkResetPassword = styled(Link)`
  ${({ theme }) => theme.textStyles.textXSmall};
  color: ${({ theme }) => theme.colors.pureWhite};
  text-decoration: underline;
  width: max-content;
  margin: 0.7rem 0 1rem 0;
`

export const SubmitButton = styled(FormSubmitButton)`
  align-self: center;
`

export const Other = styled.div`
  flex-shrink: 0;
`

export const Social = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textMediumStrong};
  color: ${({ theme }) => theme.colors.bismark};
  text-align: center;
  margin-top: 2rem;

  @media ${mq(sizes.desktop)} {
    display: none;
  }
`

export const FacebookBtn = styled(ActionButton)`
  margin: 2rem 0 1.5rem 0;
  background-color: ${({ theme }) => theme.colors.facebook};
  border-color: ${({ theme }) => theme.colors.facebook};

  & span {
    ${({ theme }) => theme.textStyles.textSmallStrong}
    text-transform: inherit;
  }
`
export const GoogleBtn = styled(ActionButton)`
  & span {
    ${({ theme }) => theme.textStyles.textSmallStrong}
    text-transform: inherit;
  }
`

export const Divider = styled.div`
  display: block;

  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  margin: 2rem 0;

  @media ${mq(sizes.desktop)} {
    height: 20rem;
    width: 1px;
    margin: 0 5rem;
  }
`

export const DividerHorizontal = styled.div`
  display: block;

  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  margin: 2rem 0;
`

export const Register = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mq(sizes.tablet)} {
    margin-top: 1rem;
  }
`

export const RegisterButton = styled(ActionButton)`
  margin-top: 1.5rem;
`
