import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import ActionButton from '../../atoms/ActionButton'
import Link from '../../atoms/Link'
import Picture from '../../atoms/Picture'

export const NavCard = styled(Link)`
  flex: 0 0 auto;
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 22rem;
  padding: 2rem;
  overflow: hidden;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.pureWhite};
  & > * {
    position: relative;
    z-index: 5;
  }
  &::after {
    content: '';
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.pureBlack};
    opacity: 0.35;
  }
  @media ${mq(sizes.scoping)} {
    width: 25rem;
    height: 25rem;
  }
`
export const CardTitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH4}
  line-height: 1.2;
  @media ${mq(sizes.scoping)} {
    ${({ theme }) => theme.textStyles.titleH3}
    line-height: 1.2;
  }
`
export const CardSubtitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH6}
  line-height: 1.4;
  text-transform: unset;
  @media ${mq(sizes.scoping)} {
    ${({ theme }) => theme.textStyles.titleH5}
    line-height: 1.4;
  }
`
export const CardPicture = styled(Picture)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  & img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
export const CardAction = styled(ActionButton)`
  margin-top: auto;
  align-self: flex-start;
  max-width: 100%;
  & > span {
    display: block;
    line-height: 2.4rem;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
`
