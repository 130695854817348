import { BrandColorType } from './types'

const brandColors: BrandColorType = {
  DarkToneColor: '#931119',
  MediumToneColor: '#F9DB6E',
  LightToneColor: '#FAECBF',
  UltraLightToneColor: '#FFF7EC',
  HighLightToneColor: '#DE100D',
}

export default brandColors
