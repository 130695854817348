import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import FormikForm from '../../form/FormikForm'
import FormMessages from '../../form/FormMessages'
import Link from '../../atoms/Link'
import FormSubmitButton from '../../form/FormSubmitButton'
import ActionButton from '../../atoms/ActionButton'
import Icon from '../../atoms/Icon'

export const Container = styled.div``

export const Popin = styled.div`
  position: fixed;
  top: 50%;
  right: 50%;
  z-index: ${({ theme }) => theme.zIndexes.highest};
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  box-shadow: 0 3rem 3rem rgba(0, 0, 0, 0.35);
  min-width: 90%;
  transform: translate(50%, -50%);
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms ease-in, visibility 0s 0s;
  ${Container}[aria-hidden='true'] & {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in, visibility 0ms 200ms;
  }
  @media ${mq(sizes.tablet)} {
    min-width: 88rem;
    border-radius: 3rem;
  }
`

export const LogoContainer = styled.div`
  position: relative;
  height: 6.4rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.linen};
  border-radius: 2rem 2rem 0 0;

  @media ${mq(sizes.tablet)} {
    border-radius: 3rem 3rem 0 0;
  }
`

export const StyledArc = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -100%;
  height: calc(21rem / 2);
  width: 21rem;
  border-radius: calc(21rem / 2) calc(21rem / 2) 0 0;
  background-color: ${({ theme }) => theme.colors.linen};
`

export const LogoImage = styled(Picture)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.linen};
  left: 50%;
  transform: translateX(-50%);
  top: -5rem;
  border-radius: 50%;
  padding: 1.5rem;

  & img {
    width: 11rem;
    height: 8rem;
  }
`

export const Close = styled.button`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  background: ${({ theme }) => theme.colors.pureWhite};
  padding: 0.8rem;
  border-radius: 1.8rem;
  color: ${({ theme }) => theme.colors.arapawa};
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    ${({ theme }) => theme.textStyles.accessibleHiddenText}
  }
`

export const CloseIcon = styled(Icon)`
  width: 3.2rem;
  height: 3.2rem;
`

export const Content = styled.div`
  padding: 2rem 1.5rem;
  @media ${mq(sizes.tablet)} {
    padding: 2rem 3rem 4rem 3rem;
  }
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;

  @media ${mq(sizes.tablet)} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH4}
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
`

export const Subtitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH4}
  color: ${({ theme }) => theme.colors.ruby};
  text-align: center;
`

export const Login = styled.div`
  @media ${mq(sizes.tablet)} {
    min-width: 33rem;
  }
`

export const Form = styled(FormikForm)`
  margin-top: 2rem;
`

export const AuthFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const Errors = styled(FormMessages)`
  margin: 1rem 0 !important;
`

export const LinkResetPassword = styled(Link)`
  ${({ theme }) => theme.textStyles.textXSmall};
  color: ${({ theme }) => theme.colors.bismark};
  text-decoration: underline;
  width: max-content;
  margin: 0.7rem 0 1rem 0;
`

export const SubmitButton = styled(FormSubmitButton)`
  display: block;
  margin: 0 auto;
`

export const Other = styled.div`
  @media ${mq(sizes.tablet)} {
    min-width: 33rem;
  }
`

export const Social = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textMediumStrong};
  color: ${({ theme }) => theme.colors.bismark};
  text-align: center;
  margin-top: 2rem;

  @media ${mq(sizes.tablet)} {
    display: none;
  }
`

export const FacebookBtn = styled(ActionButton)`
  margin: 2rem 0 1.5rem 0;
  background-color: ${({ theme }) => theme.colors.facebook};
  border-color: ${({ theme }) => theme.colors.facebook};

  & span {
    ${({ theme }) => theme.textStyles.textSmallStrong}
    text-transform: inherit;
  }
`
export const GoogleBtn = styled(ActionButton)`
  & span {
    ${({ theme }) => theme.textStyles.textSmallStrong}
    text-transform: inherit;
  }
`

export const Divider = styled.div<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};

  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.zircon};
  margin: 2rem 0;

  @media ${mq(sizes.tablet)} {
    height: 26rem;
    width: 1px;
    margin: 0 2rem;
  }
`

export const SignUp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mq(sizes.tablet)} {
    margin-top: 5.3rem;
  }
`

export const SignUpButton = styled(ActionButton)`
  margin-top: 1.5rem;
`

export const Overlay = styled.button<{ $isOpen?: boolean }>`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.pureBlack};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndexes.highest - 1};
  width: 100%;
  height: 100%;
  opacity: ${({ $isOpen }) => ($isOpen ? 0.2 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  transition: opacity 200ms ease-in, visibility 0ms 200ms;
  &:not(:disabled) {
    cursor: default;
  }
`
