import gql from 'graphql-tag'

export const FooterFragment = gql`
  fragment FooterFragment on Configuration {
    footer {
      socialLinks {
        type
        link {
          href
          label
          target
        }
      }
      legalLinks {
        href
        label
        target
      }
      sectionsLinks {
        link {
          href
          label
          target
        }
        links {
          href
          label
          target
        }
      }
    }
  }
`
