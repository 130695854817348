import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'

const labels: ServiceQuery = {
  query: gql`
    query labels($localeFilters: LocaleFilters!) {
      labels(localeFilters: $localeFilters) {
        key
        value
        type
      }
    }
  `,
  transformer: (data: any) => {
    return data?.labels
  },
}

export default labels
