import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'

const dqeAutocompleteByCityIdAndAddress: ServiceQuery = {
  name: 'dqeAutocompleteByCityIdAndAddress',
  query: gql`
    query dqeAutocompleteByCityIdAndAddress($cityId: ID!, $address: String!) {
      dqeAutocompleteByCityIdAndAddress(cityId: $cityId, address: $address) {
        number
        address
        addressId
      }
    }
  `,
  transformer: (data: any) => {
    return data.dqeAutocompleteByCityIdAndAddress
  },
}

export default dqeAutocompleteByCityIdAndAddress
