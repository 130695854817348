import { createGlobalStyle } from 'styled-components'

import colors from './colors'
import { fontsFaces } from './fonts'

export const GlobalStyles = createGlobalStyle`
  ${fontsFaces()}
  :root {
    --radius-blocks--mobile: 2rem;
    --radius-blocks--desktop: 4rem;
    color-scheme: light only;
  }
  * {
    box-sizing: border-box;
    &:before, &:after {
      box-sizing: border-box;
    }
  }
  html {
    font-size: 62.5%;
  }
  body {
    padding: 0;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }  
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle
  }
  picture img {
    display: block;
  }
  button {
    padding: 0;
    border: 0;
    &:not(:disabled) {
      cursor: pointer;
    }
  }  
  :focus {
    outline: 0.1rem dotted #000;
  }
  :focus:not(:focus-visible) {
    outline: none;
  }
  :focus-visible {
    outline: 0.1rem dotted #000;
  }
  input::-ms-clear {
    display: none;
  }
  // - overrides addthis ʕ丁ε丁ʔ
  #at_hover #atic_email,
  #at_hover #atic_print,
  #at_hover #atic_pinterest_share,
  #at_hover #atic_linkedin,
  #at_hover #atic_more,
  #at15pf
  {
    display: none;
  }
  .atm-i.atm-i {
    border: 0 none;
    padding: 0.4rem;
    margin-block: 0.8rem;
    border-radius: 0.8rem;
  }
  #at_hover.atm-s.atm-s a, 
  .atm-s.atm-s a {
    min-height: 3rem;
    display: flex;
    align-items: center;
    border-radius: 0.4rem;
  }
  #at_hover.atm-s.atm-s a:hover, 
  .atm-s.atm-s a:hover {
    background-color: ${colors.arapawa};
  }
  #at15s#at15s .at-label {
    margin-left: 0.8rem;
  }
  #at-expanded-menu-host#at-expanded-menu-host .at-expanded-menu-top-services-header,
  #at-expanded-menu-host#at-expanded-menu-host #at-expanded-menu-filter-form,
  #at-expanded-menu-host#at-expanded-menu-host #at-expanded-menu-service-list-container,
  #at-expanded-menu-host#at-expanded-menu-host .at-expanded-menu-ft
  {
    display: none;
  }
  #at-expanded-menu-host .at-expanded-menu-service-list {
    & .at3winsvc_print,
    & .at3winsvc_email.at3winsvc_email,
    & .at3winsvc_pinterest_share,
    & .at3winsvc_linkedin,
    & .at3winsvc_gmail,
    & .at3winsvc_viadeo,
    & .at3winsvc_mailto
    {
      display: none;
    }
  }
`
