import { all, call, put, takeLatest } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../api/types/state'
import searchEngineAutocomplete from '../../graphql/Services/SearchEngine/queries/searchEngineAutocomplete'
import searchEngine from '../../graphql/Services/SearchEngine/queries/searchEngine'

export default class SearchSagas {
  static *onAutocompleteRequest({
    payload,
  }: ReturnType<typeof actions.search.autocompleteRequest>) {
    const rs: ApiResponse<typeof searchEngineAutocomplete> = yield call(
      ApiSagas.query,
      searchEngineAutocomplete,
      payload
    )

    if (rs.errors) {
      yield put(actions.search.autocompleteError(rs.errors))
    } else {
      yield put(actions.search.autocompleteSuccess(rs.data))
    }
  }

  static *onSearch({
    payload,
  }: ReturnType<typeof actions.search.soloSearchResultsRequest>) {
    const rs: ApiResponse<typeof searchEngine> = yield call(
      ApiSagas.query,
      searchEngine,
      payload
    )

    if (rs.errors) {
      yield put(actions.search.soloSearchResultsError(rs.errors))
    } else {
      yield put(actions.search.soloSearchResultsSuccess(rs.data))
    }
  }

  static *loop() {
    yield all([
      takeLatest(
        actions.search.autocompleteRequest,
        this.onAutocompleteRequest
      ),
      takeLatest(actions.search.soloSearchResultsRequest, this.onSearch),
    ])
  }
}
