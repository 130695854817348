import styled, { css } from 'styled-components'

type InputStyleProps = {
  $isError?: boolean
  $isActive?: boolean
  $hasLabel?: boolean
  $isDisabled?: boolean
  $isReadOnly?: boolean
  $hasErrorMessage?: boolean
}

export const Container = styled.div<InputStyleProps>`
  display: flex;
  flex-flow: column;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      opacity: 0.8;
      &,
      & * {
        cursor: not-allowed;
      }
    `}
`

export const Wrapper = styled.label<InputStyleProps>`
  border: 1px solid ${({ theme }) => theme.colors.pattensBlue};
  background-color: ${({ theme, $isReadOnly }) =>
    $isReadOnly ? theme.colors.floralWhite : theme.colors.pureWhite};
  border-radius: 0.6rem;
  position: relative;
  padding: 1.8rem 2rem;
  width: 100%;

  ${({ $isError, theme }) =>
    $isError === true &&
    css`
      border-color: ${theme.colors.error};
    `}
`

export const Label = styled.span<{ $required?: boolean; $active?: boolean }>`
  ${({ theme }) => theme.textStyles.textSmallStrong}
  color: ${({ theme }) => theme.colors.bismark};
  position: absolute;
  transition: top 0.2s, font-size 0.2s, transform 0.2s, font-weight 0.2s;
  top: calc(50% - 1rem);
  margin: auto;
  ${(props) =>
    props.$required &&
    css`
      &::after {
        content: '*';
        position: relative;
        padding-left: 0.2rem;
      }
    `}
  ${(props) =>
    props.$active &&
    css`
      ${({ theme }) => theme.textStyles.textXSmall}
      top: 0.75rem;
    `}
`

export const InputContainer = styled.span`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  position: relative;
`

export const inputStyle = css<InputStyleProps>`
  ${({ theme }) => theme.textStyles.textMedium};
  line-height: 1;
  width: 100%;
  border: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  background: transparent;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      pointer-events: none;
    `}

  /* ${({ $hasLabel, $isActive }) =>
    $hasLabel &&
    css`
      position: relative;
      transition: top 0.2s;
      top: 0.5rem;
      ${$isActive &&
      css`
        top: 1rem;
      `}
    `} */

    ${({ $hasLabel, $isActive }) =>
    $hasLabel &&
    css`
      position: relative;
      transition: top 0.2s;
      top: 0.5rem;
      color: transparent;
      ${$isActive &&
      css`
        top: 1rem;
        color: ${({ theme }) => theme.colors.arapawa};
      `}
    `}

  &::placeholder {
    opacity: 0.5;
  }

  // readonly
  &[readonly] {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &[type='password'] {
    letter-spacing: 3px;
  }
`

export const Input = styled.input<InputStyleProps>`
  ${inputStyle}
`
const HintMessage = styled.p`
  ${({ theme }) => theme.textStyles.textXSmall}
  margin-top: 0.5rem;
  padding-inline: 0.8rem;
  text-align: left;
`
export const HelpMessage = styled(HintMessage)`
  color: ${({ theme }) => theme.colors.bismark};
`
export const ErrorMessage = styled(HintMessage)`
  color: ${({ theme }) => theme.colors.error};
`
