import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'
import { RecipeDetailsFragment } from '../../../fragments/RecipeDetailsFragment'

const newsletterCheck: ServiceQuery = {
  query: gql`
    query recipe($id: ID, $slug: String) {
      recipe(id: $id, slug: $slug) {
        ...RecipeDetailsFragment
      }
    }
    ${RecipeDetailsFragment}
  `,
  transformer: (data: any) => {
    return data?.recipe
  },
}

export default newsletterCheck
