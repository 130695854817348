import { FC } from 'react'

import useAuthPushPopin from '../../../relay/Common/useAuthPushPopin'

import PushAuthPopin from '.'

const PushAuthPopinWrapper: FC = () => {
  const authPushPopinProps = useAuthPushPopin()

  return <PushAuthPopin {...authPushPopinProps} />
}

export default PushAuthPopinWrapper
