import styled from 'styled-components'

import Picture from '../../components/atoms/Picture'
import { mq, sizes } from '../../theme'
import ActionButton from '../../components/atoms/ActionButton'

export const Maintenance = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.arapawa};
`

export const BackgroundImage = styled(Picture)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

export const LogoContainer = styled.div`
  position: relative;
  height: 6.4rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.linen};
  border-radius: 2rem 2rem 0 0;

  @media ${mq(sizes.tablet)} {
    border-radius: 3rem 3rem 0 0;
  }
`

export const StyledArc = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -100%;
  height: calc(21rem / 2);
  width: 21rem;
  border-radius: calc(21rem / 2) calc(21rem / 2) 0 0;
  background-color: ${({ theme }) => theme.colors.linen};
`

export const LogoImage = styled(Picture)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.linen};
  left: 50%;
  transform: translateX(-50%);
  top: -6rem;
  border-radius: 50%;
  padding: 1.5rem;

  & img {
    width: 11rem;
    height: 11rem;
  }
`

export const Content = styled.div`
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  width: 100%;
  text-align: center;
  padding: 0 2rem;

  @media ${mq(sizes.tablet)} {
    max-width: 64rem;
    top: 12rem;
    padding: 0;
  }
`

export const TextsContent = styled.div`
  background-color: ${({ theme }) => theme.colors.pureWhite};
  padding: 3rem 1.5rem;
  border-radius: 0 0 2rem 2rem;

  @media ${mq(sizes.tablet)} {
    padding: 3rem;
    border-radius: 0 0 3rem 3rem;
  }
`

export const Title = styled.h1`
  ${({ theme }) => theme.textStyles.titleH2};
  color: ${({ theme }) => theme.colors.ruby};
  text-align: center;
  margin: 0 0 0.3rem 0;

  @media ${mq(sizes.mobile)} {
    ${({ theme }) => theme.textStyles.titleH1};
  }
`

export const Subtitle = styled.p`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;

  @media ${mq(sizes.mobile)} {
    ${({ theme }) => theme.textStyles.textLarge};
  }
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textSmall};
  color: ${({ theme }) => theme.colors.ruby};
  text-align: center;
  margin: 4rem 0 2rem 0;

  @media ${mq(sizes.mobile)} {
    ${({ theme }) => theme.textStyles.textMedium};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media ${mq(sizes.mobile)} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
`

export const Button = styled(ActionButton)``
