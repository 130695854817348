import gql from 'graphql-tag'

export const SeoFragment = gql`
  fragment SeoFragment on Seo {
    title
    description
    picture
    robots
  }
`
