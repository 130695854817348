import gql from 'graphql-tag'

export const LocaleFragment = gql`
  fragment LocaleFragment on Locale {
    id
    isAvailableInApi
    iso
    label
    fallback {
      id
      isAvailableInApi
      iso
      label
    }
    fallbackId
  }
`
