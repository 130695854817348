import styled, { css } from 'styled-components'

import Picture from '../../atoms/Picture'
import Icon from '../../atoms/Icon'
import ActionButton from '../../atoms/ActionButton'
import { mq, sizes } from '../../../theme'

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 36rem;
  @media ${mq(sizes.tablet)} {
    min-width: min(100%, 30rem);
  }
`
export const Card = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.ruby};
  overflow: hidden;
`
export const Head = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.ruby};
`
export const Logo = styled(Picture)`
  display: flex;
  width: 6.4rem;
  height: 6.4rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 100%;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  & img {
    width: 92%;
    height: 92%;
    border-radius: 100%;
    object-fit: contain;
  }
`
export const HeadContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: flex-start;
`
export const Title = styled.div`
  ${({ theme }) => theme.textStyles.textSmallStrong};
  color: ${({ theme }) => theme.colors.pureWhite};
`
export const Label = styled.div`
  background-color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  gap: 0.5rem;
  ${({ theme }) => theme.textStyles.textXSmallStrong};
  color: ${({ theme }) => theme.colors.arapawa};
  padding: 0.4rem 0.5rem;
  border-radius: 0.4rem;
  align-items: center;
  justify-content: center;
`
export const LabelIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.atomicTangerine};
  height: 1rem;
  width: 1rem;
`
export const HelpButton = styled.div`
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border: 1px solid ${({ theme }) => theme.colors.bismark};
  border-radius: 100%;
  width: 2.8rem;
  height: 2.8rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
export const HelpIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.arapawa};
  width: 1.6rem;
  height: 1.6rem;
`
export const Main = styled.div`
  flex: 1;
  display: flex;
  gap: 1.5rem;
  width: 100%;
  padding: 0.5rem 1rem 1.5rem;
`
export const ProductImage = styled(Picture)`
  width: 12rem;
  height: 12rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`

export const QuantityLabel = styled.div`
  ${({ theme }) => theme.textStyles.titleH8};
  color: ${({ theme }) => theme.colors.pureWhite};
  background-color: ${({ theme }) => theme.colors.ruby};
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 1rem;
  flex-grow: 1;
`

export const Amount = styled.div`
  ${({ theme }) => theme.textStyles.titleH1};
  color: ${({ theme }) => theme.colors.arapawa};
  & sup {
    ${({ theme }) => theme.textStyles.textXLarge};
  }
  flex-grow: 1;
`
export const ShortDescription = styled.div`
  ${({ theme }) => theme.textStyles.textXSmall};
  color: ${({ theme }) => theme.colors.bismark};
`
export const Button = styled(ActionButton)<{ $disabled?: boolean }>`
  pointer-events: ${({ $disabled }) => $disabled && 'none'};
`
export const WhiteOverlay = styled.div<{ $readable?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  opacity: ${({ $readable }) => ($readable ? 0.95 : 0.75)};
`
export const Help = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 3rem 4rem;
`
export const HelpText = styled.div`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
`
export const HelpBoldText = styled.div`
  ${({ theme }) => theme.textStyles.textMediumStrong};
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
`
export const AddedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.arapawa};
  border: 1px solid ${({ theme }) => theme.colors.arapawa};
  opacity: 0.75;
`
const bigLabel = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3rem;
  text-align: center;
  ${({ theme }) => theme.textStyles.titleH2};
`
export const AddedLabel = styled.div`
  ${bigLabel}
  color: ${({ theme }) => theme.colors.pureWhite};
`
export const AddedButton = styled(ActionButton)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`
export const OutLabel = styled.div`
  ${bigLabel}
  color: ${({ theme }) => theme.colors.ruby};
`
export const UsedLabel = styled.div`
  ${bigLabel}
  color: ${({ theme }) => theme.colors.arapawa};
`
