import styled from 'styled-components'

import { mq, sizes } from '../../../theme'

export const NutritionBlock = styled.aside`
  padding: 3rem 1.5rem;
  border-radius: 2rem;
  background: ${({ theme }) => theme.colors.linen};
  color: ${({ theme }) => theme.colors.arapawa};
`
export const Section = styled.section`
  &:not(:first-child) {
    margin-top: 4rem;
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH4}
  margin: 0;
`
export const List = styled.dl`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-flow: row;
  grid-gap: 1rem 2rem;
  &:not(:first-child) {
    margin-top: 2rem;
  }
  @media ${mq(sizes.tablet)} {
    grid-template-columns: 1fr 1fr;
  }
`
export const Item = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.tablet)} {
  }
`
export const Label = styled.dt`
  ${({ theme }) => theme.textStyles.textMedium}
  color: ${({ theme }) => theme.colors.ruby};
`
export const Info = styled.dd`
  ${({ theme }) => theme.textStyles.textSmall}
  margin: 0;
  & strong,
  & b {
    ${({ theme }) => theme.textStyles.textSmallStrong}
  }
`
