import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'
import { SeoFragment } from './SeoFragment'

export const TagDetailsFragment = gql`
  fragment TagDetailsFragment on Tag {
    id
    type
    name
    slug
    longName
    description
    longDescription
    picture {
      ...WediaImageFragment
    }
    thumbnail {
      ...WediaImageFragment
    }
    seoBlockTitle
    seoBlockBody
    hit
    hitFolder
    hitArticle
    voucherChildren
    items {
      data {
        id
        title
        slug
        type
        articleType
        thumbnail {
          ...WediaImageFragment
        }
        brandName
        brandSlug
        recipeNutriscore
        recipePositiveFood
        recipeCookingTime {
          name
        }
        cheeseMilk {
          name
        }
        cheeseRegion {
          name
        }
      }
      paginatorInfo {
        lastPage
        currentPage
      }
      count {
        type
        count
      }
    }
    relatedTags {
      name
      slug
    }
    seo {
      ...SeoFragment
    }
    createdAt
    updatedAt
  }
  ${SeoFragment}
  ${WediaImageFragment}
`
