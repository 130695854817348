import { getService } from '../../helpers/ReduxHelpers'
import type {
  Member,
  MutationNewsletterSubscribeArgs,
  MutationMemberRegisterArgs,
  MutationMemberUpdateArgs,
  MutationFavoriteToggleArgs,
  Recipe,
  Article,
  CulinaryFolder,
  Brand,
  DqeAutocompleteByZipCode,
  DqeAutocompleteByCityIdAndAddress,
  MutationNewsletterUnsubscribeArgs,
  DqeCheckEmailResponse,
  QueryDqeCheckEmailArgs,
} from '../../graphql/generated/api-graphql'

export const newsletterSubscribeService = getService<
  'newsletterSubscribe',
  Member,
  MutationNewsletterSubscribeArgs
>('newsletterSubscribe')
export const newsletterUnsubscribeService = getService<
  'newsletterUnsubscribe',
  Member,
  MutationNewsletterUnsubscribeArgs
>('newsletterUnsubscribe')

export const memberRegisterService = getService<
  'memberRegister',
  Member,
  MutationMemberRegisterArgs
>('memberRegister')

export const memberUpdateService = getService<
  'memberUpdate',
  Member,
  MutationMemberUpdateArgs
>('memberUpdate')

export const memberDeleteService = getService<'memberDelete'>('memberDelete')

export const favoriteToggleService = getService<
  'favoriteToggle',
  Member,
  MutationFavoriteToggleArgs
>('favoriteToggle')

export const favoritesRecipesService = getService<'favoritesRecipes', Recipe[]>(
  'favoritesRecipes'
)

export const favoritesArticlesService = getService<
  'favoritesArticles',
  Article[]
>('favoritesArticles')

export const favoritesFoldersService = getService<
  'favoritesFolders',
  CulinaryFolder[]
>('favoritesFolders')

export const favoritesBrandsService = getService<'favoritesBrands', Brand[]>(
  'favoritesBrands'
)

export const autocompleteByZipCodeService = getService<
  'dqeAutocompleteByZipCode',
  DqeAutocompleteByZipCode
>('dqeAutocompleteByZipCode')

export const autocompleteByCityIdAndAddress = getService<
  'dqeAutocompleteByCityIdAndAddress',
  DqeAutocompleteByCityIdAndAddress
>('dqeAutocompleteByCityIdAndAddress')

export const checkMail = getService<
  'dqeCheckMail',
  QueryDqeCheckEmailArgs,
  DqeCheckEmailResponse
>('dqeCheckMail')
