import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { actions, selectors } from '../../redux'
import { app } from '../../configuration'
import Toaster from '../../components/atoms/Toaster'

const ToasterWrapper: FC = () => {
  const isInit = useSelector(selectors.app.isInit)
  const toaster = useSelector(selectors.app.toaster)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (!toaster) return

    const timeout = setTimeout(() => {
      dispatch(actions.app.resetToaster())
    }, app.TOASTER_TIMEOUT)

    return () => {
      clearTimeout(timeout)
    }
  }, [dispatch, toaster])

  if (!toaster || !isInit) {
    return <Toaster text="" />
  }

  return <Toaster {...toaster} text={t(toaster.text as string)} open={true} />
}

export default ToasterWrapper
