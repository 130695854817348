import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'
import { CapriceValentineSession } from '../../../generated/api-graphql'
import { CapriceValentineSessionFragment } from '../../../fragments/CapriceValentineSessionFragment'

const soloPageCapriceValentineSaveSessionInput: ServiceMutation = {
  name: 'soloPageCapriceValentineSaveSessionInput',
  mutation: gql`
    mutation soloPageCapriceValentineSaveSessionInput(
      $sessionUid: ID!
      $order: Int!
      $input: String!
      $recaptcha: String!
    ) {
      soloPageCapriceValentineSaveSessionInput(
        recaptcha: $recaptcha
        sessionUid: $sessionUid
        order: $order
        input: $input
      ) {
        ...CapriceValentineSessionFragment
      }
    }
    ${CapriceValentineSessionFragment}
  `,
  transformer: (data: any): CapriceValentineSession => {
    return data?.soloPageCapriceValentineSaveSessionInput
  },
}

export default soloPageCapriceValentineSaveSessionInput
