export const loadLocalesTransformer = (data: any) => data?.locales

export const transformLabels = (labels: any) =>
  labels?.length
    ? labels?.reduce(
        (a: any, label: { key: string; value: string; type: string }) => {
          a[label.key] = label.value

          return a
        },
        {}
      )
    : null

export const loadLabelsTransformer = (data: any) =>
  transformLabels(data?.labels)

export const loadLocalesAndLabelsTransformer = (data: any) => {
  return {
    locales: loadLocalesTransformer(data),
    labels: loadLabelsTransformer(data),
  }
}
