import React, { FC } from 'react'
import cx from 'classnames'

import { PictureProps } from '../../../atoms/Picture'
import { Icons } from '../../../atoms/Icon'
import { useNoOverflow } from '../../../../hooks/useNoOverflow'

import * as SC from './styled'

export type BaseModalProps = {
  className?: string
  isOpen?: boolean
  closeHandler?: () => void
  logoProps?: PictureProps
  children?: React.ReactNode
  hideCloseButton?: boolean
}

const BaseModal: FC<BaseModalProps> = (props) => {
  const {
    className,
    logoProps,
    isOpen,
    closeHandler,
    children,
    hideCloseButton,
  } = props

  useNoOverflow(isOpen ?? false)

  return (
    <SC.Container
      className={cx('BaseModal', className)}
      aria-hidden={!isOpen}
      $hideCloseButton={hideCloseButton ?? false}
    >
      <SC.Popin>
        {logoProps && (
          <SC.LogoContainer>
            <SC.StyledArc></SC.StyledArc>
            {logoProps && <SC.LogoImage {...logoProps} />}
            {closeHandler && !hideCloseButton && (
              <SC.Close onClick={closeHandler}>
                <span>{'Close'}</span>
                <SC.CloseIcon icon={Icons.close} />
              </SC.Close>
            )}
          </SC.LogoContainer>
        )}
        {children}
      </SC.Popin>
      <SC.Overlay $isOpen={isOpen} onClick={closeHandler} type="button" />
    </SC.Container>
  )
}

export default BaseModal
