import { all, call, put, takeLatest } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../api/types/state'
import coupons from '../../graphql/Services/Highco/queries/coupons'
import brandCoupons from '../../graphql/Services/Brand/queries/brandCoupons'
import couponsBirthday from '../../graphql/Services/Highco/queries/couponsBirthday'
import couponGetCart from '../../graphql/Services/Highco/queries/couponGetCart'
import couponAddToCart from '../../graphql/Services/Highco/mutations/couponAddToCart'
import couponRemoveFromCart from '../../graphql/Services/Highco/mutations/couponRemoveFromCart'
import couponPrintCart from '../../graphql/Services/Highco/mutations/couponPrintCart'

export default class CouponsSagas {
  static *onCouponsRequest({
    payload,
  }: ReturnType<typeof actions.coupons.couponsRequest>) {
    const rs: ApiResponse<typeof coupons> = yield call(
      ApiSagas.query,
      coupons,
      payload
    )

    if (rs.errors) {
      yield put(actions.coupons.couponsError(rs.errors))
    } else {
      yield put(actions.coupons.couponsSuccess(rs.data))
    }
  }
  static *onBrandCouponsRequest({
    payload,
  }: ReturnType<typeof actions.coupons.brandCouponsRequest>) {
    const rs: ApiResponse<typeof brandCoupons> = yield call(
      ApiSagas.query,
      brandCoupons,
      payload
    )

    if (rs.errors) {
      yield put(actions.coupons.brandCouponsError(rs.errors))
    } else {
      yield put(actions.coupons.brandCouponsSuccess(rs.data))
    }
  }
  static *onFormatBrandCouponsRequest({
    payload,
  }: ReturnType<typeof actions.coupons.formatBrandCouponsRequest>) {
    const rs: ApiResponse<typeof brandCoupons> = yield call(
      ApiSagas.query,
      brandCoupons,
      payload
    )

    if (rs.errors) {
      yield put(actions.coupons.formatBrandCouponsError(rs.errors))
    } else {
      yield put(actions.coupons.formatBrandCouponsSuccess(rs.data))
    }
  }
  static *onBirthdayCouponsRequest({
    payload,
  }: ReturnType<typeof actions.coupons.birthdayCouponsRequest>) {
    const rs: ApiResponse<typeof couponsBirthday> = yield call(
      ApiSagas.query,
      couponsBirthday,
      payload
    )

    if (rs.errors) {
      yield put(actions.coupons.birthdayCouponsError(rs.errors))
    } else {
      yield put(actions.coupons.birthdayCouponsSuccess(rs.data))
    }
  }
  static *onGetCartRequest({
    payload,
  }: ReturnType<typeof actions.coupons.getCartRequest>) {
    const rs: ApiResponse<typeof couponGetCart> = yield call(
      ApiSagas.query,
      couponGetCart,
      payload
    )

    if (rs.errors) {
      yield put(actions.coupons.getCartError(rs.errors))
    } else {
      yield put(actions.coupons.setCart(rs.data))
      yield put(actions.coupons.getCartSuccess(rs.data))
    }
  }
  static *onAddToCartRequest({
    payload,
  }: ReturnType<typeof actions.coupons.addToCartRequest>) {
    const rs: ApiResponse<typeof couponAddToCart> = yield call(
      ApiSagas.mutate,
      couponAddToCart,
      payload
    )

    if (rs.errors) {
      yield put(actions.coupons.addToCartError(rs.errors))
    } else {
      yield put(actions.coupons.setCart(rs.data))
      yield put(actions.coupons.addToCartSuccess(rs.data))
    }
  }
  static *onRemoveFromCartRequest({
    payload,
  }: ReturnType<typeof actions.coupons.removeFromCartRequest>) {
    const rs: ApiResponse<typeof couponRemoveFromCart> = yield call(
      ApiSagas.mutate,
      couponRemoveFromCart,
      payload
    )

    if (rs.errors) {
      yield put(actions.coupons.removeFromCartError(rs.errors))
    } else {
      yield put(actions.coupons.setCart(rs.data))
      yield put(actions.coupons.removeFromCartSuccess(rs.data))
    }
  }
  static *onPrintCartRequest({
    payload,
  }: ReturnType<typeof actions.coupons.printCartRequest>) {
    const rs: ApiResponse<typeof couponPrintCart> = yield call(
      ApiSagas.mutate,
      couponPrintCart,
      payload
    )

    if (rs.errors) {
      yield put(actions.coupons.printCartError(rs.errors))
    } else {
      yield put(actions.coupons.printCartSuccess(rs.data))
    }
  }

  static *loop() {
    yield all([
      takeLatest(actions.coupons.couponsRequest, this.onCouponsRequest),
      takeLatest(
        actions.coupons.brandCouponsRequest,
        this.onBrandCouponsRequest
      ),
      takeLatest(
        actions.coupons.formatBrandCouponsRequest,
        this.onFormatBrandCouponsRequest
      ),
      takeLatest(
        actions.coupons.birthdayCouponsRequest,
        this.onBirthdayCouponsRequest
      ),
      takeLatest(actions.coupons.getCartRequest, this.onGetCartRequest),
      takeLatest(actions.coupons.addToCartRequest, this.onAddToCartRequest),
      takeLatest(
        actions.coupons.removeFromCartRequest,
        this.onRemoveFromCartRequest
      ),
      takeLatest(actions.coupons.printCartRequest, this.onPrintCartRequest),
    ])
  }
}
