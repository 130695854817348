import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'
import { CulinaryFolderCardFragment } from '../../../fragments/CulinaryFolderCardFragment'
import { ArticleCardFragment } from '../../../fragments/ArticleCardFragment'
import { CulinaryArticleCardFragment } from '../../../fragments/CulinaryArticleCardFragment'
import { SearchEngineResponse } from '../../../generated/api-graphql'
import { RecipeSmallCardFragment } from '../../../fragments/RecipeSmallCardFragment'
import { WediaImageFragment } from '../../../fragments/WediaImageFragment'
import { PlateCardFragment } from '../../../fragments/PlateCardFragment'
import { SearchType } from '../../../../relay/SearchEngine/searchType'

export const SEARCH_PER_PAGE = 27
export type SearchEngineEnhancedResponse = SearchEngineResponse & {
  counts: {
    all: number
    [SearchType.Article]: number
    [SearchType.Cheese]: number
    [SearchType.Recipe]: number
    [SearchType.Folder]: number
  }
}

const searchEngine: ServiceQuery = {
  query: gql`
    query searchEngine(
      $q: String
      $type: SearchEngineType
      $tags: [ID!]
      $nutriscores: [String!]
      $onlyPositiveFood: Boolean
      $onlyWithVideo: Boolean
      $seasons: [RecipeSeason!]
      $brands: [ID!]
      $cheeses: [ID!]
      $page: Int
      $first: Int
      $orderBy: SearchEngineOrderBy
    ) {
      searchEngine(
        q: $q
        type: $type
        page: $page
        first: $first
        tags: $tags
        nutriscores: $nutriscores
        onlyPositiveFood: $onlyPositiveFood
        onlyWithVideo: $onlyWithVideo
        seasons: $seasons
        brandIds: $brands
        cheeseIds: $cheeses
        orderBy: $orderBy
      ) {
        data {
          ...RecipeSmallCardFragment
          ...CulinaryFolderCardFragment
          ...ArticleCardFragment
          ...CulinaryArticleCardFragment
          ...PlateCardFragment
          ... on Cheese {
            __typename
            id
            title
            slug
            cheeseType: type
            pictureHeader {
              ...WediaImageFragment
            }
            thumbnail01 {
              ...WediaImageFragment
            }
            health
            nutriscore
            brand {
              name
              slug
              pictureLogo {
                ...WediaImageFragment
              }
            }
            tags {
              name
              slug
            }
            tagRegion {
              name
            }
            tagMilk {
              name
            }
          }
        }
        paginatorInfo {
          total
          perPage
          lastPage
          currentPage
        }
      }
      total: searchEngine(
        q: $q
        first: 1
        tags: $tags
        nutriscores: $nutriscores
        onlyPositiveFood: $onlyPositiveFood
        onlyWithVideo: $onlyWithVideo
        seasons: $seasons
        brandIds: $brands
        cheeseIds: $cheeses
        orderBy: $orderBy
      ) {
        paginatorInfo {
          total
        }
      }
      article: searchEngine(
        q: $q
        type: ARTICLE
        first: 1
        tags: $tags
        nutriscores: $nutriscores
        onlyPositiveFood: $onlyPositiveFood
        onlyWithVideo: $onlyWithVideo
        seasons: $seasons
        brandIds: $brands
        cheeseIds: $cheeses
        orderBy: $orderBy
      ) {
        paginatorInfo {
          total
        }
      }
      cheese: searchEngine(
        q: $q
        type: CHEESE
        first: 1
        tags: $tags
        nutriscores: $nutriscores
        onlyPositiveFood: $onlyPositiveFood
        onlyWithVideo: $onlyWithVideo
        seasons: $seasons
        brandIds: $brands
        cheeseIds: $cheeses
        orderBy: $orderBy
      ) {
        paginatorInfo {
          total
        }
      }
      recipe: searchEngine(
        q: $q
        type: RECIPE
        first: 1
        tags: $tags
        nutriscores: $nutriscores
        onlyPositiveFood: $onlyPositiveFood
        onlyWithVideo: $onlyWithVideo
        seasons: $seasons
        brandIds: $brands
        cheeseIds: $cheeses
        orderBy: $orderBy
      ) {
        paginatorInfo {
          total
        }
      }
      folder: searchEngine(
        q: $q
        type: FOLDER
        first: 1
        tags: $tags
        nutriscores: $nutriscores
        onlyPositiveFood: $onlyPositiveFood
        onlyWithVideo: $onlyWithVideo
        seasons: $seasons
        brandIds: $brands
        cheeseIds: $cheeses
        orderBy: $orderBy
      ) {
        paginatorInfo {
          total
        }
      }
    }
    ${WediaImageFragment}
    ${PlateCardFragment}
    ${RecipeSmallCardFragment}
    ${CulinaryFolderCardFragment}
    ${ArticleCardFragment}
    ${CulinaryArticleCardFragment}
  `, // todo : small card fragments
  transformer: (data: any): SearchEngineEnhancedResponse => {
    return {
      ...data?.searchEngine,
      counts: {
        all: data.total?.paginatorInfo?.total,
        [SearchType.Article]: data.article?.paginatorInfo?.total,
        [SearchType.Cheese]: data.cheese?.paginatorInfo?.total,
        [SearchType.Recipe]: data.recipe?.paginatorInfo?.total,
        [SearchType.Folder]: data.folder?.paginatorInfo?.total,
      },
    }
  },
}

export default searchEngine
