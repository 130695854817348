import React, { FC } from 'react'
import cx from 'classnames'

import { PictureProps } from '../../atoms/Picture'
import { IconProps, Icons } from '../../atoms/Icon'
import { ActionButtonProps } from '../../atoms/ActionButton'
import { LinkProps } from '../../atoms/Link'
import { PopinProps } from '../../atoms/Popin'

import * as SC from './styled'

export type PushAuthPopinProps = {
  className?: string
  popin: Omit<PopinProps, 'children'>
  background: PictureProps
  logo: PictureProps
  title: string
  subtitle: string
  advantages: {
    title: string
    text: string
    icon: IconProps
  }[]
  registerCta: ActionButtonProps
  loginCta: LinkProps
}

const PushAuthPopin: FC<PushAuthPopinProps> = (props) => {
  const {
    className,
    popin,
    background,
    logo,
    title,
    subtitle,
    advantages,
    registerCta,
    loginCta,
  } = props

  return (
    <SC.PopinContainer className={cx('PushAuthPopin', className)} {...popin}>
      <SC.Header>
        <SC.Background {...background} />
        <SC.LogoWrapper>
          <SC.Logo {...logo} />
          <SC.Shape icon={Icons.soloHeroFullRound} aria-hidden />
        </SC.LogoWrapper>
        <SC.Close onClick={popin?.closeHandler}>
          <SC.CloseIcon icon={Icons.close} />
        </SC.Close>
      </SC.Header>
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.Subtitle>{subtitle}</SC.Subtitle>
        <SC.Advantages>
          {advantages.map((advantage, index) => (
            <SC.Advantage key={index}>
              <SC.AdvantageIcon {...advantage.icon} />
              <SC.AdvantageContent>
                <SC.AdvantageTitle>{advantage.title}</SC.AdvantageTitle>
                <SC.AdvantageText>{advantage.text}</SC.AdvantageText>
              </SC.AdvantageContent>
            </SC.Advantage>
          ))}
        </SC.Advantages>
        <SC.Ctas>
          <SC.RegisterCta {...registerCta} />
          <SC.Divider />
          <SC.LoginCta {...loginCta} />
        </SC.Ctas>
      </SC.Content>
    </SC.PopinContainer>
  )
}

export default PushAuthPopin
