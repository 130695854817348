import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'

const couponPrintCart: ServiceMutation = {
  mutation: gql`
    mutation couponPrintCart {
      couponPrintCart
    }
  `,
  transformer: (data: any) => {
    return data?.couponPrintCart
  },
}

export default couponPrintCart
