import React, { FC } from 'react'
import cx from 'classnames'

import { FormikFormProps } from '../../../form/FormikForm'
import { ActionButtonProps } from '../../../atoms/ActionButton'
import FormikField, { FormikFieldProps } from '../../../form/FormikField'
import { LinkProps } from '../../../atoms/Link'
import { FormMessagesProps } from '../../../form/FormMessages'
import { FormSubmitButtonProps } from '../../../form/FormSubmitButton'
import BaseModal, { BaseModalProps } from '../BaseModal'

import * as SC from './styled'

export type LightAuthLoginModalProps = BaseModalProps & {
  className?: string
  background?: string
  title: string
  login: {
    formikForm?: FormikFormProps
    submitButton?: FormSubmitButtonProps
    formikFieldEmailProps?: FormikFieldProps
    formikFieldPasswordProps?: FormikFieldProps
    linkResetPasswordProps?: LinkProps
    errors?: FormMessagesProps
  }
  social: {
    actionFacebookButtonProps?: ActionButtonProps
    actionGoogleButtonProps?: ActionButtonProps
  }
  register?: {
    title: string
    registerButtonProps?: ActionButtonProps
  }
}

const LightAuthLoginModal: FC<LightAuthLoginModalProps> = (props) => {
  const {
    className,
    background,
    title,
    login,
    social,
    register,
    ...modalProps
  } = props

  return (
    <BaseModal className={cx('LightAuthLoginModal', className)} {...modalProps}>
      <SC.Content $background={background}>
        <SC.Title>{title}</SC.Title>
        <SC.Box>
          <SC.Login>
            {login.formikForm && (
              <SC.Form {...login.formikForm}>
                <SC.AuthFields>
                  {login.formikFieldEmailProps && (
                    <FormikField {...login.formikFieldEmailProps} />
                  )}
                  {login.formikFieldPasswordProps && (
                    <FormikField {...login.formikFieldPasswordProps} />
                  )}
                </SC.AuthFields>
                {login.linkResetPasswordProps && (
                  <SC.LinkResetPassword {...login.linkResetPasswordProps} />
                )}
                {login.errors && <SC.Errors {...login.errors} />}
                <SC.SubmitButton {...login.submitButton} />
              </SC.Form>
            )}
          </SC.Login>
          <SC.Divider />
          <SC.Other>
            {social && (
              <SC.Social>
                {social.actionFacebookButtonProps && (
                  <SC.FacebookBtn {...social.actionFacebookButtonProps} />
                )}
                {social.actionGoogleButtonProps && (
                  <SC.GoogleBtn {...social.actionGoogleButtonProps} />
                )}
              </SC.Social>
            )}
            {register && (
              <>
                <SC.DividerHorizontal />
                <SC.Register>
                  <SC.Subtitle>{register.title}</SC.Subtitle>
                  {register.registerButtonProps && (
                    <SC.RegisterButton {...register.registerButtonProps} />
                  )}
                </SC.Register>
              </>
            )}
          </SC.Other>
        </SC.Box>
      </SC.Content>
    </BaseModal>
  )
}

export default LightAuthLoginModal
