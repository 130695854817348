import dayjs from 'dayjs'

import {
  Member,
  MemberConsent,
  MemberUpdateInput,
} from '../../graphql/generated/api-graphql'
import { parseDateString } from '../../helpers/DateHelpers'
import {
  doesItNeedPasswordReset,
  isMemberValid,
  isSocialConnectUser,
} from '../../helpers/UserHelpers'
import { transformObjectKeysToCamelCase } from '../../utils/Common'

export type RealMemberConsents =
  | { [key: string]: MemberConsent | undefined | null }
  | undefined
  | null

export const transformMemberFromReach5 = (
  member: Member
): MemberUpdateInput & {
  canBeDeleted: boolean
  canAccessCoupon: boolean
  isValidProfile: boolean
  isSocialConnectUser: boolean
  needPasswordReset: boolean
  isNewSocialConnectUser: boolean
} => {
  const checkPasswordDate = new Date(2024, 4, 3, 0, 0, 0, 0) // new Date(2024, 3, 15, 0, 0, 0, 0)

  const createdAt = parseDateString(member?.createdAt)

  const addressObject = member?.addresses?.[0]
  const city = addressObject?.locality ?? null
  const address = addressObject?.streetAddress ?? null
  const zipcode = addressObject?.postalCode ?? null

  const customFields = member?.customFields
    ? transformObjectKeysToCamelCase(member.customFields)
    : null

  const socialConnectUser = isSocialConnectUser(member)

  return {
    ...(member.email && { email: member.email }),
    ...(member.phoneNumber && { phoneNumber: member.phoneNumber }),
    ...(member.givenName && { firstName: member.givenName }),
    ...(member.familyName && { lastName: member.familyName }),
    ...(member.gender && { gender: member.gender }),
    ...(member.birthdate && { birthDate: member.birthdate }),
    ...(address && { address: address }),
    ...(city && { city: city }),
    ...(zipcode && { zipCode: zipcode }),
    ...(customFields && { customFields: customFields }),
    canBeDeleted: Date.now() - createdAt.getTime() > 3 * 60 * 1000,
    canAccessCoupon: !!(address && city && zipcode),
    isValidProfile: isMemberValid(member),
    isSocialConnectUser: socialConnectUser,
    needPasswordReset: doesItNeedPasswordReset(
      member,
      customFields,
      checkPasswordDate
    ),
    isNewSocialConnectUser:
      socialConnectUser && dayjs().diff(dayjs(member?.createdAt), 'minute') < 5,
  }
}
