import fonts from './fonts'

const textStyles = {
  titleH1: `
    ${fonts.PoppinsExtraBold}
    font-size: 3.6rem;
    line-height: 1.2;
  `,
  titleH2: `
    ${fonts.PoppinsBold}
    font-size: 3rem;
    line-height: 1.2;
  `,
  titleH3: `
    ${fonts.PoppinsBold}
    font-size: 2.4rem;
    line-height: 1.2;
  `,
  titleH4: `
    ${fonts.PoppinsBold}
    font-size: 2rem;
    line-height: 1.2;
  `,
  titleH5: `
    ${fonts.PoppinsBold}
    font-size: 1.8rem;
    line-height: 1.4;
  `,
  titleH6: `
    ${fonts.PoppinsBold}
    font-size: 1.6rem;
    line-height: 1.2;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
  `,
  titleH7: `
    ${fonts.PoppinsBold}
    font-size: 1.4rem;
    line-height: 1.2;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  `,
  titleH8: `
    ${fonts.PoppinsMedium}
    font-size: 1.2rem;
    line-height: 1.2;
    text-transform: uppercase;
  `,
  textXXLarge: `
    ${fonts.WorkSansRegular}
    font-size: 2.6rem;
    line-height: 1.3;
  `,
  textXXLargeStrong: `
    ${fonts.WorkSansSemiBold}
    font-size: 2.6rem;
    line-height: 1.3;
  `,
  textXLarge: `
    ${fonts.PoppinsMedium}
    font-size: 2rem;
    line-height: 1.7;
  `,
  textXLargeStrong: `
    ${fonts.PoppinsBold}
    font-size: 2rem;
    line-height: 1.7;
  `,
  textLarge: `
    ${fonts.WorkSansMedium}
    font-size: 1.8rem;
    line-height: 1.3;
  `,
  textLargeStrong: `
    ${fonts.WorkSansSemiBold}
    font-size: 1.8rem;
    line-height: 1.5;
  `,
  textMedium: `
    ${fonts.WorkSansMedium}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  textMediumStrong: `
    ${fonts.WorkSansBold}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  textSmall: `
    ${fonts.WorkSansMedium}
    font-size: 1.4rem;
    line-height: 1.4;
  `,
  textSmallStrong: `
    ${fonts.WorkSansBold}
    font-size: 1.4rem;
    line-height: 1.4;
  `,
  textXSmall: `
    ${fonts.WorkSansSemiBold}
    font-size: 1.2rem;
    line-height: 1.4;
  `,
  textXSmallStrong: `
    ${fonts.WorkSansBold}
    font-size: 1.2rem;
    line-height: 1.4;
  `,
  textXXSmallCaps: `
    ${fonts.WorkSansMedium}
    font-size: 1rem;
    line-height: 1.5;
    text-transform:uppercase;
  `,
  textCaptions: `
    ${fonts.PoppinsMedium}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  accessibleHiddenText: `
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
  `,
}

export default textStyles
