import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const CulinaryArticleCardFragment = gql`
  fragment CulinaryArticleCardFragment on CulinaryArticle {
    __typename
    id
    slug
    title
    title_homepage
    mainImage {
      ...WediaImageFragment
    }
    thumbnail01 {
      ...WediaImageFragment
    }
    positiveFood
    brand {
      id
      pictureLogo {
        ...WediaImageFragment
      }
    }
    # "tags" used to display details
    # actual tags
    tagsConsumptionTime {
      name
    }
    tagsTheme {
      name
    }
    tagsTheme {
      name
    }
  }
  ${WediaImageFragment}
`
