export const parseDateString = (dateString: string) => {
  // Check if the date string contains 'T' character
  if (dateString.includes('T')) {
    return new Date(dateString)
  } else {
    // Replace the space with 'T' and add 'Z' at the end
    const isoDateString = dateString.replace(' ', 'T') + 'Z'
    return new Date(isoDateString)
  }
}
export const parseUnixTimestamp = (timestamp: number) => {
  return new Date(timestamp * 1000)
}
export const isAfter = (date: Date, dateToCompare: Date) => {
  return date.getTime() > dateToCompare.getTime()
}
export const isBefore = (date: Date, dateToCompare: Date) => {
  return date.getTime() < dateToCompare.getTime()
}
// "00:60:00" => 60
export const transformTimeToMinutes = (time: string): number => {
  const parts = time.split(':')
  if (parts.length !== 3) {
    return 0
  }

  const hours = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)
  const seconds = parseInt(parts[2], 10)

  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    return 0
  }

  return hours * 60 + minutes
}

export const transformTimeToISO8601 = (time: string): string | null => {
  const parts = time.split(':')
  if (parts.length !== 3) {
    return null
  }

  const hours = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)
  const seconds = parseInt(parts[2], 10)

  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    return null
  }

  return `PT${hours * 60 + minutes}M`
}
