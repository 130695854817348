import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { actions } from '../../redux'

const ReduxInit: FC<any> = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.app.init())
  }, [dispatch])
  return null
}

export default ReduxInit
