import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'

import { actionTypes } from './types'
import {
  searchEngineAutocompleteService,
  soloSearchResultsService,
} from './services'
import { SearchDataLayer } from './types/state'

export const name = 'search'

type SearchState = {
  data: any
  autocomplete: typeof searchEngineAutocompleteService.values
  searchDataLayer: SearchDataLayer
  soloSearchResults: typeof soloSearchResultsService.values
}

//
// Initial state
//

const initialState: SearchState = {
  data: null,
  autocomplete: searchEngineAutocompleteService.values,
  searchDataLayer: null,
  soloSearchResults: soloSearchResultsService.values,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    init: () => undefined,
    setData: (state, action: actionTypes.setData) => {
      const { data } = action.payload
      state.data = data
    },
    ...searchEngineAutocompleteService.reducers,
    setSearchDataLayer: (state, action: actionTypes.setSearchDataLayer) => {
      state.searchDataLayer = action.payload
    },
    ...soloSearchResultsService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[name]
const data = (state: RootState) => root(state).data
const autocomplete = (state: RootState) => root(state).autocomplete
const searchDataLayer = (state: RootState) => root(state).searchDataLayer
const soloSearchResults = (state: RootState) => root(state).soloSearchResults

export const selectors = {
  data,
  autocomplete,
  searchDataLayer,
  soloSearchResults,
}
