import React, { FC, useMemo } from 'react'
import cx from 'classnames'

import { Icons } from '../Icon'

import { ToasterType } from './styled'
import * as SC from './styled'

export type StoreToaster = {
  text: string
  variant?: ToasterType
}

export type ToasterProps = {
  className?: string
  text: string
  variant?: ToasterType
  open?: boolean
}

const Toaster: FC<ToasterProps> = (props) => {
  const { className, text, variant, open } = props

  const icon = useMemo(() => {
    switch (variant) {
      case ToasterType.info:
        return Icons.info
      case ToasterType.success:
        return Icons.check
      case ToasterType.error:
        return Icons.close
      default:
        return undefined
    }
  }, [variant])

  return (
    <SC.Container
      className={cx('Toaster', className)}
      $variant={variant}
      $open={open || false}
    >
      {icon && <SC.StyledIcon icon={icon} />}
      {text && <SC.Text>{text}</SC.Text>}
    </SC.Container>
  )
}

export default Toaster
