import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Icon from '../../atoms/Icon'
import Link from '../../atoms/Link'
import SearchField from '../../atoms/SearchField'

export const HeaderSearch = styled.div`
  display: flex;
  align-items: center;
`

export const Search = styled(SearchField)`
  flex: 0 0 auto;
  display: none;
  @media ${mq(sizes.desktop)} {
    display: block;
    max-width: 28rem;
  }
`
export const StyledLink = styled(Link)`
  flex: 0 0 auto;
  color: ${({ theme }) => theme.colors.arapawa};
  @media ${mq(sizes.desktop)} {
    display: none;
  }
`
export const StyledIcon = styled(Icon)`
  width: ${({ width }) => (width ? `${width / 10}rem` : '3.2rem')};
  height: auto;
`
export const Label = styled.span`
  ${({ theme }) => theme.textStyles.accessibleHiddenText}
`
