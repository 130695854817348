import { all, fork } from 'redux-saga/effects'

// App
import AppSagas from './app/sagas'
import ApiSagas from './api/sagas'
import I18nSagas from './i18n/sagas'
import MemberSagas from './member/sagas'
import SearchSagas from './search/sagas'
import AuthSagas from './auth/sagas'
import CouponsSagas from './coupons/sagas'
import PlansSagas from './plans/sagas'
import SoloSagas from './solo/sagas'

function* loop() {
  yield all([
    ApiSagas.loop(),
    AppSagas.loop(),
    AuthSagas.loop(),
    I18nSagas.loop(),
    CouponsSagas.loop(),
    MemberSagas.loop(),
    SearchSagas.loop(),
    PlansSagas.loop(),
    SoloSagas.loop(),
  ])
}

export default function* rootSaga() {
  yield fork(loop)
}
