import React, { FC } from 'react'

import { Icons, IconType } from '../Icon'
import { FormFieldAutoSuggestProps } from '../../form/fields/FormFieldAutoSuggest'

import * as SC from './styled'

export type SearchFieldProps = Omit<FormFieldAutoSuggestProps, 'value'> & {
  value?: string
  searchIcon?: IconType
  texts?: {
    button?: string
  }
}

const SearchField: FC<SearchFieldProps> = (props) => {
  const { onSubmit, value, className, texts, searchIcon, ...extraProps } = props

  return (
    <SC.Form
      className={className}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit?.(value as string)
      }}
      role="search"
    >
      <SC.Input
        {...extraProps}
        value={value}
        onSubmit={(v) => onSubmit?.(v)}
        rightAdornment={
          <SC.SearchButton aria-label={texts?.button || 'search button'}>
            {texts?.button && <SC.SearchLabel>{texts?.button}</SC.SearchLabel>}
            <SC.SearchIcon icon={searchIcon ?? Icons.search} />
          </SC.SearchButton>
        }
      />
    </SC.Form>
  )
}

export default SearchField
