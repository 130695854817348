import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Icon from '../Icon'

export const Popin = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndexes.modal};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: visibility 0ms 0ms;
  &[aria-hidden='true'] {
    transition: visibility 0ms 600ms;
    visibility: hidden;
  }
  @media ${mq(sizes.tablet)} {
  }
`
export const Wrapper = styled.div<{ $withHeader?: boolean }>`
  position: relative;
  z-index: 2;
  flex: 0 0 auto;
  display: grid;
  grid-template-rows: ${({ $withHeader }) =>
    $withHeader ? 'auto 1fr 3rem' : '1fr 3rem'};
  max-height: 90vh;
  width: 100%;
  max-width: 72rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 2rem;
  transition: opacity 200ms ease-in-out, transform 150ms ease-in-out;
  [aria-hidden='true'] & {
    transform: translateY(5rem);
    opacity: 0;
  }
  @media ${mq(sizes.tablet)} {
    border-radius: 3rem;
  }
`
export const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 2rem 1.5rem 1.5rem;
  @media ${mq(sizes.tablet)} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH5}
  align-self: center;
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  &:not(:last-child) {
    margin-right: 2rem;
  }
`
export const Content = styled.div<{
  $withHeader?: boolean
  $noWrapping?: boolean
}>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  padding: ${({ $withHeader, $noWrapping }) =>
    $noWrapping
      ? '0'
      : $withHeader
      ? '1.5rem 1.5rem 1rem'
      : '3rem 1.5rem 1rem'};
  overflow: hidden auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${mq(sizes.tablet)} {
    padding-left: 3rem;
    padding-right: 3rem;

    ${({ $noWrapping }) => $noWrapping && 'padding: 0;'}
  }
`
export const Close = styled.button`
  margin-left: auto;
  background: transparent;
  color: ${({ theme }) => theme.colors.arapawa};
  & span {
    ${({ theme }) => theme.textStyles.accessibleHiddenText}
  }
`
export const CloseIcon = styled(Icon)`
  width: 3.2rem;
  height: 3.2rem;
`
export const Overlay = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 0;
  cursor: default;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 200ms ease-in-out 0ms;
  [aria-hidden='true'] & {
    opacity: 0;
    transition: opacity 200ms ease-in-out 100ms;
  }
`
