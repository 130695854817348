import { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

import { actions, selectors } from '../../../redux'
import Router, { routes } from '../../../routes/Router'
import APP from '../../../configuration/app'

const PasswordUpdateAuthorizedRoutes = [
  routes.mandatoryPasswordUpdate.path,
  routes.requestPassword.path,
  routes.resetPassword.path,
  routes.resetPasswordSuccess.path,
]

const Redirector: FC = () => {
  const isInit = useSelector(selectors.app.isInit)
  const isConnected = useSelector(selectors.auth.isConnected)
  const user = useSelector(selectors.auth.user)
  const router = useRouter()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isInit || (isConnected && !user)) return

    // disable redirection to allow caprice valentine light registration
    /*
    // Redirect to account informations page if user is not valid
    if (
      isConnected &&
      user &&
      !user?.isValidProfile &&
      router.pathname !== routes.accountInformations.path
    ) {
      Router.pushRoute(routes.accountInformations)
      dispatch(
        actions.app.setErrorToaster(
          'toaster_authentification_needed_info_error'
        )
      )
    }
     */

    // Redirect to account informations page if user has no address when trying to access coupon
    if (
      isConnected &&
      user &&
      !user?.canAccessCoupon &&
      router.pathname !== routes.accountInformations.path &&
      router.asPath === APP.REDUCTION_SLUG
    ) {
      Router.pushRoute(routes.accountInformations)
      dispatch(
        actions.app.setErrorToaster('toaster_member_access_coupon_error')
      )
      dispatch(
        actions.member.setIsTryingToAccessUnauthorizedPage(router.asPath)
      )
    }

    if (
      isConnected &&
      user &&
      user?.needPasswordReset &&
      !PasswordUpdateAuthorizedRoutes.includes(router.pathname)
    ) {
      Router.pushRoute(routes.mandatoryPasswordUpdate)
      dispatch(
        actions.app.setErrorToaster(
          'toaster_authentification_need_password_reset'
        )
      )
    }
  }, [user, router, isConnected, dispatch, isInit])

  return null
}

export default Redirector
