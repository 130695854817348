import styled, { css } from 'styled-components'

import { HtmlLayout } from './types'

export const Html = styled.div<{ $layout: HtmlLayout }>`
  ${({ theme }) => theme.textStyles.textMedium}
  color: ${({ theme }) => theme.colors.arapawa};

  & p {
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  & h1 {
    ${({ theme }) => theme.textStyles.titleH1}
  }
  & h2 {
    ${({ theme }) => theme.textStyles.titleH2}
  }
  & h3 {
    ${({ theme }) => theme.textStyles.titleH3}
  }
  & h4 {
    ${({ theme }) => theme.textStyles.titleH4}
  }
  & a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.ruby};
    &:hover {
      text-decoration: underline;
    }
  }
  & strong,
  & b {
    font-weight: bold;
    font-variation-settings: 'wggt' 700;
  }

  ${({ $layout }) =>
    $layout === HtmlLayout.default &&
    css`
      & table {
        /* border: 0.1rem solid ${({ theme }) => theme.colors.gainsboro}; */
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 2rem;
        table-layout: fixed;

        tr {
          border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
        }

        & tbody tr:first-child {
          & td:nth-child(4) {
            ${({ theme }) => theme.textStyles.textSmallStrong}
            text-align: right;
          }
        }

        & tbody tr > td:not(:last-child) {
          border-right: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
        }

        tr:first-child {
          & p:has(strong:only-child) {
            strong {
              color: ${({ theme }) => theme.colors.ruby};
            }
          }
        }
      }

      & th,
      & td {
        padding: 0.5rem;
      }

      & th {
        ${({ theme }) => theme.textStyles.textSmallStrong}
        text-align: left;
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
      }

      & td {
        ${({ theme }) => theme.textStyles.textSmall}
        text-align: left;
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
      }
    `}

  ${({ $layout }) =>
    $layout === HtmlLayout.article &&
    css`
      & table {
        width: 100%;
        border-collapse: collapse;
      }

      & th,
      & td {
        padding: 1.5rem 1rem;
      }

      & th {
        ${({ theme }) => theme.textStyles.textSmallStrong}
        color: ${({ theme }) => theme.colors.ruby};
        text-align: center;
        text-transform: uppercase;
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
      }

      & th:has(p:not(:empty)) {
        background-color: ${({ theme }) => theme.colors.snow};
      }

      & td {
        ${({ theme }) => theme.textStyles.textSmall}
        text-align: left;
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
      }

      & tr > td:first-child {
        color: ${({ theme }) => theme.colors.ruby};
      }

      & tr > td {
        a {
          color: ${({ theme }) => theme.colors.arapawa};
          text-decoration: underline;
          cursor: pointer;
        }
      }

      td:first-child {
        width: 20rem;
      }

      td:nth-child(3) {
        width: 18rem;
      }

      @media (max-width: 600px) {
        table:has(td:nth-child(3)) tr {
          display: grid;
          grid-template-columns: 1fr 18rem;
          grid-template-rows: auto auto;
          border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};

          & th,
          & td {
            padding: 1.5rem 1rem;
            border-bottom: none;
            text-align: left;
          }

          td:first-child {
            width: 16rem;
          }

          td:nth-child(1) {
            padding: 1rem;
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
          }

          td:nth-child(2) {
            grid-column: 1 / span 1;
            grid-row: 2 / span 1;
            color: ${({ theme }) => theme.colors.bismark};
          }

          td:nth-child(3) {
            grid-column: 2 / span 1;
            grid-row: 1 / span 3;
          }
        }
      }
    `}
`
