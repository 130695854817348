import { all, call, put, takeLatest } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../api/types/state'
import plans from '../../graphql/Services/Plan/queries/plans'
import planJWT from '../../graphql/Services/Plan/queries/planJWT'

export default class PlansSagas {
  static *onPlansRequest({
    payload,
  }: ReturnType<typeof actions.plans.plansRequest>) {
    const rs: ApiResponse<typeof plans> = yield call(
      ApiSagas.query,
      plans,
      payload
    )

    if (rs.errors) {
      yield put(actions.plans.plansError(rs.errors))
    } else {
      yield put(actions.plans.plansSuccess(rs.data))
    }
  }

  static *onPlanRequest({
    payload,
  }: ReturnType<typeof actions.plans.planRequest>) {
    const rs: ApiResponse<typeof planJWT> = yield call(
      ApiSagas.query,
      planJWT,
      payload
    )

    if (rs.errors) {
      yield put(actions.plans.planError(rs.errors))
    } else {
      yield put(actions.plans.planSuccess(rs.data))
    }
  }

  static *loop() {
    yield all([
      takeLatest(actions.plans.plansRequest, this.onPlansRequest),
      takeLatest(actions.plans.planRequest, this.onPlanRequest),
    ])
  }
}
