import { useTranslation } from 'react-i18next'
import { NextPage, NextPageContext } from 'next'

import BugsnagHelpers from '../helpers/BugsnagHelpers'
import NotFoundTemplate, {
  NotFoundTemplateProps,
} from '../templates/NotFoundTemplate'
import useHeader from '../relay/Header/useHeader'
import useFooter from '../relay/Footer/useFooter'
import { wediaImagesTransform } from '../relay/Common/wedia'
import { actionButtonIconLabelMock } from '../components/atoms/ActionButton/mocks'
import { Icons } from '../components/atoms/Icon'
import { ActionButtonVariant } from '../components/atoms/ActionButton/styled'
import Router from '../routes/Router'
import routes from '../routes/definitions'
import { initWithLangAndLabels } from '../i18n'
import { ssrPersistedQuery } from '../helpers/SSRHelpers'
import { Configuration } from '../graphql/Services'

const ErrorPage: NextPage<any> = ({ configuration, statusCode }) => {
  const { t } = useTranslation()

  const notFoundProps: NotFoundTemplateProps = {
    title: t('error_title', { statusCode }),
    subtitle: t('error_text'),
    headerProps: useHeader(
      configuration?.header,
      configuration?.footer?.socialLinks
    ),
    footerProps: useFooter(configuration?.footer),
    imageProps: {
      maxWidth: 2000,
      width: 1440,
      height: 1048,
      withHD: true,
      images: wediaImagesTransform(configuration?.notFound?.image),
      alt: configuration?.notFound?.image.alt ?? '',
    },
    logoProps: {
      maxWidth: 160,
      width: 160,
      height: 160,
      withHD: true,
      alt: t('seo_logo_alt'),
      images: [
        {
          src: '/static/assets/images/common/logo-220-min.png',
          size: 586,
        },
        {
          src: '/static/assets/images/common/logo-220-min.webp',
          size: 160,
        },
      ],
    },
    buttonProps: {
      ...actionButtonIconLabelMock,
      label: t('404_button'),
      iconPosition: 'right',
      iconProps: {
        icon: Icons.arrowRight,
      },
      variant: ActionButtonVariant.secondary,
      href: Router.getRouteUrl(routes.home),
    },
  }
  return <NotFoundTemplate {...notFoundProps} />
}

export default ErrorPage

ErrorPage.getInitialProps = async ({
  req,
  res,
  err,
  error,
}: NextPageContext & { error: any }) => {
  BugsnagHelpers?.notify(new Error('SSR Error'), (event) => {
    event.addMetadata('REQ', { statusCode: req?.statusCode, url: req?.url })
    event.addMetadata('originalError', {
      error,
      message: error?.message,
      stack: error?.stack,
    })
    event.addMetadata('statusCode', {
      res: res?.statusCode,
      err: err?.statusCode,
    })
    event.severity = 'warning'
  })

  const statusCode = res ? res.statusCode : err ? err.statusCode : error ?? 404
  const i18n = initWithLangAndLabels('fr', {})

  const configurationRs = await ssrPersistedQuery(
    Configuration.queries.configuration
  )

  const configuration = configurationRs?.data ?? null

  return { i18n, configuration, statusCode }
}
