import { FC, useCallback, useEffect, useState } from 'react'
import cx from 'classnames'

import { Icons } from '../Icon'

import * as SC from './styled'

export type BackToTopProps = {
  className?: string
  text?: string
}

const BackToTop: FC<BackToTopProps> = (props) => {
  const { className, text = 'Retour en haut de page' } = props
  const [visible, setVisible] = useState(false)

  const handleBackToTop = () => {
    if (!window?.scrollY) return
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const toggleVisible = useCallback(() => {
    if (!window?.innerHeight || !window?.scrollY) return

    const overThreshold =
      window.innerHeight / 4 < window.scrollY ||
      window.scrollY === window.innerHeight

    if (overThreshold && visible === false) {
      setVisible(true)
    } else if (!overThreshold && visible === true) {
      setVisible(false)
    }
  }, [visible])

  useEffect(() => {
    const onScroll = () => {
      toggleVisible()
    }

    onScroll()

    window && window.addEventListener('scroll', onScroll)
    return () => {
      window && window.removeEventListener('scroll', onScroll)
    }
  }, [toggleVisible])

  return (
    <SC.Container
      className={cx('BackToTop', className)}
      onClick={handleBackToTop}
      type="button"
      $visible={visible}
    >
      <SC.Text>{text}</SC.Text>
      <SC.StyledIcon icon={Icons.chevronUp} />
    </SC.Container>
  )
}

export default BackToTop
