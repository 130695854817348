import React, { FC, InputHTMLAttributes } from 'react'

import { Icons } from '../../../atoms/Icon'

import * as SC from './styled'

export type FormFieldCheckboxProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'value'
> & {
  className?: string
  name: string
  checked?: boolean
  value?: string
  label?: string
  required?: boolean
  disabled?: boolean
  error?: string
  hasError?: boolean
  help?: string
  isGroupChild?: boolean
  hideErrors?: boolean
}

const FormFieldCheckbox: FC<FormFieldCheckboxProps> = (props) => {
  const {
    className,
    label,
    error,
    hasError,
    help,
    isGroupChild,
    hideErrors,
    ...inputProps
  } = props

  return (
    <SC.Container className={className} $isDisabled={!!inputProps.disabled}>
      <SC.Label $isDisabled={!!inputProps.disabled}>
        <SC.Checkbox
          {...inputProps}
          type="checkbox"
          $isError={isGroupChild ? false : !!error || hasError}
          aria-invalid={isGroupChild ? false : !!error || hasError}
        />

        <SC.StyledIcon
          icon={Icons.check}
          $isError={isGroupChild ? false : !!error || hasError}
        />

        <SC.LabelText
          $required={!!inputProps.required}
          $isError={isGroupChild ? false : !!error || hasError}
          dangerouslySetInnerHTML={{ __html: label ?? '' }}
        />
      </SC.Label>
      {(!error || hideErrors) && help && <SC.Help>{help}</SC.Help>}
      {error && !hideErrors && <SC.Error>{error}</SC.Error>}
    </SC.Container>
  )
}

export default FormFieldCheckbox
