import React, {
  FC,
  forwardRef,
  InputHTMLAttributes,
  useId,
  useState,
} from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type FormFieldInputProps = InputHTMLAttributes<HTMLInputElement> & {
  name?: string
  className?: string
  error?: string
  hasError?: boolean
  helpText?: string
  errors?: { message?: string }[]
  label?: string
  withChildFocused?: boolean
  required?: boolean
  leftAdornment?: React.ReactElement
  rightAdornment?: React.ReactElement
  InputComponent?: FC<any>
  inputProps?: any
  pattern?: string
}

const FormFieldInput = forwardRef<any, FormFieldInputProps>(
  function FormFieldInput(props, ref) {
    const {
      className,
      name,
      id,
      error,
      hasError,
      placeholder,
      onChange,
      label,
      withChildFocused,
      required,
      helpText,
      leftAdornment,
      rightAdornment,
      InputComponent,
      inputProps,
      value,
      pattern,
      ...extraProps
    } = props
    const localId = useId()
    const [isFocused, setFocused] = useState(false)
    const isActive = isFocused || !!value || withChildFocused
    const hasLabel = !!label

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (pattern) {
        const nonMatchingPattern = new RegExp(`[^${pattern}*$]`, 'g')
        const newValue = e.target.value.replace(nonMatchingPattern, '')
        e.target.value = newValue
        e.currentTarget.value = newValue
      }
      onChange?.(e)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (extraProps?.type === 'number') {
        if (isNaN(Number(e.key)) && e.key !== 'Backspace') {
          e.preventDefault()
        }
      }
    }

    const CustomInput: FC<any> = InputComponent ? InputComponent : SC.Input

    return (
      <SC.Container className={cx('FormFieldInput', className)}>
        <SC.Wrapper
          $isError={!!error || hasError}
          $isActive={isActive}
          $isDisabled={extraProps.disabled}
          $hasLabel={hasLabel}
          $isReadOnly={extraProps.readOnly}
        >
          <SC.Label $required={required} $active={isActive}>
            {label}
          </SC.Label>
          <SC.InputContainer>
            {leftAdornment}
            <CustomInput
              ref={ref}
              {...inputProps}
              {...extraProps}
              id={id ?? localId}
              name={name}
              type={extraProps.type ?? 'text'}
              placeholder={!hasLabel || isFocused ? placeholder : undefined}
              value={value}
              $isError={!!error || hasError}
              $isActive={isActive}
              $hasLabel={hasLabel}
              $isDisabled={extraProps.disabled}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={(e: any) => {
                if (extraProps.readOnly) return
                setFocused(true)
                extraProps?.onFocus?.(e)
              }}
              onBlur={(e: any) => {
                if (extraProps.readOnly) return
                extraProps?.onBlur?.(e)
                setFocused(false)
              }}
            />
            {rightAdornment}
          </SC.InputContainer>
        </SC.Wrapper>
        {!error && helpText && <SC.HelpMessage>{helpText}</SC.HelpMessage>}
        {error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.Container>
    )
  }
)

export default FormFieldInput
