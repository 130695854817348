import gql from 'graphql-tag'

export const WediaVideoFragment = gql`
  fragment WediaVideoFragment on WediaVideo {
    uuid
    title
    downloadUrl
    alt
    sources {
      source
      type
      mimeType
      bitrate
      label
    }
  }
`
