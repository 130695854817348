import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const RecipeSmallCardFragment = gql`
  fragment RecipeSmallCardFragment on Recipe {
    __typename
    id
    slug
    title
    mainImage {
      ...WediaImageFragment
    }
    thumbnail01 {
      ...WediaImageFragment
    }
    brand {
      pictureLogo {
        ...WediaImageFragment
      }
      name
    }
    # "tags" used to display details
    tagsCookingTime {
      name
    }
    tagsPreparationTime {
      name
    }
    tagsConsumptionTime {
      name
    }
    tagsDifficulty {
      name
    }
    tagsTheme {
      name
    }
    positiveFood
    cookingTime
    preparationTime
    nutriscore
    nutriscoreLetter
  }
  ${WediaImageFragment}
`
