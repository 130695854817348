import gql from 'graphql-tag'

import { ServiceQuery } from '../../../../redux/api/types/state'
import { CouponFragment } from '../../../fragments/CouponFragment'

const brandCoupons: ServiceQuery = {
  name: 'brandCoupons',
  query: gql`
    query couponsByModel($model: CouponModel!, $id: ID!) {
      couponsByModel(model: $model, id: $id) {
        ...CouponFragment
      }
    }
    ${CouponFragment}
  `,
  transformer: (data: any) => {
    return data?.couponsByModel
  },
}

export default brandCoupons
